import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as API from "../../configuration/apiconfig";
import { FaRegEdit } from "react-icons/fa";

const renameFile = (originalFile, newName) => {
  return new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
};

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const getStartDate = () => {
  let startDate = new Date();
  if (new Date().getHours() === 15 && new Date().getMinutes() > 30) {
    startDate.setDate(startDate.getDate() + 1);
  } else if (new Date().getHours() > 15) {
    startDate.setDate(startDate.getDate() + 1);
  }
  return startDate;
};

function DatePickerFunComp(props) {
  const [startDate, setStartDate] = useState(props.getStartDate);
  const ExampleCustomInput = React.forwardRef(({ value, onClick }, ref) => {
    return (
      <section className="custom-datepicker">
        <button className="example-custom-input" onClick={onClick} ref={ref}>
          Schedule
        </button>
        {props.schedule === "" ? null : <strong>{value}</strong>}
      </section>
    );
  });

  return (
    <DatePicker
      selected={startDate}
      minDate={getStartDate()}
      onChange={(date) => {
        setStartDate(date);
        props.toggleClass("schedule", new Date(date));
      }}
      timeInputLabel="Time:"
      dateFormat="MM/dd/yyyy  h:mm aa"
      showTimeInput
      disabledKeyboardNavigation
      placeholderText="This has disabled keyboard navigation"
      customInput={<ExampleCustomInput />}
    />
  );
}

class SystemEquitySaveProceedRight extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuIsOpen: true,
      startDate: new Date(),
      setStartDate: new Date(),
      tradeImageURL: "",
      tradeErrorMsg: "",
      plans: [],
      submit: true,
      tradeLogic: [],
    };
  }

  uploadFile = async (e) => {
    try {
      let file = e.target.files[0];

      const fileFormat = file.type.split("/")[1];
      const queryObj = {
        fileFormat,
        type: "tradelogics",
      };

      await API.callEndpoint(
        "GET",
        "Bearer",
        `/api/files/presigned?fileFormat=${queryObj.fileFormat}&type=${queryObj.type}`
      )
        .then((response) => {
          const { key, url } = response.data.data;
          const fileName = `${key}.${fileFormat}`;
          const renamedFile = renameFile(file, fileName);

          const myHeaders = new Headers({ "Content-Type": "image/*" });
          fetch(url, {
            method: "PUT",
            headers: myHeaders,
            body: renamedFile,
          })
            .then(() => {
              API.callEndpoint(
                "GET",
                "Bearer",
                `/api/files/presigned-url?fileName=${fileName}&type=${queryObj.type}`
              )
                .then((response) => {
                  this.setState({
                    tradeSuccessMsg: "Uploaded!",
                    tradeErrorMsg: "",
                    tradeImageURL: response.data?.data.split("?X-")[0],
                  });
                })
                .catch((error) => {
                  console.log(error.error);
                  this.setState({
                    tradeErrorMsg: error.error,
                    tradeSuccessMsg: "",
                  });
                });
            })
            .catch((error) => {
              console.log(error.error);
              this.setState({
                tradeErrorMsg: error.error,
                tradeSuccessMsg: "",
              });
            });
        })
        .catch((error) => {
          console.log(error.error);
          this.setState({ tradeErrorMsg: error.error, tradeSuccessMsg: "" });
        });
    } catch (e) {
      console.log(e);
      this.setState({ tradeErrorMsg: e.error, tradeSuccessMsg: "" });
    }
  };

  componentDidMount = async () => {
    await API.callEndpoint(
      "GET",
      "Bearer",
      "/api/plans?include=id&include=planName"
    )
      .then((response) => {
        this.setState({ plans: response.data });
      })
      .catch((error) => {
        console.log(error.error);
        this.setState({ plans: [] });
      });

    this.setState({
      submit: this.props.state.equityTLPointArrayNew.length === 0,
    });
  };

  render() {
    return (
      <section className="admin-saveProceedRight">
        <section className="list-blk pt-0 capital-all">
          <div className="form-group w-50">
            <label htmlFor="capital">Capital Allocation (%)</label>
            <input
              type="text"
              id="capital"
              className="form-control"
              autoComplete="off"
              name="capitalAllocation"
              onChange={(e) => this.props.capitalOnChange(e)}
              value={this.props.state.capitalAllocation}
              maxLength="3"
            />
          </div>
        </section>

        {/* new  */}
        {/* <section className="list-blk">
          <h2>For</h2>
          {this.state.plans.map((item) => (
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                id={item.id}
                onChange={(e) => {
                  if (!this.props.state.modifyHeader) {
                    this.props.forCheckedFields(e, item.id);
                  }
                }}
                checked={this.props.state.equityPlan === item.id ? true : false}
              />
              <label className="form-check-label" htmlFor={item.id}>
                {item.planName}
              </label>
            </div>
          ))}
          
        </section> */}

        <section className="list-blk">
          <h2>Publish</h2>
          <ul className="list-group list-group-horizontal">
            <li
              className={`list-group-item border-0 px-2 py-0 ${
                this.props.state.now ? "active" : ""
              }`}
              onClick={() => this.props.toggleClass("now", true)}
            >
              <span className="d-block text-center">Now</span>
            </li>
            <li
              className={`list-group-item border-0 px-2 py-0 ${
                this.props.state.schedule !== "" ? "active" : ""
              }`}
              onClick={() => this.props.resetNow()}
            >
              <DatePickerFunComp
                toggleClass={this.props.toggleClass}
                getStartDate={
                  this.props.state.schedule !== ""
                    ? this.props.state.schedule
                    : getStartDate()
                }
                schedule={this.props.state.schedule}
              />
            </li>
            {/* <li className="list-group-item border-0 px-2 py-0"></li> */}
          </ul>
        </section>
        <section className="list-blk">
          <div className="form-group">
            <div className="TradeLogicMargin">
              <label>Trade Logic</label>
              {/* {console.log("equity reached")} */}
              {!this.state.submit ? (
                <FaRegEdit
                  className="equityEdit"
                  onClick={() => {
                    let obj = this.props.equityTradeLogicEdit()[0];
                    this.setState({ submit: true, tradeImageURL: obj.img });
                    let e = {
                      target: {
                        value: obj.text,
                      },
                    };
                    this.props.tradeNoteOnchange(e, "equityTradeLogic");
                  }}
                />
              ) : null}
            </div>
            <section className="accordion-blk">
              <div className="accordion" id="accordionExample">
                {this.props.state.equityTLPointArray.map((point, index) => {
                  if (index <= this.props.state.equityTLPointArrayLength) {
                    return (
                      <div className="accordion-item" key={index}>
                        <h2
                          className="accordion-header"
                          id={`heading_${index + 1}`}
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapse_${index + 1}`}
                            aria-expanded="false"
                            aria-controls={`collapse_${index + 1}`}
                          >
                            <span>{point.text}</span>
                          </button>
                        </h2>
                        <div
                          id={`collapse_${index + 1}`}
                          className="accordion-collapse collapse"
                          aria-labelledby={`heading_${index + 1}`}
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <img
                              src={point.img.split("?X-")[0]}
                              className="img-fluid"
                            />
                            <p
                              style={{
                                whiteSpace: "pre-line",
                              }}
                            >
                              {point.text}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
              {/* <aside className="add-trade-logic">
                  <p>Add new trade logic</p>
                </aside> */}
            </section>
            {this.state.submit ? (
              <>
                <div className="row">
                  <section className="file-upload-blk">
                    <label htmlFor="file">Upload image</label>{" "}
                    <span className="upload-image-recommend">{`(600px X 300px | Size < 1MB recommended)`}</span>
                    <input
                      type="file"
                      name="image"
                      id="file"
                      onChange={this.uploadFile}
                      accept="image/x-png,image/gif,image/jpeg"
                    />
                  </section>
                </div>
                {this.state.tradeSuccessMsg === "Uploaded!" ? (
                  <div className="trade-success-msg">
                    {this.state.tradeSuccessMsg}
                  </div>
                ) : (
                  <div className="trade-error-msg">
                    {this.state.tradeErrorMsg}
                  </div>
                )}
                <textarea
                  value={this.props.state.equityTradeLogic}
                  id="trade-note"
                  className="form-control"
                  onChange={(e) =>
                    this.props.tradeNoteOnchange(e, "equityTradeLogic")
                  }
                ></textarea>
                <aside className="submit-btn">
                  <button
                    type="button"
                    id="submit-Btn"
                    className={`btn btn-secondary ${
                      this.props.submitDisabled() ? "disabled" : ""
                    }`}
                    onClick={() => {
                      this.props.equityTradeLogicSubmit(
                        this.state.tradeImageURL,
                        this.state.tradeSmallImageURL
                      );
                      this.setState({ submit: false });
                    }}
                  >
                    Submit
                  </button>
                </aside>
              </>
            ) : null}
          </div>

          <div className="form-group">
            <label htmlFor="textarea">Notes</label>
            <textarea
              rows={12}
              value={this.props.state.equityNotes}
              className="form-control"
              id="textarea"
              onChange={(e) => this.props.tradeNoteOnchange(e, "equityNotes")}
            ></textarea>
          </div>
        </section>
      </section>
    );
  }
}

export default SystemEquitySaveProceedRight;
