import React from "react";
import Swal from "sweetalert2";
import * as API from "../../configuration/apiconfig";
import BundlesTable from "../bundles/bundlesTable";
import BundlesTabs from "../bundles/bundlesTabs";
import BundleDetails from "../bundles/bundledetails";
import moment from "moment";
import swal from "sweetalert";
import { createBundle, updateBundle } from "./ApiBundles";

const membershipSelectOptions = [
  { value: "Free", label: "Free" },
  { value: "Prime", label: "Prime" },
  //  { value: "Crown", label: "Crown" },
];

const canEnterStatusSelectOptions = [
  { value: 0, label: "No" },
  { value: 1, label: "Yes" },
];
const shouldUseOldRocFormulaSelectOptions = [
  { value: 0, label: "No" },
  { value: 1, label: "Yes" },
];

const typesOfLiveFund = [
  { value: "Large Cap Live Funds", label: "Large Cap Live Funds" },
  { value: "Mid Cap Live Funds", label: "Mid Cap Live Funds" },
  { value: "Multi Cap Live Funds", label: "Multi Cap Live Funds" },
  { value: "All seasons Live Funds", label: "All seasons Live Funds" },
  //  { value: "Crown", label: "Crown" },
];

const legselect = [
  { value: "buy", label: "BUY" },
  { value: "sell", label: "SELL" },
];

const optionselect = [
  { value: "PE", label: "PE" },
  { value: "CE", label: "CE" },
];

const equityType = [
  { value: "Long", label: "Long" },
  { value: "Short", label: "Short" },
];

const closeType = [
  { value: "profit", label: "Profit" },
  { value: "loss", label: "Loss" },
];

const callStatus = [
  { value: "new", label: "New" },
  { value: "modified", label: "Modified" },
  { value: "closed", label: "Closed" },
];

const stopLossTypeSelect = [
  { value: "Value", label: "Value" },
  { value: "Price", label: "Price" },
];

const optionsValue = [
  { value: "Long call", label: "Long call" },
  { value: "Short Call", label: "Short Call" },
  { value: "Long Put", label: "Long Put" },
  { value: "Short Put", label: "Short Put" },
  { value: "Bull call spread", label: "Bull call spread" },
  { value: "Bear Put spread", label: "Bear Put spread" },
  { value: "Bull Put Spread", label: "Bull Put Spread" },
  { value: "Synthetic", label: "Synthetic" },
  { value: "Long Combo", label: "Long Combo" },
  { value: "Long Collar", label: "Long Collar" },
  { value: "Short Collar", label: "Short Collar" },
  { value: "Bear Call Spread", label: "Bear Call Spread" },
  { value: "Married Put", label: "Married Put" },
  { value: "Long Strangle", label: "Long Strangle" },
  { value: "Short Strangle", label: "Short Strangle" },
  { value: "Short Straddle", label: "Short Straddle" },
  { value: "Long Straddle", label: "Long Straddle" },
  { value: "Call Butterfly", label: "Call Butterfly" },
  { value: "Put Butterfly", label: "Put Butterfly" },
  { value: "Iron Fly", label: "Iron Fly" },
  { value: "Iron Condor", label: "Iron Condor" },
  { value: "Call Calendar spread", label: "Call Calendar spread" },
  { value: "Put Calendar Spread", label: "Put Calendar Spread" },
  { value: "Diagonal", label: "Diagonal" },
  {
    value: "Modified Futures Call Butterfly",
    label: "Modified Futures Call Butterfly",
  },
  { value: "Call Ratio Spread", label: "Call Ratio Spread" },
  { value: "Put Ratio spread", label: "Put Ratio spread" },
  { value: "Calendar Call Ratio Spread", label: "Calendar Call Ratio Spread" },
  { value: "Calendar Put Ratio spread", label: "Calendar Put Ratio spread" },
  { value: "Modified Close Call Collar", label: "Modified Close Call Collar" },
  { value: "Cross Calender Spread", label: "Cross Calender Spread" },
  { value: "Pull Call spread", label: "Pull Call spread" },
  { value: "Pull Put spread", label: "Pull Put spread" },
];

const optionsType = [
  { value: "Bullish", label: "Bullish" },
  { value: "Bearish", label: "Bearish" },
  { value: "Neutral", label: "Neutral" },
];

function capitalizeFirstLetter(string) {
  if (string !== undefined && string !== null) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  } else {
    return "";
  }
}

class Bundles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bundlesTableComponent: true,
      BundlesTabsComponent: false,
      bundlesDetailComponent: false,
      activeComponent: this.props.match.params.bundletype,
      Bundledatas: [],
      // bundleTableData: [
      //   {
      //     bundle: "Hedged Dream",
      //     traget: "Prime",
      //     createOn: [
      //       {
      //         time: "09:00:12 AM",
      //         date: "25 Apr 2021",
      //       },
      //     ],
      //     lastModify: [
      //       {
      //         time: "09:15:18 AM",
      //         date: "26 Apr 2021",
      //       },
      //     ],
      //     detailsBtn: "Details",
      //     modifyBtn: "Modify",
      //     removeBtn: "Remove",
      //   },
      //   {
      //     bundle: "Hedged Ambition",
      //     traget: "Crown",
      //     createOn: [
      //       {
      //         time: "09:00:12 AM",
      //         date: "25 Apr 2021",
      //       },
      //     ],
      //     lastModify: [
      //       {
      //         time: "09:15:18 AM",
      //         date: "26 Apr 2021",
      //       },
      //     ],
      //     detailsBtn: "Details",
      //     modifyBtn: "Modify",
      //     removeBtn: "Remove",
      //   },
      //   {
      //     bundle: "Hedged Super",
      //     traget: "Prime",
      //     createOn: [
      //       {
      //         time: "09:00:12 AM",
      //         date: "25 Apr 2021",
      //       },
      //     ],
      //     lastModify: [
      //       {
      //         time: "09:15:18 AM",
      //         date: "26 Apr 2021",
      //       },
      //     ],
      //     detailsBtn: "Details",
      //     modifyBtn: "Modify",
      //     removeBtn: "Remove",
      //   },
      // ],
      equity: true,
      option: false,
      equityTabActive: true,
      optionsTabActive: false,
      addBundle: "",
      addLabel: "",
      // addDes: "",
      memberSelectValue: "",
      legsSelectValue: "",
      eqtycurstatus: "",
      optcurstatus: "",
      //equity bundle array
      equityAddScripArray: [
        // {
        //   alreadyPresent: 0,
        //   scripNameValue: "",
        //   minPriceValue: "",
        //   maxPriceValue: "",
        //   quantityValue: "",
        //   quantityPercentValue: "",
        //   call_type: "",
        //   call_status: "",
        //   closeType: "",
        //   closeTotal: "",
        // },
      ],
      //options bundle array
      optionsAddScripArray: [
        // {
        //   alreadyPresent: 0,
        //   scripNameValue: "",
        //   capitalReq: "",
        //   call_type: "",
        //   call_status: "",
        //   closeType: "",
        //   closeTotal: "",
        //   strategy: "",
        //   maxLoss: "",
        //   stopLoss: "",
        //   exitPrice: "",
        //   legs: [
        //     {
        //       action: "",
        //       option: "",
        //       expiry: "",
        //       strikePrice: "",
        //       qtyValue: "",
        //       Price: "",
        //     },
        //   ],
        // },
      ],
      bundleId: "",
      bundleData: [],
      callDetailData: [],
      nifty_growth: "",
      meter: "",
      //create bundle
      bundleTitle: "",
      bundle_version: "",
      // bundleLabel:null,
      subscription: "",
      type: "",
      expectedReturn: "",
      currentReturn: "",
      equityCapital: "",
      eqtynote: "",
      optionsCapital: "",
      optionalnote: "",
      modify: false,
      modifyId: 0,
      doneBtnDisable: false,
      navList: [
        { id: 1, tabname: "Free", navStatus: true },
        { id: 2, tabname: "Prime", navStatus: false },
      ],
      curTab: { id: 1, tabname: "Free" },
      notify: true,
    };
  }

  updateState = (field, value) => {
    // console.log("field",field,"value", value);
    let state = this.state;
    state[field] = value;
    this.setState(state);
  };

  modifyComponent = async () => {
    //console.log(this.props.match.params.bundletype);
    if (this.props.match.params.bundletype === "table") {
      this.setState({
        bundlesTableComponent: true,
        BundlesTabsComponent: false,
        bundlesDetailComponent: false,
        modify: false,
        equityAddScripArray: [
          // {
          //   id: "",
          //   scripNameValue: "",
          //   minPriceValue: "",
          //   maxPriceValue: "",
          //   quantityValue: "",
          //   quantityPercentValue: "",
          //   call_type: "",
          //   call_status: "",
          //   closeType: "",
          //   closeTotal: "",
          //   alreadyPresent: 0,
          // },
        ],
        optionsAddScripArray: [
          // {
          //   id: "",
          //   alreadyPresent: 0,
          //   scripNameValue: "",
          //   capitalReq: "",
          //   call_type: "",
          //   call_status: "",
          //   closeType: "",
          //   closeTotal: "",
          //   strategy: "",
          //   maxLoss: "",
          //   stopLoss: "",
          //   exitPrice: "",
          //   legs: [
          //     {
          //       action: "",
          //       option: "",
          //       expiry: "",
          //       strikePrice: "",
          //       qtyValue: "",
          //       Price: "",
          //     },
          //   ],
          // },
        ],
        bundleTitle: "",
        // bundleLabel:"",
        subscription: "",
        expectedReturn: "",
        currentReturn: "",
        equityCapital: "",
        eqtynote: "",
        optionsCapital: "",
        optionalnote: "",
      });
    } else if (this.props.match.params.bundletype === "tabs") {
      this.setState({
        bundlesTableComponent: false,
        BundlesTabsComponent: true,
        bundlesDetailComponent: false,
      });
    } else if (this.props.match.params.bundletype === "details") {
      this.setState({
        bundlesTableComponent: false,
        BundlesTabsComponent: false,
        bundlesDetailComponent: true,
        equityTabActive: true,
        optionsTabActive: false,
        // addBundle: "Influx",
        // addDes: "Influx Private Ltd",
        // memberSelectValue: { value: "Crown", label: "Crown" },
        // equityCapital: "Capital value",
        // eqtycurstatus: "Equity current status",
        // eqtynote: "Equity note",
        // equityAddScripArray: [
        //   {
        //     scripNameValue: "Influx",
        //     minPriceValue: "10, 000",
        //     maxPriceValue: "12,000",
        //     quantityValue: "12",
        //   },
        // ],
        // optionsCapital: "Capital value",
        // optcurstatus: "Curent Status",
        // optionalnote: "Optional notes",
        // legsSelectValue: { value: "BUY - CE", label: "BUY - CE" },
        // optionsAddScripArray: [
        //   {
        //     scripNameValue: "Influx",
        //     reqdCapitalValue: "10,000",
        //     legs: [
        //       {
        //         selectValue: { value: "BUY - CE", label: "BUY - CE" },
        //         qtyValue: "20",
        //         minPrice: "20",
        //         maxPrice: "35",
        //       },
        //       {
        //         selectValue: { value: "SELL - CE", label: "SELL - CE" },
        //         qtyValue: "10",
        //         minPrice: "30",
        //         maxPrice: "35",
        //       },
        //     ],
        //   },
        // ],
      });
    } else if (this.props.match.params.bundletype.startsWith("modify")) {
      // --------------------------------------------------------- Modify Bundles ----------------------------------------------------
      //console.log(this.props.location.bundle);
      let bundle = this.props.location.bundle;
      let path = this.props.location.pathname;
      let id = parseInt(path.slice(path.indexOf("id=") + 3));
      if (bundle === undefined) {
        path = this.props.location.search;
        id = parseInt(path.slice(path.indexOf("id=") + 3));
      }
      await API.callEndpoint(
        "GET",
        "Bearer",
        `/api/bundles?childtables=calls&childtables=history&id=${id}`
      )
        .then((response) => {
          bundle = response.data[0];
        })
        .catch((error) => {
          console.log(error.error);
        });

      let subscription = "Free";
      if (bundle.planid === 2) {
        subscription = "Prime";
      } else if (bundle.planid === 3) {
        subscription = "Crown";
      }

      // equity
      let equityAddScripArray = [];
      let optionsAddScripArray = [];
      // let fund_hedged_id = bundle?.bundles_calls[]

      // bundle.bundles_calls.map((item) => {
      //   // console.log("item", item);

      //   if (item.call_class === "Equity") {
      //     // console.log("exitprice",item.info.exit_price);
      //     equityAddScripArray = [
      //       ...equityAddScripArray,
      //       {
      //         id: item.id,
      //         alreadyPresent: 1,
      //         scripNameValue: { value: item.scrip_symbol, label: item.name },
      //         minPriceValue: item.info.minprice,
      //         maxPriceValue: item.info.maxprice,
      //         quantityValue: item.info.quantity,
      //         quantityPercentValue: item.info.quantity_percent,
      //         stopLossTypeEquity: {
      //           value: item.info.stop_loss_type,
      //           label: item.info.stop_loss_type,
      //         },
      //         call_type: {
      //           value: item.call_type,
      //           label: capitalizeFirstLetter(item.call_type),
      //         },
      //         call_status: {
      //           value: item.call_status,
      //           label: capitalizeFirstLetter(item.call_status),
      //         },

      //         closeType: {
      //           value: item.close_type,
      //           label: capitalizeFirstLetter(item.close_type),
      //         },
      //         closeTotal: item.returns,
      //         exit_price: item.info.exit_price ? item.info.exit_price : "",
      //         // leg: item?.info?.legs_info,
      //         ...item?.info?.leg_info[0],
      //         // createdAt:item?.info?.legs_info[0]?.createdAt,
      //         // updatedAt:item?.info?.legs_info[0]?.updatedAt,
      //       },
      //     ];
      //   } else {
      //     let legs = [];
      //     legs = item.info?.legs_info?.map((leg, index) => {
      //       // console.log("leg", leg);
      //       return {
      //         action: { value: leg.action, label: leg.action.toUpperCase() },
      //         option: {
      //           value: leg.strike_price?.slice(-2),
      //           label: leg.strike_price?.slice(-2),
      //         },

      //         // option: {
      //         //   value: leg.instrument_type,
      //         //   label: leg.instrument_type,
      //         // },
      //         // expiry: new Date(
      //         //   moment(leg.series, "Do MMM'YY").local().format("YYYY-MM-DD")
      //         // ),
      //         expiry: new Date(
      //           moment(leg.series, "Do MMM'YY").local().format("YYYY-MM-DD")
      //         ),
      //         strikePrice: leg.strike_price?.split(
      //           leg.strike_price?.slice(-2)
      //         )[0],
      //         // strikePrice: leg.strike,
      //         qtyValue: leg.quantity,
      //         Price: leg.entry_price_start,
      //         entry_version: leg?.entry_version,
      //         exit_version: leg?.exit_version,
      //         exitPriceToggle:
      //           leg.exit_price === undefined || leg.exit_price === ""
      //             ? false
      //             : true,
      //         exitPrice: leg.exit_price ? leg.exit_price : "",
      //         status:
      //           leg.exit_price === undefined || leg.exit_price === ""
      //             ? "Open"
      //             : "closed",
      //         id: leg?.id,
      //         legExit:
      //           leg.exit_price === undefined || leg.exit_price === ""
      //             ? false
      //             : true,
      //         type: leg?.type,
      //         instrument_id: leg?.instrument_id ? leg?.instrument_id : "",
      //         instrument_type: leg?.instrument_type,

      //         bundle_calls_id: item?.id,
      //         bundleId: leg?.bundleId,
      //         fund_hedged_id: leg?.fund_hedged_id,
      //       };
      //     });

      //     optionsAddScripArray = [
      //       ...optionsAddScripArray,
      //       {
      //         id: item.id,
      //         alreadyPresent: 1,
      //         exitPrice: item.info.exit_price,
      //         scripNameValue: { value: item.scrip_symbol, label: item.name },
      //         capitalReq: item.capital_required,
      //         currentPrice: item?.current_price,
      //         fund_id: item?.fund_id,
      //         hedged_bundle_version: item?.hedged_bundle_version,
      //         call_type: {
      //           value: item.call_type,
      //           label: capitalizeFirstLetter(item.call_type),
      //         },

      //         call_status: {
      //           value: item.call_status,
      //           label: capitalizeFirstLetter(item.call_status),
      //         },
      //         closeType: {
      //           value: item.close_type,
      //           label: capitalizeFirstLetter(item.close_type),
      //         },
      //         closeTotal: item.returns,
      //         strategy: {
      //           value: item.info.strategy,
      //           label: capitalizeFirstLetter(item.info.strategy),
      //         },
      //         maxLoss: item.info.max_loss,
      //         stopLoss: item.info.stopp_loss_value,
      //         stopLossTypeOption: {
      //           value: item.info.stop_loss_type,
      //           label: item.info.stop_loss_type,
      //         },
      //         fund_hedged_id: bundle?.fund_hedgeds[0]?.fund_id,
      //         legs,
      //       },
      //     ];
      //   }
      // });

      bundle.bundles_calls_legs
        ?.filter((elem) => elem?.type == "Equity")
        ?.map((item) => {
          // console.log("item", item);

          // if (item.call_class === "Equity") {
          // console.log("exitprice",item.info.exit_price);
          equityAddScripArray = [
            ...equityAddScripArray,
            {
              id: item.id,
              alreadyPresent: 1,
              scripNameValue: { value: item.strike, label: item.strike },
              minPriceValue: item.entry_price_start,
              maxPriceValue: item.entry_price_end,
              quantityValue: item.quantity,
              quantityPercentValue: item.quantity_percent,
              stopLossTypeEquity: {
                value: item.stop_loss_type,
                label: item.stop_loss_type,
              },
              call_type: {
                value: item.call_type,
                label: capitalizeFirstLetter(item.call_type),
              },
              call_status: {
                value: item.status,
                label: capitalizeFirstLetter(item.status),
              },

              closeType: {
                value: item.closed_type,
                label: capitalizeFirstLetter(item.closed_type),
              },
              closeTotal: item.returns,
              exit_price: item.exit_price ? item.exit_price : "",
              closed_on: item?.closed_on,
              // leg: item?.info?.legs_info,
              ...item?.info?.leg_info[0],
              createdAt: item?.createdAt,
              updatedAt: item?.updatedAt,
            },
          ];
          // } else {
          //   let legs = [];
          //   legs = item.info?.legs_info?.map((leg, index) => {
          //     // console.log("leg", leg);
          //     return {
          //       action: { value: leg.action, label: leg.action.toUpperCase() },
          //       option: {
          //         value: leg.strike_price?.slice(-2),
          //         label: leg.strike_price?.slice(-2),
          //       },

          //       // option: {
          //       //   value: leg.instrument_type,
          //       //   label: leg.instrument_type,
          //       // },
          //       // expiry: new Date(
          //       //   moment(leg.series, "Do MMM'YY").local().format("YYYY-MM-DD")
          //       // ),
          //       expiry: new Date(
          //         moment(leg.series, "Do MMM'YY").local().format("YYYY-MM-DD")
          //       ),
          //       strikePrice: leg.strike_price?.split(
          //         leg.strike_price?.slice(-2)
          //       )[0],
          //       // strikePrice: leg.strike,
          //       qtyValue: leg.quantity,
          //       Price: leg.entry_price_start,
          //       entry_version:leg?.entry_version,
          //       exit_version:leg?.exit_version,
          //       exitPriceToggle:
          //         leg.exit_price === undefined || leg.exit_price === ""
          //           ? false
          //           : true,
          //       exitPrice: leg.exit_price ? leg.exit_price : "",
          //       status:leg.exit_price === undefined || leg.exit_price === ""
          //       ? "Open"
          //       : "closed",
          //       id:leg?.id,
          //       legExit:
          //         leg.exit_price === undefined || leg.exit_price === ""
          //           ? false
          //           : true,
          //           type:leg?.type,
          //           instrument_id:leg?.instrument_id ? leg?.instrument_id : "",
          //           instrument_type:leg?.instrument_type,

          //           bundle_calls_id: item?.id,
          //                 bundleId: leg?.bundleId,
          //                 fund_hedged_id:leg?.fund_hedged_id ,

          //     };
          //   });

          //   optionsAddScripArray = [
          //     ...optionsAddScripArray,
          //     {
          //       id: item.id,
          //       alreadyPresent: 1,
          //       exitPrice: item.info.exit_price,
          //       scripNameValue: { value: item.scrip_symbol, label: item.name },
          //       capitalReq: item.capital_required,
          //       currentPrice:item?.current_price,
          //       fund_id:item?.fund_id,
          //       hedged_bundle_version:item?.hedged_bundle_version,
          //       call_type: {
          //         value: item.call_type,
          //         label: capitalizeFirstLetter(item.call_type),
          //       },

          //       call_status: {
          //         value: item.call_status,
          //         label: capitalizeFirstLetter(item.call_status),
          //       },
          //       closeType: {
          //         value: item.close_type,
          //         label: capitalizeFirstLetter(item.close_type),
          //       },
          //       closeTotal: item.returns,
          //       strategy: {
          //         value: item.info.strategy,
          //         label: capitalizeFirstLetter(item.info.strategy),
          //       },
          //       maxLoss: item.info.max_loss,
          //       stopLoss: item.info.stopp_loss_value,
          //       stopLossTypeOption: {
          //         value: item.info.stop_loss_type,
          //         label: item.info.stop_loss_type,
          //       },
          //       fund_hedged_id:bundle?.fund_hedgeds[0]?.fund_id,
          //       legs,
          //     },
          //   ];
          // }
        });

      bundle.fund_hedgeds?.map((item) => {
        // console.log("item", item);

        // if (item.call_class === "Equity") {
        // console.log("exitprice",item.info.exit_price);
        // equityAddScripArray = [
        //   ...equityAddScripArray,
        //   {
        //     id: item.id,
        //     alreadyPresent: 1,
        //     scripNameValue: { value: item.strike, label: item.strike },
        //     minPriceValue: item.entry_price_start,
        //     maxPriceValue: item.entry_price_end,
        //     quantityValue: item.quantity,
        //     quantityPercentValue: item.quantity_percent,
        //     stopLossTypeEquity: {
        //       value: item.stop_loss_type,
        //       label: item.stop_loss_type,
        //     },
        //     call_type: {
        //       value: item.call_type,
        //       label: capitalizeFirstLetter(item.call_type),
        //     },
        //     call_status: {
        //       value: item.status,
        //       label: capitalizeFirstLetter(item.status),
        //     },

        //     closeType: {
        //       value: item.closed_type,
        //       label: capitalizeFirstLetter(item.closed_type),
        //     },
        //     closeTotal: item.returns,
        //     exit_price: item.exit_price ? item.exit_price : "",
        //     // leg: item?.info?.legs_info,
        //     ...item?.info?.leg_info[0]
        //     // createdAt:item?.info?.legs_info[0]?.createdAt,
        //     // updatedAt:item?.info?.legs_info[0]?.updatedAt,

        //   },
        // ];
        // } else {
        let legs = [];
        legs = item?.bundles_calls_legs?.map((leg, index) => {
          console.log("leg", leg.action);
          return {
            action: { value: leg.action, label: leg.action.toUpperCase() },
            option: {
              value: leg.strike_price?.slice(-2),
              label: leg.strike_price?.slice(-2),
            },

            // option: {
            //   value: leg.instrument_type,
            //   label: leg.instrument_type,
            // },
            // expiry: new Date(
            //   moment(leg.series, "Do MMM'YY").local().format("YYYY-MM-DD")
            // ),
            expiry: new Date(
              moment(leg.series, "Do MMM'YY").local().format("YYYY-MM-DD")
            ),
            strikePrice: leg.strike_price?.split(
              leg.strike_price?.slice(-2)
            )[0],
            // strikePrice: leg.strike,
            qtyValue: leg.quantity,
            Price: leg.entry_price_start,
            entry_version: leg?.entry_version,
            exit_version: leg?.exit_version,
            exitPriceToggle:
              leg.exit_price === undefined || leg.exit_price === ""
                ? false
                : true,
            exitPrice: leg.exit_price ? leg.exit_price : "",
            status:
              leg.exit_price === undefined || leg.exit_price === ""
                ? "Open"
                : "closed",
            id: leg?.id,
            legExit:
              leg.exit_price === undefined || leg.exit_price === ""
                ? false
                : true,
            type: leg?.type,
            instrument_id: leg?.instrument_id ? leg?.instrument_id : "",
            instrument_type: leg?.instrument_type,

            bundle_calls_id: item?.id,
            bundleId: leg?.bundleId,
            fund_hedged_id: leg?.fund_hedged_id,
            closed_on: leg?.closed_on,
            createdAt: leg?.createdAt
          };
        });

        optionsAddScripArray = [
          ...optionsAddScripArray,
          {
            id: item.id,
            alreadyPresent: 1,
            exitPrice: item.hedged_exitprice,
            scripNameValue: {
              value: bundle?.bundles_calls?.filter(
                (elem) => elem?.fund_hedged_id == item?.id
              )[0]?.name,
              label: bundle?.bundles_calls?.filter(
                (elem) => elem?.fund_hedged_id == item?.id
              )[0]?.name,
            },
            capitalReq: item.hedged_capital_required,
            currentPrice: 0,
            fund_id: item?.fund_id,
            hedged_bundle_version: item?.hedged_bundle_version,
            call_type: {
              value: item.hedged_type,
              label: capitalizeFirstLetter(item.hedged_type),
            },

            call_status: {
              value: item.hedged_status,
              label: capitalizeFirstLetter(item.hedged_status),
            },
            closeType: {
              value: item.close_type,
              label: capitalizeFirstLetter(item.close_type),
            },
            closeTotal: item.returns,
            closed_on: item?.closed_on,
            strategy: {
              value: item.hedged_strategy,
              label: capitalizeFirstLetter(item.hedged_strategy),
            },
            maxLoss: item.hedged_maxloss,
            stopLoss: item.hedged_stoploss,
            stopLossTypeOption: {
              value: item.hedged_stoplosstype,
              label: item.hedged_stoplosstype,
            },
            fund_hedged_id: item?.id,
            legs,
            createdAt: item?.createdAt
          },
        ];
        // }
      });

      this.setState({
        modify: true,
        modifyId: id,
        bundlesTableComponent: false,
        BundlesTabsComponent: true,
        equityTabActive: true,
        optionsTabActive: false,
        equityAddScripArray,
        optionsAddScripArray,
        type: bundle.bundle_type,
        use_old_formula: bundle?.use_old_formula,
        enter_status: bundle?.enter_status,
        bundleTitle: bundle.title,
        bundleId: bundle?.id,
        bundle_version: bundle?.bundle_version,
        // bundleLabel: bundle.label,
        subscription,
        expectedReturn: bundle.expected_returns,
        currentReturn: bundle.current_returns,
        equityCapital: bundle.equity_capital_required,
        eqtynote: bundle.equity_notes,
        optionsCapital: bundle.hedged_capital_required,
        optionalnote: bundle.hedged_notes,
        meter: bundle.meter,
        nifty_growth: bundle?.nifty_growth,
      });
    }
  };

  changemenuTab = (menuname, index) => {
    // console.log(menuname, index)
    let newMen = [...this.state.navList];
    newMen.map((items) => {
      return (items.navStatus = false);
    });
    newMen[index].navStatus = true;
    this.setState({
      navList: newMen,
      curTab: { id: index + 1, tabname: menuname },
    });
  };

  componentDidMount = () => {
    this.modifyComponent();
    // this.getBundleDatas();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.props.match.params.bundletype !== prevProps.match.params.bundletype
    ) {
      this.setState(
        {
          activeComponent: this.props.match.params.bundletype,
        },

        () => {
          this.modifyComponent();
        }
      );
    }
  };

  getBundleDatas = async () => {
    await API.callEndpoint("GET", "Bearer", "/api/bundles").then(
      async (response) => {
        await this.setState({ Bundledatas: response.data });
      }
    );
  };

  getDetails = (dataid) => {
    let getbndldtl = this.state.Bundledatas.filter((bndldtl) => {
      return bndldtl.id === dataid;
    });
    // console.log('getbndldtl: ',getbndldtl);
    this.setState({ callDetailData: getbndldtl });
    // console.log("Props: ", this.props);
    // this.props.history.push("/bundles/details");
    this.props.history.push(`/bundles/details?id=${dataid}`);
  };

  // Table remove > button > function
  // tableRemoveBtn = (index) => {
  //   const getArray = [...this.state.bundleTableData];
  //   getArray.splice(index, 1);
  //   this.setState({
  //     bundleTableData: getArray,
  //   });
  // };

  tableRemoveBtn = (getId) => {
    console.log(getId);
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
        API.callEndpoint("DELETE", "Bearer", `/api/bundles/${getId}`)
          .then((response) => {
            console.log(response.data, "delete API success");
            this.getBundleDatas();
          })
          .catch((error) => {
            console.log(error.error);
          });
      }
    });
  };

  // add bundle and Description > function
  addIOChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  // Member Ship > onchange > function
  memberShipSelectOnChange = (memberSelectValue) => {
    this.setState({
      memberSelectValue: memberSelectValue,
    });
  };

  // Capital input > onchange > function
  capitalOnchange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  // Legs select value > onchange > function
  legsSelectOnchange = (legsSelectValue, scripIndex, legIndex) => {
    console.log(legsSelectValue, scripIndex, legIndex);
    this.state.optionsAddScripArray[scripIndex].legs[legIndex].selectValue =
      legsSelectValue.value;
    this.setState({
      legsSelectValue: legsSelectValue,
      optionsAddScripArray: this.state.optionsAddScripArray,
      legsSelectValue,
      //['legs'+[scripIndex+1]+[legIndex+1]]: legsSelectValue
    });
  };

  // Legs add > button
  legsAddBtn = (e, index) => {
    let newState = Object.assign({}, this.state);
    newState.optionsAddScripArray[index].legs = [
      ...newState.optionsAddScripArray[index].legs,
      {
        selectValue: "",
        qtyValue: "",
        minPrice: "",
        maxPrice: "",
      },
    ];
    this.setState(newState);
  };

  // Tabs change > function
  tabsChange = (txt) => {
    this.setState({
      equity: false,
      option: false,
    });
    this.setState({
      [txt]: true,
    });
  };

  // Equity add script > click function
  equityAddScrip = (event) => {
    this.setState({
      equityAddScripArray: [
        ...this.state.equityAddScripArray,
        {
          scripNameValue: "",
          minPriceValue: "",
          maxPriceValue: "",
          quantityValue: "",
        },
      ],
    });
  };

  // equity input onchange > function
  equityIOChange = (event, index) => {
    const { name, value } = event.target;
    this.state.equityAddScripArray[index][name] = value;
    this.setState({
      equityAddScripArray: this.state.equityAddScripArray,
      value,
    });
  };

  // options input on change > function
  optionsIOChange = (event, index) => {
    const { name, value } = event.target;
    this.state.optionsAddScripArray[index][name] = value;
    this.setState({
      optionsAddScripArray: this.state.optionsAddScripArray,
      value,
    });
  };

  // options legs input on change > function
  optionsLegsIOChange = (event, scripIndex, legIndex) => {
    // console.log(scripIndex, legIndex);
    const { name, value } = event.target;
    this.state.optionsAddScripArray[scripIndex].legs[legIndex][name] = value;
    this.setState({
      optionsAddScripArray: this.state.optionsAddScripArray,
      value,
    });
  };

  // OPtions add scrip > click function
  optionsAddScrip = () => {
    this.setState({
      optionsAddScripArray: [
        ...this.state.optionsAddScripArray,
        {
          scripNameValue: "",
          reqdCapitalValue: "",
          legs: [
            {
              selectValue: "",
              qtyValue: "",
              minPrice: "",
              maxPrice: "",
            },
          ],
        },
      ],
    });
  };

  setDoneBtnDisable = (value) => {
    this.setState({ doneBtnDisable: value });
  };

  // Send JSON data > function
  bundleDataJson = async () => {
    this.setState({ doneBtnDisable: true });
    let closedAlert = true;

    this.state.equityAddScripArray.map((equityItem) => {
      if (equityItem.call_status.value === "closed") {
        if (
          equityItem.closeType.value === "" ||
          equityItem.closeType.value === null ||
          equityItem.closeTotal === "" ||
          equityItem.closeTotal === null
        ) {
          closedAlert = false;
        }
      }
    });

    this.state.optionsAddScripArray.map((optionsItem) => {
      if (optionsItem.call_status.value === "closed") {
        if (
          optionsItem.closeType.value === "" ||
          optionsItem.closeType.value === null ||
          optionsItem.closeTotal === "" ||
          optionsItem.closeTotal === null
        ) {
          closedAlert = false;
        }
      }
    });

    if (!closedAlert) {
      swal({
        title: `Error`,
        text: "close type and close total cannot be empty.",
        icon: "error",
      });
      this.setState({ doneBtnDisable: false });
    } else {
      if (!this.state.modify) {
        if (
          await createBundle(
            {
              data: this.state,
            },
            this.setDoneBtnDisable
          )
        ) {
          this.props.history.push("/bundles/table");
        }
      } else {
        if (
          await updateBundle(
            {
              data: this.state,
            },
            this.state.modifyId,
            this.setDoneBtnDisable
          )
        ) {
          this.props.history.push("/bundles/table");
        }
      }
    }

    // console.log(this.state.bundleData);
  };

  targetDeleteBtn = (index) => {
    let getequArr = [...this.state.equityAddScripArray];
    getequArr.splice(index, 1);
    if (getequArr.length === 0) {
      this.setState({
        equityAddScripArray: getequArr,
        equityCapital: null,
        eqtynote: null,
      });
    } else {
      this.setState({ equityAddScripArray: getequArr });
    }
  };

  optionsTargetDeleteBtn = (scripIndex, legIndex) => {
    const dupliState = [...this.state.optionsAddScripArray];
    const dubscriptarrlen = dupliState[scripIndex].legs;
    if (dubscriptarrlen.length > 1) {
      dubscriptarrlen.splice(legIndex, 1);
      this.setState({ optionsAddScripArray: dupliState });
    } else if (dubscriptarrlen.length === 1) {
      const dupliState = [...this.state.optionsAddScripArray];
      dupliState.splice(legIndex, 1);
      this.setState({ optionsAddScripArray: dupliState });
    } else {
      return false;
    }
  };
  updateNotify = (value) => {
    this.setState({ notify: value })
  }

  render() {
    let getTbname = this.state.navList.filter((tbnm) => {
      return tbnm.navStatus === true;
    });
    let tabname = getTbname[0].tabname.replace(" ", "");

  

    return (
      <section className="admin-bundle-page">
        {this.state.bundlesTableComponent ? (
          <BundlesTable
            state={this.state}
            tableRemoveBtn={this.tableRemoveBtn}
            getDetails={this.getDetails}
            getBundleDatas={this.getBundleDatas}
            changemenuTab={this.changemenuTab}
          />
        ) : (
          ""
        )}
        {this.state.BundlesTabsComponent ? (
          <BundlesTabs
            state={this.state}
            legselect={legselect}
            optionselect={optionselect}
            equityType={equityType}
            callStatus={callStatus}
            closeType={closeType}
            updateState={this.updateState}
            membershipSelectOptions={membershipSelectOptions}
            shouldUseOldRocFormulaSelectOptions={
              shouldUseOldRocFormulaSelectOptions
            }
            canEnterStatusSelectOptions={canEnterStatusSelectOptions}
            typesOfLiveFund={typesOfLiveFund}
            legsAddBtn={this.legsAddBtn}
            tabsChange={this.tabsChange}
            equityAddScrip={this.equityAddScrip}
            equityIOChange={this.equityIOChange}
            optionsAddScrip={this.optionsAddScrip}
            optionsIOChange={this.optionsIOChange}
            optionsLegsIOChange={this.optionsLegsIOChange}
            addIOChange={this.addIOChange}
            memberShipSelectOnChange={this.memberShipSelectOnChange}
            capitalOnchange={this.capitalOnchange}
            legsSelectOnchange={this.legsSelectOnchange}
            targetDeleteBtn={this.targetDeleteBtn}
            optionsTargetDeleteBtn={this.optionsTargetDeleteBtn}
            optionsValue={optionsValue}
            optionsType={optionsType}
            stopLossTypeSelect={stopLossTypeSelect}
            updateNotify={this.updateNotify}
          />
        ) : (
          ""
        )}
        {this.state.bundlesDetailComponent ? (
          <BundleDetails state={this.state} props={this.props} />
        ) : (
          ""
        )}
        {/* Buttom done > button */}
        <section
          className={`btm-strip ${this.state.BundlesTabsComponent ? "d-block" : "d-none"
            }`}
        >
          <aside className="proceed-btn-group">
            <a
              title="DONE"
              onClick={this.bundleDataJson}
              className={
                this.state.doneBtnDisable ||
                  (this.state.equityAddScripArray.length === 0 &&
                    this.state.optionsAddScripArray.length === 0)
                  ? "disabled"
                  : ""
              }
            >
              DONE
            </a>
          </aside>
        </section>
      </section>
    );
  }
}

export default Bundles;
