import React from "react";
import * as API from "../../configuration/apiconfig";
import Artworkr from "../../assets/images/artwork-1.png";
import Influx from "../../assets/images/logobk.png";
import { ReactComponent as Loader } from "../../assets/images/loader.svg";
import constant from "../../configuration/config";
import { Redirect } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { useHistory } from "react-router-dom";

class Loginuser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Username: "",
      password: "",
      id: "",
      newPassword: "",
      code: "",
      showError: false,
      errorMsg: "",
      showSuccess: false,
      showLoader: false,
      loginScreen: true,
      forgetPasswordScreen: false,
      verified: false,
      resetPasswordVerificationScreen: false,
    };
  }

  LoginGet = () => {
    this.setState({
      showError: false,
      showLoader: true,
    });
    API.getAccessToken(this.state.Username, this.state.password, true)
      .then((response) => {
        try {
          this.setState({ showLoader: false });
          this.props.setAccessToken(response.data.accessToken);

          // if (response.data.role == "CMSSALESADMINUSER") {
          // window.location.replace(`/usermanagement`);
          // }
          // if (response.data.role == "CMSREASEARCHADMINUSER") {
          // console.log(response.data.role)
          // window.location.replace(`/calls`);
          // }
        } catch (e) {
          this.setState({
            showError: true,
          });
          document.getElementById("wrongmess").style.display = "block";
        }
      })
      .catch((ex) => {
        this.setState({ showLoader: false });
        this.setState({
          showError: true,
        });
        document.getElementById("wrongmess").style.display = "block";
      });
  };
  handleVerify = () => {
    this.setState({
      showError: false,
      showLoader: true,
    });
    let data = {
      username: this.state.id,
    };
    API.callEndpoint(
      "POST",
      "Basic",
      "/users/reset-password-verification",
      data
    )
      .then((response) => {
        this.setState({
          Username: "",
          password: "",
          id: this.state.id,
          newPassword: "",
          showLoader: false,
          loginScreen: false,
          errorMsg: "",
          forgetPasswordScreen: false,
          resetPasswordVerificationScreen: true,
        });
      })
      .catch((ex) => {
        this.setState({ showLoader: false });
        this.setState({
          showError: true,
          errorMsg: ex.error,
        });
        document.getElementById("wrongmess").style.display = "block";
      });
  };
  handleReset = () => {
    this.setState({
      showError: false,
      errorMsg: "",
      showLoader: true,
    });
    let data = {
      email: this.state.id,
      password: this.state.newPassword,
      verificationCode: this.state.code,
    };
    API.callEndpoint("PATCH", "Basic", "/users/reset-admin-password", data)
      .then((response) => {
        this.setState({
          Username: "",
          password: "",
          id: "",
          code: "",
          newPassword: "",
          showSuccess: true,
          showLoader: false,
          loginScreen: true,
          forgetPasswordScreen: false,
          resetPasswordVerificationScreen: false,
        });
      })
      .catch((ex) => {
        this.setState({ showLoader: false });
        this.setState({
          showError: true,
          errorMsg: ex.error,
        });
        document.getElementById("wrongmess").style.display = "block";
      });
  };

  getinput = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleKeypress = (e) => {
    if (e.key === "Enter") {
      this.LoginGet();
    }
  };

  render() {
    return (
      <section className="innerpage" onKeyDown={this.handleKeypress}>
        <div
          className={`customLoader ${this.state.showLoader == true ? "show d-block" : "d-none"
            }`}
        >
          <Loader className="loaderIcon"></Loader>
        </div>
        <section className="loginpage position-relative">
          <div className="main-login main-login--fullscreen">
            <div className="main-login-header">
              <div className="row">
                <div className="col-md-1"></div>
                <div className="col-md-5 mobile-responsive-login">
                  <div className="main-login-block">
                    {this.state.loginScreen && (
                      <div className="main-login-form">
                        <div className="main-login-header">
                          <span className="main-login-header-first">
                            Welcome to hedged CMS
                          </span>
                        </div>
                        <div className="login-form">
                          <div class="form-group">
                            <label for="username">Username</label>
                            <input
                              type="text"
                              class="form-control"
                              id="username"
                              name="Username"
                              onChange={(e) => this.getinput(e)}
                            />
                          </div>
                          <div class="form-group">
                            <label for="password">Password</label>
                            <input
                              type="password"
                              class="form-control"
                              id="password"
                              name="password"
                              onChange={(e) => this.getinput(e)}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <button
                              type="button"
                              class="btn btn-primary"
                              disabled={
                                this.state.Username.length != 0 &&
                                  this.state.password.length != 0
                                  ? false
                                  : true
                              }
                              onClick={this.LoginGet}
                            >
                              Login
                            </button>
                            <p
                              onClick={() =>
                                this.setState({
                                  forgetPasswordScreen: true,
                                  loginScreen: false,
                                  showError: false,
                                })
                              }
                              style={{ cursor: "pointer", color: "#f67452" }}
                            >
                              Forgot password?
                            </p>
                          </div>
                        </div>
                        <div
                          className={`alert alert-danger ${this.state.showError ? "" : "d-none"
                            }`}
                          id="wrongmess"
                        >
                          Invalid credentials. Please try again.
                        </div>
                      </div>
                    )}
                    {this.state.forgetPasswordScreen && (
                      <div className="main-login-form">
                        <div className="main-login-header">
                          <span className="main-login-header-first">
                            Forget Password
                          </span>
                        </div>
                        <div className="login-form">
                          <div class="form-group">
                            <label for="id">Username</label>
                            <input
                              type="text"
                              class="form-control"
                              id="id"
                              name="id"
                              value={this.state.id}
                              onChange={(e) => this.getinput(e)}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <button
                              type="button"
                              class="btn btn-primary"
                              disabled={
                                this.state.id.length != 0 ? false : true
                              }
                              onClick={this.handleVerify}
                            >
                              Continue
                            </button>
                            <div style={{ textAlign: "right" }}>
                              <p
                                onClick={() =>
                                  this.setState({
                                    Username: "",
                                    password: "",
                                    id: "",
                                    code: "",
                                    newPassword: "",
                                    showSuccess: false,
                                    showLoader: false,
                                    verified: true,
                                    showError: false,
                                    forgetPasswordScreen: false,
                                    loginScreen: false,
                                    resetPasswordVerificationScreen: true,
                                  })
                                }
                                style={{ cursor: "pointer", color: "#f67452" }}
                              >
                                Already have Verification code ?
                              </p>
                              <p
                                onClick={() =>
                                  this.setState({
                                    Username: "",
                                    password: "",
                                    id: "",
                                    newPassword: "",
                                    showSuccess: false,
                                    showLoader: false,
                                    forgetPasswordScreen: false,
                                    loginScreen: true,
                                  })
                                }
                                style={{ cursor: "pointer", color: "#f67452" }}
                              >
                                Back to Login
                              </p>
                            </div>
                          </div>
                        </div>
                        <div
                          className={`alert alert-danger ${this.state.showError ? "" : "d-none"
                            }`}
                          id="wrongmess"
                        >
                          {this.state.errorMsg}
                        </div>
                      </div>
                    )}
                    {this.state.resetPasswordVerificationScreen && (
                      <div className="main-login-form">
                        <div className="main-login-header">
                          <span className="main-login-header-first">
                            Reset Password
                          </span>
                        </div>
                        <div className="login-form">
                          <div class="form-group">
                            <label for="id">Username</label>
                            <input
                              type="text"
                              class="form-control"
                              id="id"
                              name="id"
                              disabled={!this.state.verified}
                              value={this.state.id}
                              onChange={(e) => this.getinput(e)}
                            />
                          </div>
                          <div class="form-group">
                            <label for="newPassword">New Password</label>
                            <input
                              type="password"
                              class="form-control"
                              id="newPassword"
                              value={this.state.newPassword}
                              name="newPassword"
                              onChange={(e) => this.getinput(e)}
                            />
                          </div>
                          <div class="form-group">
                            <label for="code">Verification code</label>
                            <input
                              type="text"
                              class="form-control"
                              id="code"
                              value={this.state.code}
                              name="code"
                              onChange={(e) => this.getinput(e)}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <button
                              type="button"
                              class="btn btn-primary"
                              disabled={
                                this.state.id.length != 0 &&
                                  this.state.newPassword.length != 0 &&
                                  this.state.code.length != 0
                                  ? false
                                  : true
                              }
                              onClick={this.handleReset}
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                        <div
                          className={`alert alert-danger ${this.state.showError ? "" : "d-none"
                            }`}
                          id="wrongmess"
                        >
                          {this.state.errorMsg}
                        </div>
                        <div
                          className={`alert alert-success ${this.state.showSuccess ? "" : "d-none"
                            }`}
                          id="wrongmess"
                        >
                          Password Reset Successfull.
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 mobile-responsive">
                  <div className="main-login__header__left">
                    <img src={Artworkr} alt="Clean UI Admin Template" />
                  </div>
                </div>
              </div>
            </div>
            <span className="footerlogo">
              Powered by <img src={Influx} alt="Influx" />
            </span>
          </div>
        </section>
      </section>
    );
  }
}

export default Loginuser;
