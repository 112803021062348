import React from 'react';
import Chart from 'react-apexcharts';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import * as API from '../../configuration/apiconfig';
import moment from 'moment';
import { ReactComponent as Loader } from '../../assets/images/loader.svg';
import swal from 'sweetalert';

function DatePickerFunComp(data) {
  let listOfDate = [];
  data.date?.map((item) => {
    listOfDate.push(new Date(item.event_date_time));
  });
  return <DayPicker initialMonth={new Date()} selectedDays={listOfDate} />;
}

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chartShow: true,
      showLoader: false,
      series: [
        {
          name: 'round-1',
          data: [
            {
              x: ['Open'],
              y: 5,
            },
            {
              x: ['Not Picking'],
              y: 2,
            },
            {
              x: ['Following Up'],
              y: 1,
            },
            {
              x: ['Closed'],
              y: 3,
            },
            {
              x: ['Sale'],
              y: 2,
            },
          ],
        },
      ],
      options: {
        chart: {
          type: 'bar',
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: true,
        },
        yaxis: {
          labels: {
            align: 'center',
          },
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
        legend: {
          horizontalAlign: 'center',
        },
      },
      dashboardData: {},
      openCalls: [],
      closeCalls: [],
      sales: {},
      events: [],
      hedgeometer: {},
    };
  }

  // componentDidMount = async () => {
  //   this.setState({
  //     showLoader: true,
  //   });
  //   // this.fetchcall();
  // };

  fetchcall = async () => {
    this.setState({
          showLoader: true,
        });
    return await API.callEndpoint('GET', 'Bearer', `/api/calls/dashboard`)
      .then((response) => {
        let fetchData = response.data;

        this.setState({
          showLoader: false,
          dashboardData: fetchData,
          openCalls: response.data.openCalls,
          events: response.data.events,
          closeCalls: response.data.closedCalls,
          sales: response.data.sales,
          hedgeometer: response.data.hedgeometer,
        });
      })
      .catch((error) => {
        console.log(error);
        swal({
          text: 'An error occured, try again!',
          icon: 'info',
        });
      });
  };

  render() {
    return (
      <section className="admin-dashboard">
        <div
          className={`customLoader ${
            this.state.showLoader == true ? 'show d-block' : 'd-none'
          }`}
        >
          <Loader className="loaderIcon"></Loader>
        </div>
        <section className='open-calls-blk typ-head' > 
        <h1 className="head text-uppercase m-0">Dashboard</h1>
        {/* <div className="new-call-btn"> */}
        <button type='button' className='load-btn' onClick={this.fetchcall}>Load Data</button>
        </section>
        {this.state.dashboardData &&
        <>
        <section className="open-calls-blk">
          <section className="row">
            {this.state.openCalls.length > 0 && (
              <section className="col-lg-9">
                <h2>Open Calls</h2>
                <section className="das-table-blk">
                  <table className="table table-bordered">
                    <thead>
                      <tr className="bg">
                        <th className="bg-none border-0">&nbsp;</th>
                        {this.state.openCalls.map((item, i) => (
                          <th colSpan="3">{item.plan}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="num">
                        <td className="border-0">&nbsp;</td>
                        {this.state.openCalls.map((item, i) => (
                          <td colSpan="3">{item.userCount}</td>
                        ))}
                      </tr>
                      <tr className="bg">
                        <td className="bg-none border-0">&nbsp;</td>
                        <td>Newbie</td>
                        <td>Experienced</td>
                        <td>Both</td>
                        <td>Newbie</td>
                        <td>Experienced</td>
                        <td>Both</td>
                        {/* <td>Newbie</td>
                            <td>Experienced</td> */}
                        <td colSpan="3">Both</td>
                      </tr>
                      <tr className="fs">
                        <td className="border-0">&nbsp;</td>
                        {this.state.openCalls.slice(0, 2).map((item, i) => (
                          <>
                            <td>{item.userTypes.totalNewbie}</td>
                            <td>{item.userTypes.totalExperienced}</td>
                            <td>{item.userTypes.totalBoth}</td>
                          </>
                        ))}
                        {this.state.openCalls.slice(2).map((item, i) => (
                          <>
                            <td colSpan="3">{item.userTypes.totalBoth}</td>
                          </>
                        ))}
                      </tr>
                      <tr>
                        <td>Equity</td>
                        {this.state.openCalls.slice(0, 2).map((item, i) => (
                          <>
                            <td>{item.userTypes.totalEquityNewbie}</td>
                            <td>{item.userTypes.totalEquityExperienced}</td>
                            <td>{item.userTypes.totalEquityBoth}</td>
                          </>
                        ))}
                        {this.state.openCalls.slice(2).map((item, i) => (
                          <>
                            <td colSpan="3">
                              {item.userTypes.totalEquityBoth}
                            </td>
                          </>
                        ))}
                      </tr>
                      <tr>
                        <td>Options</td>
                        {this.state.openCalls.slice(0, 2).map((item, i) => (
                          <>
                            <td>{item.userTypes.totalOptionsNewbie}</td>
                            <td>{item.userTypes.totalOptionsExperienced}</td>
                            <td>{item.userTypes.totalOptionsBoth}</td>
                          </>
                        ))}
                        {this.state.openCalls.slice(2).map((item, i) => (
                          <>
                            <td colSpan="3">
                              {item.userTypes.totalOptionsBoth}
                            </td>
                          </>
                        ))}
                      </tr>
                    </tbody>
                  </table>
                </section>
              </section>
            )}
            {Object.keys(this.state.hedgeometer).length > 0 && (
              <section className="col-lg-3">
                <h2>Hedgeometer - Current Status</h2>
                <div className="box d-flex justify-content-center">
                  <div className="box-inner d-flex align-items-center">
                    <h2>
                      {this.state.hedgeometer.status}{' '}
                      <span className="d-block text-center">
                        Updated{' '}
                        {moment(this.state.hedgeometer.updatedAt).format(
                          'DD/MM/YYYY'
                        )}{' '}
                        <strong className="d-block">
                          {this.state.hedgeometer.updatedAt.slice(11, 16)}
                        </strong>
                      </span>
                    </h2>
                  </div>
                </div>
              </section>
            )}
          </section>
        </section>
        {this.state.closeCalls.length > 0 && (
          <section className="close-call-blk">
            <h2>Closed Calls</h2>
            <section className="row">
              {this.state.closeCalls.map((item, i) => (
                <section key={i} className="col-lg-4">
                  <section className="box">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th colSpan="3">{item.plan}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>YTD ROC</td>
                          <td className="fs" colSpan="2">
                            {item.YTDRoc.toFixed(2)}%
                          </td>
                          {/* <td className="fs">27%</td> */}
                        </tr>
                        <tr>
                          <td>HIT RATE</td>
                          <td className="fs" colSpan="2">
                            {item.hitrate.toFixed(2)}%
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="3" className="p-0">
                            <table>
                              <thead>
                                <tr>
                                  <th></th>
                                  {item.plan !== 'Crown' && <th>Newbie</th>}
                                  {item.plan !== 'Crown' && (
                                    <th>Experienced</th>
                                  )}
                                  <th>Both</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="text-start">Calls Closed</td>
                                  {item.plan !== 'Crown' && (
                                    <td>{item.newbie.callCloseCount}</td>
                                  )}
                                  {item.plan !== 'Crown' && (
                                    <td>{item.experienced.callCloseCount}</td>
                                  )}
                                  <td>{item.both.callCloseCount}</td>
                                </tr>
                                <tr>
                                  <td className="text-start">Avg. Capital</td>
                                  {item.plan !== 'Crown' && (
                                    <td>
                                      Rs.{' '}
                                      {item.newbie.callCloseCount
                                        ? (
                                            item.newbie.capital /
                                            item.newbie.callCloseCount
                                          ).toFixed(2)
                                        : item.newbie.capital.toFixed(2)}
                                    </td>
                                  )}
                                  {item.plan !== 'Crown' && (
                                    <td>
                                      Rs.{' '}
                                      {item.experienced.callCloseCount
                                        ? (
                                            item.experienced.capital /
                                            item.experienced.callCloseCount
                                          ).toFixed(2)
                                        : item.experienced.capital.toFixed(2)}
                                    </td>
                                  )}
                                  <td>
                                    Rs.{' '}
                                    {item.both.callCloseCount
                                      ? (
                                          item.both.capital /
                                          item.both.callCloseCount
                                        ).toFixed(2)
                                      : item.both.capital.toFixed(2)}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-start">Total Profit</td>
                                  {item.plan !== 'Crown' && (
                                    <td>Rs. {item.newbie.profit || 0}</td>
                                  )}
                                  {item.plan !== 'Crown' && (
                                    <td>Rs. {item.experienced.profit || 0}</td>
                                  )}
                                  <td>Rs. {item.both.profit || 0}</td>
                                </tr>
                                <tr>
                                  <td className="text-start">Total Loss</td>
                                  {item.plan !== 'Crown' && (
                                    <td>Rs. {item.newbie.totalLoss || 0}</td>
                                  )}
                                  {item.plan !== 'Crown' && (
                                    <td>
                                      Rs. {item.experienced.totalLoss || 0}
                                    </td>
                                  )}
                                  <td>Rs. {item.both.totalLoss}</td>
                                </tr>
                                {/* <tr>
                                    <td className="text-start">Total Loss</td>
                                    <td>Rs 20,000</td>
                                    <td>Rs. 15,000</td>
                                  </tr> */}
                                <tr>
                                  <td className="text-start">Profit Calls</td>
                                  {item.plan !== 'Crown' && (
                                    <td>{item.newbie.callProfitCount}</td>
                                  )}
                                  {item.plan !== 'Crown' && (
                                    <td>{item.experienced.callProfitCount}</td>
                                  )}
                                  <td>{item.both.callProfitCount}</td>
                                </tr>
                                <tr>
                                  <td className="text-start">Loss Calls</td>
                                  {item.plan !== 'Crown' && (
                                    <td>{item.newbie.callLossCount}</td>
                                  )}
                                  {item.plan !== 'Crown' && (
                                    <td>{item.experienced.callLossCount}</td>
                                  )}
                                  <td>{item.both.callLossCount}</td>
                                </tr>
                                <tr>
                                  <td className="text-start">
                                    Break even Calls
                                  </td>
                                  {item.plan !== 'Crown' && (
                                    <td>{item.newbie.callEvenCount}</td>
                                  )}
                                  {item.plan !== 'Crown' && (
                                    <td>{item.experienced.callEvenCount}</td>
                                  )}
                                  <td>{item.both.callEvenCount}</td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </section>
              ))}
            </section>
          </section>
        )}

        {Object.keys(this.state.sales).length > 0 && (
          <section className="sales-blk">
            <h2>Sales</h2>
            <section className="row">
              <section className="col-lg-4">
                <div className="box d-flex justify-content-center align-content-stretch">
                  <div className="box-inner d-flex align-items-center align-self-stretch">
                    <h2>
                      Total Users{' '}
                      <span className="d-block text-center">
                        {' '}
                        <strong className="d-block">
                          {this.state.dashboardData.totalUsers}
                        </strong>
                      </span>
                    </h2>
                  </div>
                </div>
              </section>
              <section className="col-lg-4">
                <div className="box d-flex justify-content-center align-content-stretch">
                  <div className="box-inner d-flex align-items-center align-self-stretch">
                    <h2>
                      Total Registered Users{' '}
                      <span className="d-block text-center">
                        {' '}
                        <strong className="d-block">
                          {this.state.dashboardData.totalRegisteredUsers}
                        </strong>
                      </span>
                    </h2>
                  </div>
                </div>
              </section>
              <section className="col-lg-4">
                <div className="box d-flex justify-content-center align-content-stretch">
                  <div className="box-inner d-flex align-items-center align-self-stretch">
                    <h2>
                      Total Subscribers{' '}
                      <span className="d-block text-center">
                        {' '}
                        <strong className="d-block">
                          {this.state.dashboardData.totalSubscribers}
                        </strong>
                      </span>
                    </h2>
                  </div>
                </div>
              </section>
              <section className="col-lg-6">
                <div className="box d-flex justify-content-center align-content-stretch">
                  <div className="box-inner d-flex align-items-center align-self-stretch">
                    <h2>
                      Total Sale{' '}
                      <span className="d-block text-center">
                        {' '}
                        <strong className="d-block">
                          {this.state.sales.totalSales}
                        </strong>
                      </span>
                    </h2>
                  </div>
                </div>
                <div className="box d-flex justify-content-center align-content-stretch">
                  <div className="box-inner d-flex align-items-center align-self-stretch">
                    <h2>
                      Total Sale Amount{' '}
                      <span className="d-block text-center">
                        {' '}
                        <strong className="d-block">
                          Rs.{' '}
                          {this.state.sales.totalSaleAmount.total_revenue ===
                          null
                            ? '0'
                            : this.state.sales.totalSaleAmount.total_revenue}
                        </strong>
                      </span>
                    </h2>
                  </div>
                </div>
              </section>
              <section className="col-lg-6">
                <div className="box d-flex justify-content-center align-content-stretch">
                  <div className="box-inner d-flex align-items-center align-self-stretch">
                    <h2>
                      Monthly Revenue{' '}
                      <span className="d-block text-center">
                        {' '}
                        <strong className="d-block">
                          Rs.{' '}
                          {this.state.sales.monthlySaleAmount.total_revenue ===
                          null
                            ? '0'
                            : this.state.sales.monthlySaleAmount.total_revenue}
                        </strong>
                      </span>
                    </h2>
                  </div>
                </div>
                <div className="box d-flex justify-content-center align-content-stretch">
                  <div className="box-inner d-flex align-items-center align-self-stretch">
                    <h2>
                      Weekly Revenue{' '}
                      <span className="d-block text-center">
                        {' '}
                        <strong className="d-block">
                          Rs.{' '}
                          {this.state.sales.weeklySaleAmount.total_revenue ===
                          null
                            ? '0'
                            : this.state.sales.weeklySaleAmount.total_revenue}
                        </strong>
                      </span>
                    </h2>
                  </div>
                </div>
              </section>
              {/* <section className="col-lg-8">
                  <div className="chart">
                    <aside className="req-para"><p>Request Status</p></aside>
                    {this.state.chartShow ? <Chart options={this.state.options} series={this.state.series} type="bar" height={350} /> : ''}
                    <aside className="no-req-para"><p>No of Requests</p></aside>
                  </div>
                </section> */}
            </section>
          </section>
        )}

        {this.state.events.length > 0 && (
          <section className="events-blk">
            <section className="row">
              <section className="col-lg-4">
                <h2>Events</h2>
                <section className="datepicker-blk">
                  <DatePickerFunComp date={this.state.events} />
                </section>
              </section>
              <section className="col-lg-8">
                <h2>Upcoming Events</h2>
                <aside className="events-list">
                  <ul>
                    {this.state.events.map(
                      (item) =>
                        moment(item.event_date_time) > new Date() && (
                          <li>
                            <aside className="lft">
                              <h4>
                                <strong>
                                  {moment(item.event_date_time).format('MMM')}
                                </strong>{' '}
                                <span>
                                  {moment(item.event_date_time).format('DD')}
                                </span>
                              </h4>
                            </aside>
                            <aside className="rgt">
                              <p>{item.description}</p>
                            </aside>
                          </li>
                        )
                    )}
                  </ul>
                </aside>
              </section>
            </section>
          </section>
        )}
        </>
        }
      </section>
    );
  }
}
export default Dashboard;
