import React, { Component, useState } from "react";
import * as API from "../../configuration/apiconfig";
import moment from "moment";
import Select from "react-select";
import Swal from "sweetalert2";
import { FiPlusCircle } from "react-icons/fi";
import { ImCross } from "react-icons/im";
import DatePicker from "react-datepicker";
import PreviewIntradayData from "./PreviewIntradayData";
import { FaChevronLeft } from "react-icons/fa";
import Form from 'react-bootstrap/Form';


const optionsValue = [
  { value: "Long call", label: "Long call" },
  { value: "Short Call", label: "Short Call" },
  { value: "Long Put", label: "Long Put" },
  { value: "Short Put", label: "Short Put" },
];

const legsValue = [
  { value: "BUY", label: "BUY" },
  { value: "SELL", label: "SELL" },
];

const legsOption = [
  { value: "CE", label: "CE" },
  { value: "PE", label: "PE" },
  { value: "FUTURES", label: "FUTURES" },
];
const stopLossTypeSelect = [
  { value: "Value", label: "Value" },
  { value: "Price", label: "Price" },
];

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

function DatePickerFunComp(props) {
  const [startDate, setStartDate] = useState(props.getStartDate);
  const ExampleCustomInput = React.forwardRef(({ value, onClick }, ref) => {
    return (
      <section className="custom-datepicker">
        <button className="example-custom-input" onClick={onClick} ref={ref}>
          Schedule
        </button>
        {props.optionSchedule === "" ? null : <strong>{value}</strong>}
      </section>
    );
  });

  const getStartDate = () => {
    let startDate = new Date();
    if (new Date().getHours() === 15 && new Date().getMinutes() > 30) {
      startDate.setDate(startDate.getDate() + 1);
    } else if (new Date().getHours() > 15) {
      startDate.setDate(startDate.getDate() + 1);
    }
    return startDate;
  };

  return (
    <DatePicker
      selected={startDate}
      minDate={getStartDate()}
      onChange={(date) => {
        setStartDate(date);
        props.optionToggleClass("optionSchedule", new Date(date));
      }}
      timeInputLabel="Time:"
      dateFormat="MM/dd/yyyy  h:mm aa"
      showTimeInput
      disabledKeyboardNavigation
      placeholderText="This has disabled keyboard navigation"
      customInput={<ExampleCustomInput />}
    />
  );
}
const getStartDate = () => {
  let startDate = new Date();
  if (new Date().getHours() === 15 && new Date().getMinutes() > 30) {
    startDate.setDate(startDate.getDate() + 1);
  } else if (new Date().getHours() > 15) {
    startDate.setDate(startDate.getDate() + 1);
  }
  return startDate;
};

const DateCustomInput = ({ onClick, value }) => {
  return (
    <React.Fragment>
      <div className="form-group form-group1  ">
        <input
          value={value === "" ? "" : moment(value).format("Do MMM'YY")}
          className="legsItemWidth1 legs-series"
          onClick={onClick}
          placeholder="Select Date"
        ></input>
      </div>
    </React.Fragment>
  );
};

class ModifyIntradayCall extends Component {
  constructor() {
    super();
    this.state = {
      getsigleCall: [],
      callStatus: "",
      call_type: "",
      strategy: "",
      optionStrategyGray: false,
      scripname: "",
      getleg: [],
      capital_required: "",
      max_profit: "",
      lot_size: "",
      // expected_profit: '',
      stop_loss: "",
      max_loss: "",
      callId: "",
      optionData: [],
      tradelogic: "",
      tradeLogicNotes: "",
      tradeLogicUrl: "",
      payoffurl: false,
      getpayoffchartUrl: "",
      optionsSelect: "",
      planid: 1,
      intradayVersion: "",
      legsArray: [
        {
          id: "",
          action: "",
          legOption: "",
          strike_price: "",
          entry_price_start: "",
          entry_price_end: "",
          quantity: "",
          series: "",
          exitToggle: false,
          exit_price: "",
        },
      ],
      stoploss_modified: false,
      target_modified: false,
      notify: true,

    };
  }

  componentDidMount = async () => {
    await this.fetchCall();
  };

  fetchCall = async () => {
    let path = this.props.location.search;
    let id = parseInt(path.slice(path.indexOf("id=") + 3));

    return await API.callEndpoint("GET", "Bearer", `/api/intraday?id=${id}`)
      .then((response) => {
        console.log("response?.data", response?.data?.data[0]?.intraday_version);
        this.setState({
          getsigleCall: response?.data?.data,
        });
        this.setState({
          planid: response?.data?.data[0]?.planid,
          callStatus: response?.data?.data[0]?.status?.toLowerCase(),
          call_type: response?.data?.data[0]?.call_type,
          strategy: response?.data?.data[0]?.strategy,
          scripname: response?.data?.data[0]?.scripname,
          getleg: response?.data?.data[0]?.info?.legs,
          capital_required: response?.data?.data[0]?.capital_required,
          max_profit: response?.data?.data[0]?.info?.max_profit,
          lot_size: response?.data?.data[0]?.info?.lot_size,
          // stop_loss_type: response?.data?.data[0]?.info?.stop_loss_type,
          // expected_profit: response?.data?.data[0]?.info?.expected_profit,
          stop_loss: response?.data?.data[0]?.info?.stop_loss,
          max_loss: response?.data?.data[0]?.info?.max_loss,
          callId: response?.data?.data[0]?.id,
          intradayVersion: response?.data?.data[0]?.intraday_version,
          target_price: response?.data?.data[0]?.info?.target_price,
          tradelogic: response?.data?.data[0]?.tradelogic_option,
          tradeLogicUrl: response?.data?.data[0]?.tradelogic_img,
          tradeLogicNotes: response?.data?.data[0]?.tradelogic_note,
          getpayoffchartUrl: response?.data?.data[0]?.payoffchart_url,
          optionsSelect: response?.data?.data[0]?.strategy,
          target_modified: response?.data?.data[0]?.info?.target_modified,
          stoploss_modified: response?.data?.data[0]?.info?.stoploss_modified,
          legsArray: response?.data?.data[0]?.info?.legs.map((item, index) => {
            // console.log(moment(item?.series, "Do MMM'YY")
            // .local()
            // .format("Do MMM'YY"))
            return {
              id: item?.id,
              action: item?.action,
              legOption: item?.legOption,
              strike_price: item?.strike_price,
              entry_price: item?.entry_price,
              entry_price_start: item?.entry_price_start,
              entry_price_end: item?.entry_price_end,
              quantity: item?.quantity,
              series: moment(item?.series, "Do MMM'YY").local().toDate(),
              exitToggle: false,
            };
          }),
        });

        return response.data[0];
      })
      .catch((error) => {
        console.log(error);
      });
  };

  updateCallStatus = (value) => {
    this.setState({ callStatus: value });
  };

  optionsTypeofCall = (txt) => {
    this.setState({ call_type: txt });
  };

  optionsSelectType = (e) => {
    this.setState({
      strategy: e.value,
    });
  };
  optionsDeselect = () => {
    this.setState({
      strategy: "",
      optionStrategyGray: false,
      optionStrategySelect: true,
    });
  };

  optionsSelectOnChange = (optionsSelect) => {
    let url = "";
    let call_type = this.state.call_type;
    let optionBull = false;
    let optionBear = false;

    // optionsSelect

    switch (optionsSelect?.value) {
      case "Long call":
        call_type = "Bullish";
        // optionBull = true;
        // optionBear = false;
        url =
          "https://hedged-prod-assets-01.s3.ap-south-1.amazonaws.com/payoffchart-images/Long_Call.jpg";
        break;
      case "Long Put":
        call_type = "Bearish";

        // optionBull = false;
        // optionBear = true;
        url =
          "https://hedged-prod-assets-01.s3.ap-south-1.amazonaws.com/payoffchart-images/Long_Put.jpg";

        break;
      case "Bear Call":
        call_type = "Bearish";
        // optionBull = false;
        // optionBear = true;
        url =
          "https://hedged-prod-assets-01.s3.ap-south-1.amazonaws.com/payoffchart-images/Bear_Call.jpg";

        break;
      case "Bull Put":
        call_type = "Bullish";
        // optionBull = true;
        // optionBear = false;
        url =
          "https://hedged-prod-assets-01.s3.ap-south-1.amazonaws.com/payoffchart-images/Bull_Put.jpg";

        break;

      default:
        break;
    }
    this.setState({
      optionsBulish: optionBull,
      optionsBearish: optionBear,
      call_type: call_type,
    });
    if (optionsSelect.value === "Long Put") {
      this.setState({
        legsArray: [
          {
            action: "BUY",
            legOption: "PE",
            strike_price: "",
            entry_price_start: "",
            entry_price_end: "",
            quantity: 1,
            series: "",
          },
        ],
      });
    } else if (optionsSelect.value === "Long call") {
      this.setState({
        legsArray: [
          {
            action: "BUY",
            legOption: "CE",
            strike_price: "",
            entry_price_start: "",
            entry_price_end: "",
            quantity: 1,
            series: "",
          },
        ],
      });
    } else if (optionsSelect.value === "Bull Put") {
      this.setState({
        legsArray: [
          {
            action: "SELL",
            legOption: "PE",
            strike_price: "",
            entry_price_start: "",
            entry_price_end: "",
            quantity: 1,
            series: "",
          },
          {
            action: "BUY",
            legOption: "PE",
            strike_price: "",
            entry_price_start: "",
            entry_price_end: "",
            quantity: 1,
            series: "",
          },
        ],
      });
    } else if (optionsSelect.value === "Bear Call") {
      this.setState({
        legsArray: [
          {
            action: "SELL",
            legOption: "CE",
            strike_price: "",
            entry_price_start: "",
            entry_price_end: "",
            quantity: 1,
            series: "",
          },
          {
            action: "BUY",
            legOption: "CE",
            strike_price: "",
            entry_price_start: "",
            entry_price_end: "",
            quantity: 1,
            series: "",
          },
        ],
      });
    }
    // else if (optionsSelect.value === "Short Call") {
    //   this.setState({
    //     legsArray: [
    //       {
    //         action: "BUY",
    //         legOption: "CE",
    //         strike_price: "",
    //         entry_price_start: "",
    //         entry_price_end: "",
    //         quantity: 1,
    //         series: "",
    //       },
    //     ],
    //   });
    // } else if (optionsSelect.value === "Short Put") {
    //   this.setState({
    //     legsArray: [
    //       {
    //         action: "BUY",
    //         legOption: "PE",
    //         strike_price: "",
    //         entry_price_start: "",
    //         entry_price_end: "",
    //         quantity: 1,
    //         series: "",
    //       },
    //     ],
    //   });
    // }

    this.setState({
      getpayoffchartUrl: url,
      payoffurl: true,
      optionsSelect: optionsSelect.value,
      // strategy: true,  payoffchartUrl
      // optionsBulish: true,
      optionStrategySelect: false,
      optionStrategyGray: true,
      whichScrip1Input: true,

      // legsArray: [
      //   {
      //     action: 'BUY',
      //     legOption: 'CE',
      //     strike_price: '',
      //     entry_price_start: '',
      //     entry_price_end: '',
      //     quantity: 1,
      //     series: '',
      //   },
      // ],
    });
  };

  capitalChange = (e) => {
    this.setState({
      capital_required: e.target.value,
    });
  };

  maxProfitChange = (e) => {
    this.setState({
      max_profit: e.target.value,
    });
  };

  maxLossOnChange = (e) => {
    this.setState({
      max_loss: e.target.value,
    });
  };

  stopLossOnChange = (e) => {
    this.setState({
      stop_loss: e.target.value,
    });
  };

  selectTarget = (e) => {
    this.setState({
      target_price: e.target.value,
    });
  };

  // expectedProfitOnChange = (e) => {
  //   this.setState({
  //     expected_profit: e.target.value,
  //   });
  // };

  stoplossTypeOnChange = (e) => {
    this.setState({
      stop_loss_type: e.value,
    });
  };

  lotSizeOnChange = (e) => {
    this.setState({
      lot_size: e.target.value,
    });
    if (
      this.state?.optionsSelect == "Long call" ||
      this.state?.optionsSelect == "Long Put"
    ) {
      let cap_req = 0;
      cap_req =
        e.target.value *
        this.state?.legsArray[0]?.quantity *
        this.state?.legsArray[0]?.entry_price;
      this.setState({
        capital_required: cap_req,
      });
    }
  };

  updateScripname = (value) => {
    let lot_size = 0;
    let cap_req = 0;

    switch (value) {
      case "nifty":
        lot_size = 50;
        break;
      case "banknifty":
        lot_size = 15;
        break;
      case "Finnifty":
        lot_size = 40;
        break;

      default:
        break;
    }

    switch (this.state.optionsSelect) {
      case "Long call":
      case "Long Put":
        if (
          lot_size &&
          this.state?.legsArray[0]?.quantity &&
          this.state?.legsArray[0]?.entry_price
        ) {
          cap_req =
            lot_size *
            this.state?.legsArray[0]?.quantity *
            this.state?.legsArray[0]?.entry_price;
        }

        // Lot Size * Quantity * Entry Price
        break;

      case "Bear Call":
      case "Bull Put":
        cap_req =
          value == "nifty"
            ? 32000
            : value == "banknifty"
              ? 35000
              : value == "Finnifty"
                ? 28000
                : 0;
        break;

      default:
        break;
    }
    this.setState({
      scripname: value,
      lot_size: lot_size,
      capital_required: cap_req,
    });
  };

  optionsTradeLogic = (value) => {
    let obj = {
      tradeLogicUrl: "",
      tradeLogicNotes: "",
      tradelogic: "",
    };
    if (value === 1) {
      obj = {
        tradeLogicUrl:
          "https://hedged-prod-assets-01.s3.ap-south-1.amazonaws.com/images/uploads/e93031489feb3446161ce98a0e165bf4b2543fd890d965923d557f56cbd50c29.jpeg",
        tradeLogicNotes: "learn react js",
        tradelogic: value,
      };
    } else if (value === 2) {
      obj = {
        tradeLogicUrl:
          "https://hedged-prod-assets-01.s3.ap-south-1.amazonaws.com/images/uploads/e93031489feb3446161ce98a0e165bf4b2543fd890d965923d557f56cbd50c29.jpeg",
        tradeLogicNotes: "learn react next js",
        tradelogic: value,
      };
    } else if (value === 3) {
      obj = {
        tradeLogicUrl:
          "https://hedged-prod-assets-01.s3.ap-south-1.amazonaws.com/images/uploads/e93031489feb3446161ce98a0e165bf4b2543fd890d965923d557f56cbd50c29.jpeg",
        tradeLogicNotes: "learn html js",
        tradelogic: value,
      };
    } else if (value === 4) {
      obj = {
        tradeLogicUrl:
          "https://hedged-prod-assets-01.s3.ap-south-1.amazonaws.com/images/uploads/e93031489feb3446161ce98a0e165bf4b2543fd890d965923d557f56cbd50c29.jpeg",
        tradeLogicNotes: "learn cssss js",
        tradelogic: value,
      };
    }
    this.setState(obj);
  };

  legsSelectOnchange = (index, getParameter) => (legsSelectValue) => {
    this.state.legsArray[index][getParameter] = legsSelectValue.value;
    const getValue = legsSelectValue.value;

    this.setState(
      {
        legsArray: this.state.legsArray,
        getValue,
      },
      () => {
        console.log(JSON.stringify(this.state.legsArray));
      }
    );
  };
  legFieldsOnchange = (e, index) => {
    const { name, value } = e.target;
    this.state.legsArray[index][name] = value
      .split("")
      .filter((item) => item.match(/[0-9\\.]/i))
      .join("");

    this.setState({
      legsArray: this.state.legsArray,
      value,
    });
    if (
      (name == "entry_price" || name == "quantity") &&
      (this.state?.optionsSelect == "Long call" ||
        this.state?.optionsSelect == "Long Put")
    ) {
      let cap_req = 0;
      cap_req =
        this.state?.lot_size *
        this.state?.legsArray[0]?.quantity *
        this.state?.legsArray[0]?.entry_price;
      this.setState({
        capital_required: cap_req,
      });
    }
  };
  legsSelectOptionOnchange1 = (index, getParameter) => (legsSelectValue) => {
    this.state.legsArray[index][getParameter] = legsSelectValue;
    const getValue = legsSelectValue;
    this.setState({
      legsArray: this.state.legsArray,
      getValue,
    });
  };

  legsSelectOptionOnchange = (index, getParameter) => (legsSelectValue) => {
    this.state.legsArray[index][getParameter] = legsSelectValue.value;

    const getValue = legsSelectValue.value;
    this.setState({
      legsArray: this.state.legsArray,
      getValue,
    });
  };

  optionAddBtn = (e) => {
    this.setState({
      legsArray: [
        ...this.state.legsArray,
        {
          action: "",
          strike_price: "",
          entry_price_start: "",
          entry_price_end: "",
          quantity: "",
          series: "",
        },
      ],
    });
  };

  legDeleteClick = (index) => {
    const getArray = [...this.state.legsArray];
    getArray.splice(index, 1);
    this.setState({
      legsArray: getArray,
    });
  };

  saveProceedBtn = async () => {
    let legs = [];
    for (let index = 0; index < this.state.legsArray?.length; index++) {
      legs.push({
        ...this.state.legsArray[index],
        series: moment(this.state.legsArray[index]?.series).format("Do MMM'YY"),
      });
    }
    let data = {
      id: this.state.callId,
      scripname: this.state.scripname,
      call_type: this.state.call_type,
      strategy: this.state.strategy,
      scripname_price: 3000,
      intraday_version: this?.state?.intradayVersion,
      info: {
        legs: legs,
        stop_loss: this.state.stop_loss,
        max_loss: this.state.max_loss,
        lot_size: this.state.lot_size,
        max_profit: this.state.max_profit,
        // stop_loss_type: this.state.stop_loss_type,
        // expected_profit: this.state.expected_profit,
        target_price: this.state.target_price,
        entry_price: this?.state?.entry_price,
        stoploss_modified: this.state?.stoploss_modified,
        target_modified: this.state?.target_modified,
        status: this.state.callStatus,
      },
      capital_required: this.state.capital_required,
      status: this.state.callStatus,
      tradelogic_note: this.state.tradeLogicNotes,
      tradelogic_img: this.state.tradeLogicUrl,
      current_price: 500,
      payoffchart_url: this.state.getpayoffchartUrl,
      tradelogic_option: this.state.tradelogic,
      is_notify:this.state.notify,

    };

    this.setState({
      parCapitalRequied: this.state.capital_required,
      parStopLoss: this.state.stop_loss,
      parLotSize: this.state.lot_size,
      optionData: data,
      showPreview: true,
      backBtn: true,
    });
  };

  modifyData = async () => {
    // console.log("state", this.state?.optionData);
    await API.callEndpoint(
      "PATCH",
      "Bearer",
      "/api/intraday",
      this.state?.optionData
    )
      .then((response) => {
        // if (response?.data?.status == 200) {
        this.props.history.push("/intradaycall");
        // }
      })
      .catch((error) => {
        console.log(error.error);
      });
  };

  updateNotify = (value) => {
    this.setState({ notify: value })
  }

  render() {
    return (
      <>
        <section className="newcall-parent">
          <h1
            className={`head text-uppercase m-0 ${this.state.closeCall ? "d-none" : "d-block"
              } ${this.state.callDetails ? "d-none" : ""}`}
          >
            {this.state.backBtn ? (
              <>
                <FaChevronLeft
                  className="back-btn"
                  onClick={() => {
                    this.setState({
                      showPreview: false,
                      backBtn: false,
                    });
                  }}
                />
                &nbsp;
              </>
            ) : null}
            <>Modified Call </>
          </h1>
          {this.state?.showPreview ? (
            <PreviewIntradayData state={this.state} />
          ) : (
            <section className="row">
              <section className="col-lg-4 leftComponentWidth">
                <section className="list-blk d-block">
                  <h2>What is the Call Status?</h2>
                  <ul className="list-group list-group-horizontal">
                    <li
                      className={`list-group-item border-0 px-2 py-0 ${this.state.callStatus === "open" ? "active" : ""
                        }`}
                    >
                      <span
                        onClick={() => this.updateCallStatus("open")}
                        className="d-block text-center"
                      >
                        Open
                      </span>
                    </li>

                    <li
                      className={
                        "list-group-item border-0 px-2 py-0 " +
                        (this.state.callStatus === "pending" ? "active" : "")
                      }
                    >
                      <span
                        onClick={() => this.updateCallStatus("pending")}
                        className="d-block text-center"
                      >
                        Pending
                      </span>
                    </li>
                  </ul>
                </section>
                <section className="list-blk d-block ">
                  <h2>Strategy</h2>
                  <ul
                    className="list-group list-group-horizontal"
                    style={{ display: "flex", flexWrap: "wrap", gap: "1rem" }}
                  >
                    <li
                      className={`list-group-item border-0 px-2 py-0 ${this.state.planid == 1 ? "active" : ""
                        }`}
                    >
                      <span className="d-block text-center">Free</span>
                    </li>
                    <li
                      className={`list-group-item border-0 px-2 py-0 ${this.state.planid == 3 ? "active" : ""
                        }`}
                    >
                      <span className="d-block text-center">Crown</span>
                    </li>
                  </ul>
                </section>

                <section className="list-blk d-block ">
                  <h2>Strategy</h2>
                  <ul
                    className="list-group list-group-horizontal"
                    style={{ display: "flex", flexWrap: "wrap", gap: "1rem" }}
                  >
                    <li
                      className={`list-group-item border-0 px-2 py-0 ${this.state.optionsSelect == "Long call" ? "active" : ""
                        }`}
                    >
                      <span
                        onClick={() =>
                          this.optionsSelectOnChange({
                            value: "Long call",
                          })
                        }
                        className="d-block text-center"
                      >
                        Long Call
                      </span>
                    </li>
                    <li
                      className={`list-group-item border-0 px-2 py-0 ${this.state.optionsSelect == "Long Put" ? "active" : ""
                        }`}
                    >
                      <span
                        onClick={() =>
                          this.optionsSelectOnChange({
                            value: "Long Put",
                          })
                        }
                        className="d-block text-center"
                      >
                        Long Put
                      </span>
                    </li>
                    <li
                      className={`list-group-item border-0 px-2 py-0 ${this.state.optionsSelect == "Bear Call" ? "active" : ""
                        }`}
                    >
                      <span
                        onClick={() =>
                          this.optionsSelectOnChange({
                            value: "Bear Call",
                          })
                        }
                        className="d-block text-center"
                      >
                        Bear Call
                      </span>
                    </li>

                    <li
                      className={`list-group-item border-0 px-2 py-0 ${this.state.optionsSelect == "Bull Put" ? "active" : ""
                        }`}
                    >
                      <span
                        onClick={() =>
                          this.optionsSelectOnChange({
                            value: "Bull Put",
                          })
                        }
                        className="d-block text-center"
                      >
                        Bull Put
                      </span>
                    </li>
                  </ul>
                </section>
                <section className="list-blk d-block ">
                  <h2>What type of Call?</h2>
                  <ul
                    className="list-group list-group-horizontal"
                    style={{ display: "flex", flexWrap: "wrap", gap: "1rem" }}
                  >
                    <li
                      className={`list-group-item border-0 px-2 py-0 ${this.state.call_type === "Bullish" ? "active" : ""
                        }`}
                    >
                      <span
                        onClick={() => this.optionsTypeofCall("Bullish")}
                        className="d-block text-center"
                      >
                        Bullish
                      </span>
                    </li>
                    <li
                      className={`list-group-item border-0 px-2 py-0 ${this.state.call_type === "Bearish" ? "active" : ""
                        }`}
                    >
                      <span
                        onClick={() => this.optionsTypeofCall("Bearish")}
                        className="d-block text-center"
                      >
                        Bearish
                      </span>
                    </li>
                  </ul>
                </section>

                <section className="list-blk d-block">
                  <h2>Payoff Chart : </h2>
                  <img
                    src={this.state.getpayoffchartUrl}
                    style={{
                      width: "100%",
                    }}
                  />
                </section>

                <section className="list-blk d-block">
                  <h2>Which scrip?</h2>
                  <ul className="list-group list-group-horizontal">
                    <li
                      className={`list-group-item border-0 px-2 py-0 ${this.state.scripname === "nifty" ? "active" : ""
                        }`}
                    >
                      <span
                        onClick={() => {
                          this.setState({
                            autoCompleteEquity: "",
                            textDropDownToggle: true,
                          });
                          this.updateScripname("nifty");
                        }}
                        className="d-block text-center"
                      >
                        Nifty
                      </span>
                    </li>

                    <li
                      className={
                        "list-group-item border-0 px-2 py-0 " +
                        (this.state.scripname === "banknifty" ? "active" : "")
                      }
                    >
                      <span
                        onClick={() => {
                          this.setState({
                            autoCompleteEquity: "",
                            textDropDownToggle: true,
                          });

                          this.updateScripname("banknifty");
                        }}
                        className="d-block text-center"
                      >
                        BankNifty
                      </span>
                    </li>

                    <li
                      className={
                        "list-group-item border-0 px-2 py-0 " +
                        (this.state.scripname === "Finnifty" ? "active" : "")
                      }
                    >
                      <span
                        onClick={() => {
                          this.setState({
                            autoCompleteEquity: "",
                            textDropDownToggle: true,
                          });

                          this.updateScripname("Finnifty");
                        }}
                        className="d-block text-center"
                      >
                        Finnifty
                      </span>
                    </li>
                  </ul>
                </section>
              </section>
              <section className="col-lg-6 rightComponentWidth">
                <section className="option-rgt-blk">
                  <section className="add-legs-blk">
                    <p>Legs</p>
                    <aside
                      className="plus-btn"
                      onClick={(e) => this.optionAddBtn(e)}
                    >
                      <span>&nbsp;</span>
                    </aside>
                  </section>
                  <section className="leg-blk">
                    {this.state.legsArray.map((legs, index) => {
                      // index = this.props.state.legsArray.length - (index + 1);
                      return (
                        <section
                          className={`form-blk ${legs.exitToggle ? `gray-leg-bg ` : ``
                            }`}
                          key={index}
                        >
                          <h2
                            className={` ${legs.exitToggle ? `head-gray-bg` : ``
                              }`}
                          >
                            Leg {index + 1}
                          </h2>
                          <div
                            className={`form-group ps-0 colmb ${legs.exitToggle ? `call-leg-grey` : ``
                              }`}
                          >
                            <div
                              className={`call-field-header ${legs.exitToggle ? `head-gray-bg` : ``
                                }`}
                            >
                              Action
                            </div>
                            <Select
                              className="legsItemWidth"
                              onChange={this.legsSelectOnchange(
                                index,
                                "action"
                              )}
                              options={legsValue}
                              placeholder="Select"
                              value={legsValue.filter(
                                (item) => item.value === legs.action
                              )}
                            />
                          </div>

                          <div className="form-group ps-0 colmb">
                            <div
                              className={`call-field-header ${legs.exitToggle ? `head-gray-bg` : ``
                                } ${legs.legOption === "FUTURES" && `head-gray-bg`
                                }`}
                            >
                              Strike Price
                            </div>
                            <input
                              disabled={
                                legs.exitToggle || legs.legOption === "FUTURES"
                              }
                              type="text"
                              className={`form-control legsItemWidth ${legs.exitToggle
                                ? `legs-option-call-grey`
                                : `legs-option-call-black`
                                }`}
                              name="strike_price"
                              onChange={(e) => this.legFieldsOnchange(e, index)}
                              placeholder="Strike Price"
                              autoComplete="off"
                              value={legs.strike_price}
                            />
                          </div>
                          <div
                            className={`form-group ps-0 colmb ${legs.exitToggle ? `call-leg-grey` : ``
                              }`}
                          >
                            <div
                              className={`call-field-header ${legs.exitToggle ? `head-gray-bg` : ``
                                }`}
                            >
                              Option
                            </div>
                            <Select
                              isDisabled={legs.exitToggle}
                              className="legsItemWidth"
                              onChange={this.legsSelectOptionOnchange(
                                index,
                                "legOption"
                              )}
                              options={legsOption}
                              placeholder="Select"
                              value={legsOption.filter(
                                (item) => item.value === legs.legOption
                              )}
                            />
                          </div>
                          <div
                            className={`form-group form-group1  ps-0 colmb ${legs.exitToggle
                              ? `legs-option-call-grey`
                              : `legs-option-call-black`
                              }`}
                          >
                            <div
                              className={`call-field-header ${legs.exitToggle ? `head-gray-bg ` : ``
                                }`}
                            >
                              Series
                            </div>

                            <DatePicker
                              //   readOnly={legs.exitToggle}
                              className="legsItemWidth1"
                              selected={legs.series}
                              showMonthDropdown
                              showYearDropdown
                              onChange={this.legsSelectOptionOnchange1(
                                index,
                                "series"
                              )}
                              customInput={
                                <DateCustomInput toggle={legs.exitToggle} />
                              }
                            />
                          </div>
                          {/* {this.state?.strategy == "Long call" ||
                        this.state?.strategy == "Long Put" ? ( */}
                          <div className="form-group colmb">
                            <div
                              className={`call-field-header ${legs.exitToggle ? `head-gray-bg` : ``
                                }`}
                            >
                              Entry Price
                            </div>
                            <input
                              disabled={legs.exitToggle}
                              type="text"
                              className={`form-control legsItemWidth ${legs.exitToggle
                                ? `legs-option-call-grey`
                                : `legs-option-call-black`
                                }`}
                              name="entry_price"
                              onChange={(e) => this.legFieldsOnchange(e, index)}
                              placeholder="Entry Price"
                              autoComplete="off"
                              value={legs.entry_price}
                            />
                          </div>
                          {/* ) : null} */}
                          {/* <div className="form-group  ps-0 colmb">
                          <div
                            className={`call-field-header ${
                              legs.exitToggle ? `head-gray-bg` : ``
                            }`}
                          >
                            Min Price
                          </div>
                          <input
                            disabled={legs.exitToggle}
                            type="text"
                            className={`form-control legsItemWidth ${
                              legs.exitToggle
                                ? `legs-option-call-grey`
                                : `legs-option-call-black`
                            }`}
                            name="entry_price_start"
                            onChange={(e) => this.legFieldsOnchange(e, index)}
                            placeholder="Min Price"
                            autoComplete="off"
                            value={legs.entry_price_start}
                          />
                        </div> */}
                          {/* <div className="form-group  ps-0 colmb">
                          <div
                            className={`call-field-header ${
                              legs.exitToggle ? `head-gray-bg` : ``
                            }`}
                          >
                            Max Price
                          </div>
                          <input
                            disabled={legs.exitToggle}
                            type="text"
                            className={`form-control legsItemWidth ${
                              legs.exitToggle
                                ? `legs-option-call-grey`
                                : `legs-option-call-black`
                            }`}
                            name="entry_price_end"
                            onChange={(e) => this.legFieldsOnchange(e, index)}
                            placeholder="Max Price"
                            autoComplete="off"
                            value={legs.entry_price_end}
                          />
                        </div> */}
                          <div className="form-group  ps-0 colmb">
                            <div
                              className={`call-field-header ${legs.exitToggle ? `head-gray-bg` : ``
                                }`}
                            >
                              Quantity
                            </div>
                            <input
                              disabled={legs.exitToggle}
                              type="text"
                              className={`form-control legsItemWidth ${legs.exitToggle
                                ? `legs-option-call-grey`
                                : `legs-option-call-black`
                                }`}
                              name="quantity"
                              onChange={(e) => this.legFieldsOnchange(e, index)}
                              placeholder="Quantity"
                              autoComplete="off"
                              value={legs.quantity}
                            />
                          </div>
                          <div className="form-group w-6 colmb">
                            <div
                              className="close-btn"
                              onClick={() => {
                                if (!legs.exitToggle) {
                                  this.legDeleteClick(index);
                                }
                              }}
                            >
                              <span>&nbsp;</span>
                            </div>
                          </div>
                        </section>
                      );
                    })}
                  </section>

                  <section className="call-participat-blk">
                    <section className="list-blk">
                      <h2>Participation Data</h2>
                      <section className="participt-blk">
                        <div className="form-group">
                          <div className="call-field-header">
                            Capital Required
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            name="capitalChange"
                            onChange={this.capitalChange}
                            placeholder="Capital Requied"
                            value={this.state.capital_required}
                          />
                        </div>
                        {this.state?.optionsSelect == "Bear Call" ||
                          this.state?.optionsSelect == "Bull Put" ? (
                          <div className="form-group">
                            <div className="call-field-header">Max Profit</div>
                            <input
                              type="text"
                              className="form-control"
                              name="parMaxProfit"
                              onChange={this.maxProfitChange}
                              placeholder="Max Profit"
                              value={this.state.max_profit}
                            />
                          </div>
                        ) : null}

                        {this.state?.optionsSelect == "Bear Call" ||
                          this.state?.optionsSelect == "Bull Put" ? (
                          <div className="form-group">
                            <div className="call-field-header">Max Loss</div>
                            <input
                              type="text"
                              className="form-control"
                              name="parMaxLoss"
                              onChange={this.maxLossOnChange}
                              placeholder="Max Loss"
                              value={this.state.max_loss}
                            />
                          </div>
                        ) : null}

                        <div className="form-group">
                          <div className="call-field-header">Stop Loss</div>
                          <input
                            type="text"
                            className="form-control"
                            name="parStopLoss"
                            onChange={this.stopLossOnChange}
                            placeholder="Stop Loss"
                            value={this.state.stop_loss}
                          />
                        </div>

                        {this.state?.optionsSelect == "Long call" ||
                          this.state?.optionsSelect == "Long Put" ? (
                          <div className="form-group">
                            <div className="call-field-header">Target</div>
                            <input
                              type="text"
                              className="form-control"
                              name="target_price"
                              onChange={this.selectTarget}
                              placeholder="Target"
                              value={this.state.target_price}
                            />
                          </div>
                        ) : null}

                        {/* <div className="form-group">
                        <div className="call-field-header">Expected Profit</div>
                        <input
                          type="text"
                          className="form-control"
                          name="parExpectedProfit"
                          onChange={this.expectedProfitOnChange}
                          placeholder="Expected Profit"
                          value={this.state.expected_profit}
                        />
                      </div> */}
                        {/* <div className="form-group">
                        <div className="call-field-header">Stop Loss Type</div>
                        <Select
                          onChange={this.stoplossTypeOnChange}
                          options={stopLossTypeSelect}
                          placeholder="Stop Loss Type"
                          value={stopLossTypeSelect.filter(
                            (item) => item.value === this.state.stop_loss_type
                          )}
                          //menuIsOpen={this.state.menuIsOpen}
                        />
                      </div> */}

                        <div className="form-group">
                          <div className="call-field-header">Lot Size</div>
                          <input
                            type="text"
                            className="form-control"
                            name="parLotSize"
                            onChange={this.lotSizeOnChange}
                            placeholder="Lot Size"
                            value={this.state.lot_size}
                          />
                        </div>
                        {this.state?.strategy?.toLocaleLowerCase() ==
                          "long call" ||
                          this?.state?.strategy?.toLocaleLowerCase() ==
                          "long put" ? (
                          <>
                            {" "}
                            <div className={`form-group exit-width colmb`}>
                              <div className={`call-field-header1 `}>
                                Is Stop Loss Modified
                              </div>
                              <label className="switch">
                                <input
                                  // disabled={legs.legExit}
                                  type="checkbox"
                                  name="stoploss_modified"
                                  onChange={(e) => {
                                    this.setState({
                                      stoploss_modified:
                                        !this.state.stoploss_modified,
                                    });
                                  }}
                                  checked={this.state.stoploss_modified}
                                />
                                <span className="slider"></span>
                              </label>
                            </div>
                            <div className={`form-group exit-width colmb`}>
                              <div className={`call-field-header1 `}>
                                Is Target Modified
                              </div>
                              <label className="switch">
                                <input
                                  // disabled={legs.legExit}
                                  type="checkbox"
                                  name="target_modified"
                                  onChange={(e) => {
                                    this.setState({
                                      target_modified:
                                        !this.state.target_modified,
                                    });
                                  }}
                                  checked={this.state.target_modified}
                                />
                                <span className="slider"></span>
                              </label>
                            </div>
                          </>
                        ) : null}
                      </section>
                    </section>
                    <section className="list-blk">
            <section className="participt-blk">
              {/* <h2>Notify User</h2> */}
              <Form.Check
                onChange={(event) => {
                  this.updateNotify(event.target.checked);
                  // console.log(event.target.checked, 'check value')
                }}
                label="Notify User"
                name="group1"
                type={"checkbox"}
                checked={this.state.notify}
                id={`reverse-checkbox-1`}
                className="notifycheck"
              />

            </section>

          </section>
                  </section>

                  {/* <section className="list-blk d-block ">
                  <h2>Trade Logic </h2>
                  <ul
                    className="list-group list-group-horizontal"
                    style={{ display: 'flex', flexWrap: 'wrap', gap: '1rem' }}
                  >
                    <li
                      className={`list-group-item border-0 px-2 py-0 ${
                        this.state.tradelogic === 1 ? 'active' : ''
                      }`}
                    >
                      <span
                        onClick={() => this.optionsTradeLogic(1)}
                        className="d-block text-center"
                      >
                        Option 1
                      </span>
                    </li>
                    <li
                      className={`list-group-item border-0 px-2 py-0 ${
                        this.state.tradelogic === 2 ? 'active' : ''
                      }`}
                    >
                      <span
                        onClick={() => this.optionsTradeLogic(2)}
                        className="d-block text-center"
                      >
                        Option 2
                      </span>
                    </li>
                    <li
                      className={`list-group-item border-0 px-2 py-0 ${
                        this.state.tradelogic === 3 ? 'active' : ''
                      }`}
                    >
                      <span
                        onClick={() => this.optionsTradeLogic(3)}
                        className="d-block text-center"
                      >
                        Option 3
                      </span>
                    </li>
                    <li
                      className={`list-group-item border-0 px-2 py-0 ${
                        this.state.tradelogic === 4 ? 'active' : ''
                      }`}
                    >
                      <span
                        onClick={() => this.optionsTradeLogic(4)}
                        className="d-block text-center"
                      >
                        Option 4
                      </span>
                    </li>
                  </ul>
                </section> */}
                  {/* <section className="m-4">
                  <h5 style={{ marginTop: 20 }}>Trade Logic URL </h5>
                  <img
                    src={this.state.tradeLogicUrl}
                    style={{
                      width: '100%',
                    }}
                  />
                  <h5 style={{ marginTop: 20 }}>Trade Logic Notes</h5>
                  <p>{this.state.tradeLogicNotes}</p>
                </section> */}
                </section>
              </section>
            </section>
          )}
        </section>

        <section className="btm-strip d-block">
          <aside className="proceed-btn-group">
            {this.state?.showPreview ? (
              <a title="SAVE &amp; PROCEED" onClick={this.modifyData}>
                Modify Call
              </a>
            ) : (
              <a title="SAVE &amp; PROCEED" onClick={this.saveProceedBtn}>
                Save
              </a>
            )}
          </aside>
        </section>
      </>
    );
  }
}

export default ModifyIntradayCall;
