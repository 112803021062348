import React, { Component } from 'react'
import Vidmaincomp from './Vidmaincomp'

class index extends Component {
    render() {
        return (
            <section>
                <Vidmaincomp/>
            </section>
        )
    }
}

export default index
