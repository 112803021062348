import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Vimeo from "@u-wave/react-vimeo";
import moment from "moment";
import Muuri from "muuri";
import ReactTooltip from "react-tooltip";
import { DateTimeFormatISO } from "../dateTimeFormat/DateTimeFormat";
import "font-awesome/css/font-awesome.min.css";
import Card from "@mui/material/Card";
import * as API from "../../configuration/apiconfig";
import swal from "sweetalert";
var gridLayout;

const dateTimeFormat1 = "DD/MM/YYYY";
const dateTimeFormat2 = "DD/MM/YYYY h:mm A";
let DragIndex = 0;

class Draganddrop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listItemsLoaded: [],
    };
  }
  componentDidUpdate() {
    var gridLength = document.querySelectorAll(".video-width");
    if (gridLength.length != 0 && this.props.gridInitiated == false) {
      gridLayout = new Muuri(".video-list", {
        dragEnabled: true,
        layoutDuration: 200,
        sortData: {
          id: function (item, element) {
            return parseInt(element.getAttribute("data-order"));
          },
          index: function (item, element) {
            return parseInt(element.getAttribute("data-index"));
          },
        },
        dragSortPredicate: {
          threshold: 10,
        },
        dragSortHeuristics: {
          sortInterval: 1,
        },
      });
      gridLayout.sort("id");
      gridLayout.refreshItems();
      this.props.setGridInitiated(true);

      gridLayout.on("dragEnd", function (item, event) {
        let output = gridLayout.getItems().map((item, index) => {
          return {
            id: item.getElement().getAttribute("data-id"),
            sortorder: item._id,
          };
        });
        console.log(output);
      });
    }
  }

  sortOrder = async () => {
    let output = gridLayout.getItems().map((item, index) => {
      return {
        id: item.getElement().getAttribute("data-id"),
        sortorder: (index + 1) * 10,
      };
    });
    //  console.log(output);
    console.log(
      "----------------- API update videos sort order -------------------"
    );
    await API.callEndpoint(
      "PUT",
      "Bearer",
      "/api/videos/updatefeatureorder",
      JSON.stringify(output)
    )
      .then((response) => {
        try {
          console.log(response);
          this.props.setShow(false);
        } catch (e) {
          console.log(e);
          swal({
            title: "An error occured, try again!",
            text: e.error,
            icon: "info",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        swal({
          title: "An error occured, try again!",
          text: error.error,
          icon: "info",
        });
      });
  };

  render() {
    return (
      <React.Fragment>
        <Modal
          show={this.props.show}
          onHide={() => {
            this.props.setShow(false);
          }}
          dialogClassName="modal-90w"
          backdrop="static"
          className="modalEventform modal fade"
          id="modalFeatureEventlist"
        >
          <ReactTooltip
            id="descToolTop"
            place="top"
            type="dark"
            effect="float"
            multiline={true}
            html={true}
          />
          <Modal.Header closeButton>
            <h2>
              Featured Videos
              <span className="mhDropndrop">Drag events to reorder</span>
            </h2>
          </Modal.Header>
          <Modal.Body>
            <h4>{this.props.tab}</h4>
            <br />
            <section className="trading-tab-blk">
              <section className="trader-inner">
                <section className="memcontblk">
                  <section className="row m-0 p-0">
                    <ul className="row video-list">
                      {this.props.state.videoListAPIData
                        .sort(function (a, b) {
                          if (a.sortorder < b.sortorder) {
                            return -1;
                          }
                          if (a.sortorder > b.sortorder) {
                            return 1;
                          }
                          return 0;
                        })
                        .filter((item) => item.publish && item.isFeature)
                        .map((videoData, Tradeindex) => {
                          if (
                            videoData.islive === false &&
                            videoData.category === this.props.tab
                          ) {
                            return (
                              <li
                                className="video-width p-0 pe-3 mb-3"
                                data-order={Tradeindex}
                                data-index={Tradeindex}
                                data-id={videoData.id}
                              >
                                <Card className="video-card">
                                  <aside className="vidfile1 position-relative mb-2">
                                    <Vimeo video={videoData.url} />
                                  </aside>
                                  <aside className="vidfileinfo position-relative">
                                    <br />
                                    <h6 className="pe-5">
                                      <b>{videoData.topic}</b>
                                      <span className="d-block video-description">
                                        {videoData.description}
                                      </span>
                                    </h6>
                                    <br />
                                    <p>
                                      <strong>CreatedAt:</strong>
                                      <br />
                                      {videoData.createdAt !== null
                                        ? moment(videoData.createdAt).format(
                                            dateTimeFormat1
                                          ) +
                                          " | " +
                                          DateTimeFormatISO(
                                            videoData.createdAt,
                                            "TimeDefault"
                                          )
                                        : ""}
                                    </p>
                                  </aside>
                                </Card>
                              </li>
                            );
                          }
                        })}
                    </ul>
                  </section>
                </section>
              </section>
            </section>
          </Modal.Body>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              onClick={() => {
                this.props.setShow(false);
              }}
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-primary"
              onClick={() => {
                this.sortOrder();
              }}
            >
              Save changes
            </button>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default Draganddrop;
