import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import DatePicker from "react-datepicker";
import * as API from "../../configuration/apiconfig";
import "react-datepicker/dist/react-datepicker.css";

const options = [
  { value: "", label: "Select" },
  { value: "Open", label: "Open" },
  { value: "Pending", label: "Pending" },
  { value: "Closed", label: "Closed" },
];
const renameFile = (originalFile, newName) => {
  return new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
};
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export class UpdateWebinar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subject: this?.props?.data?.subject,
      status: this?.props?.data?.status,
      uploadStatus: false,
      selectedFile: "",
      meetUrl: this?.props?.data?.meeturl,
      imgUrl: this?.props?.data?.imgurl,
      notes: this?.props?.data?.note,
      imageValues: {
        loaded: false,
        url: "",
        type: "",
        name: "",
        source: "",
      },
    };
  }

  getinput = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  updateWebinar = () => {
    let data = {
      id: this.props.data.id,
      subject: this.state.subject || "",
      status: this.state.status,
      meeturl: this.state.meetUrl,
      imgurl: this.state.imgUrl,
      note: this.state.notes || "",
    };
    API.callEndpoint("PUT", "Bearer", "/api/live-webinar", data)
      .then((response) => {
        try {
          this.setState({
            showSuccess: true,
            showError: false,
          });
          setTimeout(() => {
            this.props.onHide();
           this?.props.getLiveWebinarImg()
          }, 1000);
        } catch (e) {
          this.setState({
            showError: true,
          });
          //  document.getElementById("wrongmess").style.display = "block";
        }
      })
      .catch((ex) => {
        // this.setState({ showLoader: false });
        this.setState({
          showError: true,
        });
        // document.getElementById("wrongmess").style.display = "block";
      });
  };

  onImageChange = async (e) => {
    if (e?.target?.files && e?.target?.files[0]) {
      let img = e?.target?.files[0];
      let type = e?.target?.files[0]?.type;
      var srcss = await getBase64(e.target.files[0]);
      // console.log(srcss, "srcss");
      if (type == "image/jpeg" || type == "image/png" || type == "image/jpg") {
        this.setState({
          imageValues: {
            loaded: true,
            source: e?.target?.files[0],
            url: srcss,
          },
          selectedFile: true,
        });
      } else {
        // setChangeImageError("Please Select Jpeg/Png/Jpg files only");
      }
    }
  };

  uploadFile = async (e) => {
    this.setState({
      uploadStatus: true,
    });
    try {
      let file = this.state.imageValues.source;

      const fileFormat = file.type.split("/")[1];
      const queryObj = {
        fileFormat,
        type: "roadblock",
      };

      await API.callEndpoint(
        "GET",
        "Bearer",
        `/api/files/presigned?fileFormat=${queryObj.fileFormat}&type=${queryObj.type}`
      )
        .then((response) => {
          const { key, url } = response.data.data;
          const fileName = `${key}.${fileFormat}`;
          const renamedFile = renameFile(file, fileName);

          const myHeaders = new Headers({ "Content-Type": "image/*" });
          fetch(url, {
            method: "PUT",
            headers: myHeaders,
            body: renamedFile,
          })
            .then(() => {
              API.callEndpoint(
                "GET",
                "Bearer",
                `/api/files/presigned-url?fileName=${fileName}&type=${queryObj.type}`
              )
                .then((response) => {
                  var raw = {
                    url: response.data?.data.split("?X-")[0],
                  };

                  this.setState({ imgUrl: raw?.url });
                  this.setState({
                    uploadStatus: false,
                  });
                })
                .catch((error) => {});
            })
            .catch((error) => {});
        })
        .catch((error) => {});
    } catch (e) {}
  };

  updateImage = async () => {
    this.uploadFile();
  };

  render() {
    const { props } = this;

    console.log("props", props);
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={`modalblkk`}
      >
        <h2
          style={{ fontSize: "25px", textAlign: "center", fontWeight: "600" }}
        >
          Update Webinar
        </h2>
        <Modal.Body>
          <section className="row m-0 p-0 mt-4 justify-content-center addblk">
            <section className="col-lg-12">
              <section className="select-options-blk">
                <section className="row">
                  <section
                    className="col-lg-6 mb-20"
                    style={{ margin: "10px 0" }}
                  >
                    <section className="forms-blk">
                      <div className="head-1 head-label">Subject</div>
                      <div className="form-group">
                        <input
                          id="subject"
                          type="text"
                          className="form-control"
                          name="subject"
                          placeholder="Enter subject"
                          autoComplete="off"
                          onChange={(e) => this.getinput(e)}
                          // onChange={(e) =>
                          //   this.props.updateState("bundleTitle", e.target.value)
                          // }
                          value={this.state.subject}
                        />
                        {/* <span className="b-editbtn">&nbsp;</span> */}
                      </div>
                    </section>
                  </section>
                  <section
                    className="col-lg-6 mb-20"
                    style={{ margin: "10px 0" }}
                  >
                    <section className="forms-blk">
                      <div className="head-1 head-label">Meet Url</div>
                      <div className="form-group">
                        <input
                          id="meetUrl"
                          type="text"
                          className="form-control"
                          name="meetUrl"
                          placeholder="Enter Google Meet Url"
                          autoComplete="off"
                          onChange={(e) => this.getinput(e)}
                          // onChange={(e) =>
                          //   this.props.updateState("bundleTitle", e.target.value)
                          // }
                          value={this.state.meetUrl}
                        />
                        {/* <span className="b-editbtn">&nbsp;</span> */}
                      </div>
                    </section>
                  </section>
                  <section
                    className="col-lg-6 mb-20"
                    style={{ margin: "10px 0" }}
                  >
                    <section className="forms-blk">
                      <div className="head-1 head-label">Status</div>
                      <div className="form-group">
                        <Select
                          name="status"
                          options={options}
                          onChange={(e) => this.setState({ status: e.value })}
                          classNamePrefix="addsele"
                          value={options?.find(
                            (option) => option.value === this.state.status
                          )}
                        />
                      </div>
                    </section>
                  </section>

                  <section
                    className="col-lg-6 mb-20"
                    style={{ margin: "10px 0" }}
                  >
                    <section className="forms-blk">
                      <div className="head-1 head-label">Notes</div>
                      <div className="form-group">
                        <textarea
                          onChange={(e) => this.getinput(e)}
                          className="form-control"
                          name="notes"
                          id="notes"
                          placeholder="Enter something notes"
                          value={this.state?.notes}
                          autoComplete="off"
                        />
                      </div>
                    </section>
                  </section>
                  {this?.state?.imgUrl ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        position: "relative",
                      }}
                    >
                      <div>
                        <img
                          src={this?.state?.imgUrl}
                          alt="webinar-img"
                          height="120px"
                          width="120px"
                        />
                      </div>
                      <button
                        type="button"
                        style={{
                          position: "absolute",
                          top: "-13px",
                          left: "122px",
                          padding: "0.1rem 0.3rem",
                          fontSize: "1rem",
                          fontWeight: "900",
                          color: "red",
                          border: "1px solid",
                          background: "none",
                          borderRadius: "50%",
                        }}
                        onClick={() => this?.setState({ imgUrl: "" })}
                      >
                        X
                      </button>
                    </div>
                  ) : (
                    <section
                      className="col-lg-6 mb-20"
                      style={{ margin: "10px 0" }}
                    >
                      <section className="forms-blk">
                        <div className="head-1 head-label">Upload Image</div>
                        <div
                          className="form-group"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <input
                            onChange={(e) => this.onImageChange(e)}
                            type="file"
                            accept="image/png, image/gif, image/jpeg"
                          />
                          <button
                            onClick={() => {
                              if (this.state?.uploadStatus == false) {
                                this.updateImage();
                              }
                            }}
                            disabled={this.state?.selectedFile ? false : true}
                            style={{
                              marginLeft: 2,
                            }}
                            className="btn btn-primary"
                          >
                            {this.state?.uploadStatus
                              ? "Uploading..."
                              : "Upload"}
                          </button>
                        </div>
                      </section>
                    </section>
                  )}
                </section>
              </section>
              <section className="row justify-content-center p-0 m-0 my-5">
                <section className="col-3">
                  <button
                    className="btnsave"
                    onClick={this.updateWebinar}
                    disabled={
                      !(
                        this.state.subject &&
                        this.state.meetUrl &&
                        this.state.imgUrl &&
                        this.state.notes &&
                        this.state.status
                      )
                    }
                  >
                    Save
                  </button>
                </section>
              </section>
            </section>
          </section>
        </Modal.Body>
      </Modal>
    );
  }
}

export default UpdateWebinar;
