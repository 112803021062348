import React, { Component } from "react";
import { Button } from "react-bootstrap";
import Select from "react-select";
import * as API from "../../configuration/apiconfig";

export default class createAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      name: "",
      password: "",
      type: "",
      phone: "",
      showSuccess: false,
      showError: false,
      typesOfAdmin: [
        // { value: "CMSUSER", label: "Super Admin" },
        { value: "CMSSALESADMINUSER", label: "Sales Admin" },
        { value: "CMSREASEARCHADMINUSER", label: "Research Admin" },
        { value: "CMSAUDITUSER", label: "Audit Admin" },

      ],
    };
  }

  getinput = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  LoginGet = () => {
    let data = {
      name: this.state.name,
      email: this.state.email,
      password: this.state.password,
      phonenumber: this.state.phone,
      authenticationPlatform: "app",
      type: this.state.type,
    };
    API.callEndpoint("POST", "Bearer", "/api/admin/create-admin", data)
      .then((response) => {
        try {
          this.setState({
            showSuccess: true,
          });
          setTimeout(() => {
            this.props.history.push(`/admin/list`);
          }, 1000);
        } catch (e) {
          this.setState({
            showError: true,
          });
          //  document.getElementById("wrongmess").style.display = "block";
        }
      })
      .catch((ex) => {
        // this.setState({ showLoader: false });
        this.setState({
          showError: true,
        });
        // document.getElementById("wrongmess").style.display = "block";
      });
  };

  render() {
    return (
      <section className="admin-bundleTable-page">
        <h1 className="head text-uppercase m-0">Create Admin</h1>
        <section className="select-options-blk">
          <section className="row">
            <section className="col-lg-6 mb-20">
              <section className="forms-blk">
                <div className="head-1">Email</div>
                <div className="form-group">
                  <input
                    id="email"
                    type="text"
                    className="form-control"
                    name="email"
                    placeholder="Enter email"
                    autoComplete="off"
                    onChange={(e) => this.getinput(e)}
                    // onChange={(e) =>
                    //   this.props.updateState("bundleTitle", e.target.value)
                    // }
                    value={this.state.email}
                  />
                  {/* <span className="b-editbtn">&nbsp;</span> */}
                </div>
              </section>
            </section>
            <section className="col-lg-6 mb-20">
              <section className="forms-blk">
                <div className="head-1">Name</div>
                <div className="form-group">
                  <input
                    id="name"
                    type="text"
                    className="form-control"
                    name="name"
                    placeholder="Enter name"
                    autoComplete="off"
                    onChange={(e) => this.getinput(e)}
                    // onChange={(e) =>
                    //   this.props.updateState("bundleTitle", e.target.value)
                    // }
                    value={this.state.name}
                  />
                  {/* <span className="b-editbtn">&nbsp;</span> */}
                </div>
              </section>
            </section>
            <section className="col-lg-6 mb-20">
              <section className="forms-blk">
                <div className="head-1">Phone Number</div>
                <div className="form-group">
                  <input
                    id="phone"
                    type="number"
                    className="form-control"
                    name="phone"
                    // maxlength = "10"
                    // pattern="/^-?\d+\.?\d*$/"
                    onKeyPress={(e) => {
                      if (e.target.value.length >= 10) e.preventDefault();
                    }}
                    placeholder="Enter Phone number"
                    autoComplete="off"
                    onChange={(e) => this.getinput(e)}
                    // onChange={(e) =>
                    //   this.props.updateState("bundleTitle", e.target.value)
                    // }
                    value={this.state.phone}
                  />
                  {/* <span className="b-editbtn">&nbsp;</span> */}
                </div>
              </section>
            </section>
            <section className="col-lg-6 mb-20">
              <section className="forms-blk">
                <div className="head-1">Password</div>
                <div className="form-group">
                  <input
                    id="password"
                    type="password"
                    className="form-control"
                    name="password"
                    placeholder="Enter password"
                    autoComplete="off"
                    onChange={(e) => this.getinput(e)}
                    // onChange={(e) =>
                    //   this.props.updateState("bundleTitle", e.target.value)
                    // }
                    value={this.state.password}
                  />
                </div>
              </section>
            </section>
            <section className="col-lg-6 mb-20">
              <section className="forms-blk">
                <div className="head-1">Admin Type</div>
                <div className="form-group">
                  <Select
                    onChange={(e) =>
                      this.setState({
                        type: e.value,
                      })
                    }
                    options={this.state.typesOfAdmin}
                    placeholder="Select"
                    // value={this.props.membershipSelectOptions.filter(
                    //   (item) => item.value === this.props.state.subscription
                    // )}
                  />
                </div>
              </section>
            </section>
          </section>
        </section>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "50px",
          }}
        >
          <Button
            disabled={
              !(
                this.state.email &&
                this.state.name &&
                this.state.password &&
                this.state.phone &&
                this.state.phone.length == 10 &&
                this.state.type
              )
            }
            onClick={this.LoginGet}
          >
            Submit
          </Button>
        </div>

        <div
          style={{ marginTop: "10px" }}
          className={`alert alert-success ${
            this.state.showSuccess ? "" : "d-none"
          }`}
          id="wrongmess"
        >
          Account created successfully.
        </div>
        <div
          style={{ marginTop: "10px" }}
          className={`alert alert-danger ${
            this.state.showError ? "" : "d-none"
          }`}
          id="wrongmess"
        >
          Something went wrong.
        </div>
      </section>
    );
  }
}
