import React,{useState} from 'react'
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Addvideocomp = (props) => {
    const [memType, setmemType] = useState('');
    const [catType, setcatType] = useState('');
    const [videoType, setVideoType] = useState('');

    // console.log("props.stateinfo", props.stateinfo);
    const [liveHedgedType, liveHedgedsetcatType] = useState('');

    const [fromDate, setfromDate] = useState(new Date());
    const [toDate, settoDate] = useState(new Date());
    
    // useEffect(() => {
    //     props.setDatefun(fromDate,'publishfrom');
    //     props.setDatefun(toDate,'publishto');
    // }, [])
    const memberOnChange = memType => {
        setmemType(memType);
        props.memberOnChange(memType)
    };
    const categoryOnChange = catType => {
        setcatType(catType);
        props.categoryOnChange(catType)
    }; 

    const videoTypeChange = type => {
        setVideoType(type);
        props.videoTypeOnChange(type)
    }; 

    const liveHedgedcategoryOnChange = liveHedgedType => {
        liveHedgedsetcatType(liveHedgedType);
        props.liveHedgedcategoryOnChange(liveHedgedType)
    }; 

    const stfrmDt = (date) =>{
        setfromDate(date);
        props.setDatefun(date,'publishfrom');
    }
    const sttoDt = (date) =>{
        settoDate(date);
        props.setDatefun(date,'publishto');
    }
    const VideoThumb = ({ vdeo }) => {
        return (
            <video width="100%" height="100%" autoPlay={true} muted={true} loop={true} className={`vidresponive`}>
                <source src={URL.createObjectURL(vdeo)} type="video/mp4"  alt={vdeo.name} className={`img-responsive`}/>
            </video>
        )
    }

    console.log("video", videoType);
    return (
        <>
        <section className="row m-0 p-0 mt-4 justify-content-center addblk">
            <section className="col-lg-12">
                {/* <section className="dndblk text-center position-relative rounded-0 mb-3">
                    <label className="my-4 text-center position-absolute">Drag and drop a file here or click browse</label>
                    <input type="file" onChange={props.handlevidupload} name="myVideo" placeholder="Browse" accept="video/mp4,video/x-m4v,video/*" id="vidfile"/>
                    <button className={`btnbrwose`}>Browse</button>
                    {props.stateinfo.fileinfo && <VideoThumb vdeo={props.stateinfo.fileinfo}/>}
                </section> */}
                <section className="row m-0 p-0 justify-content-center">
                    <section className="col-lg-12 formblk">
                    <section className="blk w-100">
                                <label>Type</label>
                                <Select value={videoType} onChange={videoTypeChange} options={props.videoType} classNamePrefix="addsele" />
                            </section>
                        <section className="blk mt-3 w-100">
                            <label>Topic</label><input type="text" name="title" placeholder="" className="inpfield" autoComplete="off" onChange={(e)=>props.inputchange(e)}/>
                        </section>
                        <section className="blk mt-3 w-100">
                            <label>Description</label><input type="text" name="description" placeholder="" autoComplete="off" className="inpfield" onChange={(e)=>props.inputchange(e)}/>
                        </section>
                        <section className="blk mt-3 w-100">
                            {/* <label>{props.stateinfo.fakeobj[0]?.source == "vimeo" ? "Video URL" : "Zoom Meeting Number"}</label><input type="text" name="videourl" placeholder="" autoComplete="off" className="inpfield" onChange={(e)=>props.inputchange(e)} value={props.stateinfo.fakeobj[0]["videourl"]}/> */}
                            <label>{"Video URL"}</label><input type="text" name="videourl" placeholder="" autoComplete="off" className="inpfield" onChange={(e)=>props.inputchange(e)} value={props.stateinfo.fakeobj[0]["videourl"]}/>

                        </section>
                        {videoType?.value !== "vimeo" && videoType !== "" &&
                        <section className="blk mt-3 w-100">
                            <label>Password</label><input type="text" name="password" placeholder="" className="inpfield" autoComplete="off" onChange={(e)=>props.inputchange(e)}/>
                        </section>}
                        <section className="mt-3">
                            <section className="blk w-100">
                                <label>Category</label>
                                <Select value={catType} onChange={categoryOnChange} options={props.categorytype} classNamePrefix="addsele" />
                            </section>
                            {/* {
                                props.stateinfo.vidmodal.iscatmem && ( <section className="blk mt-3 w-100">
                                    <label>Membership</label>
                                    <Select value={memType} onChange={memberOnChange} options={props.addmembertype} placeholder='Membership' classNamePrefix="addsele" />
                                </section>)
                            } */}
                        </section>
                        <section className="mt-3">
                            <section className={`blk w-100 ${props.stateinfo.vidmodal.iscatmem ? 'd-block':'d-none'}`}>
                                <label>Hedged Category</label>
                                <Select value={liveHedgedType} onChange={liveHedgedcategoryOnChange} options={props.liveFromHedgedSelected} classNamePrefix="addsele" />
                            </section>
                        </section>
                        {/* <section className="row m-0 p-0 mt-4" style={{display:'display:inline-block',position:'relative'}}>
                            <section className="col-lg-6 pe-5 ps-0">
                                <h4>Publish From</h4>
                                <DatePicker placeholderText="Select Date/Time" selected={fromDate} onChange={date =>stfrmDt(date)} className="rounded-0 mt-3 dtblk" timeInputLabel="Time:" showTimeInput dateFormat="dd/MM/yyyy h:mm aa"/>
                            </section>
                            <section className="col-lg-6 pe-0 ps-5">
                                <h4>Publish To</h4>
                                <DatePicker placeholderText="Select Date/Time" selected={toDate} onChange={date =>sttoDt(date)} className="rounded-0 mt-3 dtblk" timeInputLabel="Time:" showTimeInput dateFormat="dd/MM/yyyy h:mm aa"  />
                            </section>
                        </section> */}
                        <section className="mt-3 publish-on-off">
                            <input type="checkbox" name="publish" id="publish" onChange={(e)=>props.publishOnOff(e)} />
                            <label htmlFor="publish">Publish</label>
                        </section>
                        {props.stateinfo.publishOnOffState?
                        <section className="mt-3 featured-blk">
                            <input type="checkbox" name="featured" id="featured" onChange={(e)=>props.featuredOnOff(e)} checked={props.stateinfo.featuredOnOffState}/>
                            <label htmlFor="featured">Featured<span>&nbsp;</span></label>
                        </section>:null}
                    </section>
                </section> 
                <section className="row justify-content-center p-0 m-0 my-3 pt-5 newvideo-saveBtn">
                    <section className="col-3">
                        {/* <button className="btnsave disabled" onClick={props.addvidsave}>Save</button> */}
                        <button className={`btnsave-video ${props.newVideoSaveDisabled() ? 'disabled':''}`} onClick={props.addvidsave}>Save</button>
                    </section>
                </section>
            </section>
        </section>
        </>
    )
}

export default Addvideocomp;
