import React from "react";
import { NavLink } from "react-router-dom";
import * as API from "../../configuration/apiconfig";
import moment from "moment-timezone";
import Swal from "sweetalert2";
import Datatable from "react-bs-datatable";
const format1 = "DD/MM/YYYY hh:mm A";

class Calls extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newcall: null,
      plans: [],
      plansData: [],
      deleted_calls: [],
      close_calls: [],
      close_intraday_call: [],
      intraday_calls: [],
      closeCall: false,
      CloseIntradayCall: false,
    };
  }

  newCallFunction = (getTxt) => {
    this.setState({
      newcall: getTxt,
    });
  };

  getData = async () => {
    // `/api/calls/deleted-calls`
    await API.callEndpoint("GET", "Bearer", `/api/intraday/deleted-calls`)
      .then(async (response) => {
        this.setState({
          deleted_calls: response?.data?.data,
        });
      })
      .catch((error) => {
        console.log(error.error);
      });
    await API.callEndpoint("GET", "Bearer", `/api/intraday?status=closed`)
      .then(async (response) => {
        this.setState({
          close_calls: response?.data?.data,
        });
      })
      .catch((error) => {
        console.log(error.error);
      });

    await API.callEndpoint("GET", "Bearer", `/api/intraday?status=open`)
      .then(async (response) => {
        this.setState({
          intraday_calls: response?.data?.data,
        });
      })
      .catch((error) => {
        console.log(error.error);
      });
  };

  componentDidMount = async () => {
    await this.getData();
  };

  handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
        API.callEndpoint("DELETE", "Bearer", `/api/calls/${id}`)
          .then(async (response) => {
            if (response.status === 200) {
              this.getData();
            }
          })
          .catch((error) => {
            console.log(error.error);
          });
      }
    });
  };

  handleIntradayCallDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
        API.callEndpoint("DELETE", "Bearer", `/api/intraday/${id}`)
          .then(async (response) => {
            if (response.status === 200) {
              this.getData();
            }
          })
          .catch((error) => {
            console.log(error.error);
          });
      }
    });
  };

  render() {
    return (
      <section className="admin-calls">
        <h1 className="head text-uppercase m-0">Intraday Calls</h1>
        <div className="row mb-4">
          <div className="col-md-8 d-flex align-items-center"></div>
          <div className="col-md-4 text-end calls-dropdown">
            <div
              className="new-call-btn"
              style={{
                width: 160,
                marginLeft: 5,
              }}
            >
              <NavLink exact to={"/intradaycall/new"} title="Intraday Call">
                New Intraday Call
              </NavLink>
            </div>
          </div>
        </div>
        <section className="table-blk">
          <ul
            className="nav nav-tabs border-bottom-0"
            id="myTab"
            role="tablist"
          >
            <li className="nav-item" role="presentation" key={"intraday_calls"}>
              <button
                style={{
                  width: 140,
                }}
                className={"nav-link active"}
                // id={`${item["planName"].toLowerCase()}-tab`}
                data-bs-toggle="tab"
                data-bs-target={`#intraday_calls`}
                type="button"
                role="tab"
                aria-controls={"intraday_calls"}
                aria-selected="true"
              >
                Intraday calls
              </button>
            </li>
            <li className="nav-item" role="presentation" key={"delete_calls"}>
              <button
                style={{
                  width: 140,
                }}
                className={"nav-link "}
                // id={`${item["planName"].toLowerCase()}-tab`}
                data-bs-toggle="tab"
                data-bs-target={`#delete_calls`}
                type="button"
                role="tab"
                aria-controls={"delete_calls"}
                aria-selected="true"
              >
                Deleted calls
              </button>
            </li>
            <li className="nav-item" role="presentation" key={"close_calls"}>
              <button
                style={{
                  width: 140,
                }}
                className={"nav-link "}
                // id={`${item["planName"].toLowerCase()}-tab`}
                data-bs-toggle="tab"
                data-bs-target={`#close_calls`}
                type="button"
                role="tab"
                aria-controls={"close_calls"}
                aria-selected="true"
              >
                Close calls
              </button>
            </li>
          </ul>

          <div className="tab-content" id="myTabContent">
            <div
              key={"intraday_calls"}
              className={"tab-pane active usermanage"}
              id={"intraday_calls"}
              role="tabpanel"
              aria-labelledby={`intraday_calls-tab`}
            >
              <Datatable
                tableHeaders={[
                  {
                    title: "Created Date & Time",
                    prop: "createdAt",
                    cell: (row) => {
                      return (
                        <>
                          <p>
                            {moment
                              .utc(row.createdAt)
                              .utcOffset("-00:00")
                              .format(format1, "hh:mm A")}
                          </p>
                        </>
                      );
                    },
                  },
                  { title: "call id", prop: "id", sortable: true },
                  { title: "Strategy", prop: "strategy" },
                  { title: "Type", prop: "call_type" },
                  { title: "Scrip", prop: "scripname" },
                  { title: "Call Status", prop: "status", sortable: true },
                  {
                    title: "Call Close Type",
                    prop: "close_type",
                    cell: (call) => {
                      return (
                        <p>
                          {call.close_type === null ? "---" : call.close_type}
                        </p>
                      );
                    },
                  },
                  {
                    title: "Actions",
                    prop: "actions",
                    cell: (call) => {
                      return (
                        <>
                          <p>
                            <NavLink
                              exact
                              to={`intracall_details?id=${call.id}`}
                              title="Details"
                            >
                              Details
                            </NavLink>

                            <NavLink
                              exact
                              to={`close_intradayCall?id=${call.id}`}
                              title="Close"
                            >
                              Close
                            </NavLink>

                            <a
                              style={{ color: "#000" }}
                              onClick={() =>
                                this.handleIntradayCallDelete(call.id)
                              }
                            >
                              Delete
                            </a>
                            {call.status !== "closed" ? (
                              <NavLink
                                exact
                                to={`modify_intradayCall?id=${call.id}`}
                                title="Modify"
                              >
                                Modify
                              </NavLink>
                            ) : null}
                          </p>
                        </>
                      );
                    },
                  },
                ]}
                id="table-to-xls"
                tableBody={this.state.intraday_calls}
                // rowsPerPage={this.state.intraday_calls?.length}
                labels={{
                  filterPlaceholder: "Search",
                }}
              />
              {/* <table className="table table-borderless">
                <thead>
                  <tr>
                    <th>Created Date &amp; Time</th>
                    <th>call id</th>
                    <th>Strategy</th>
                    <th>Type</th>
                    <th>Scrip</th>
                    <th>Call Status</th>
                    <th>Call Close Type</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.intraday_calls.data &&
                    this.state.intraday_calls.data?.map((call, callIndex) => {
                      return (
                        <tr key={1}>
                          <td>
                            {" "}
                            {moment
                              .utc(call.createdAt)
                              .utcOffset("-00:00")
                              .format(format1, "hh:mm A")}
                          </td>
                          <td>{call?.id}</td>
                          <td>{call?.strategy}</td>
                          <td>{call.call_type}</td>
                          <td>{call.scripname?.toUpperCase()}</td>
                          <td>{call.status}</td>
                          <td>
                            {call.close_type === null ? "---" : call.close_type}
                          </td>
                          <td>
                            <NavLink
                              exact
                              to={`intracall_details?id=${call.id}`}
                              title="Details"
                            >
                              Details
                            </NavLink>

                            <NavLink
                              exact
                              to={`close_intradayCall?id=${call.id}`}
                              title="Close"
                            >
                              Close
                            </NavLink>

                            <a
                              style={{ color: "#000" }}
                              onClick={() =>
                                this.handleIntradayCallDelete(call.id)
                              }
                            >
                              Delete
                            </a>
                            {call.status !== "closed" ? (
                              <NavLink
                                exact
                                to={`modify_intradayCall?id=${call.id}`}
                                title="Modify"
                              >
                                Modify
                              </NavLink>
                            ) : null}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table> */}
            </div>

            <div
              key={"delete_calls"}
              className={"tab-pane fade usermanage"}
              id={"delete_calls"}
              role="tabpanel"
              aria-labelledby={`delete_calls-tab`}
            >
              <Datatable
                tableHeaders={[
                  {
                    title: "Created Date & Time",
                    prop: "createdAt",
                    cell: (row) => {
                      return (
                        <>
                          <p>
                            {moment
                              .utc(row.createdAt)
                              .utcOffset("-00:00")
                              .format(format1, "hh:mm A")}
                          </p>
                        </>
                      );
                    },
                  },
                  { title: "call id", prop: "id", sortable: true },
                  { title: "Strategy", prop: "strategy" },
                  { title: "Type", prop: "call_type" },
                  { title: "Scrip", prop: "scripname" },
                  { title: "Call Status", prop: "status", sortable: true },
                  {
                    title: "Call Close Type",
                    prop: "close_type",
                    cell: (call) => {
                      return (
                        <p>
                          {call.close_type === null ? "---" : call.close_type}
                        </p>
                      );
                    },
                  },
                  {
                    title: "Actions",
                    prop: "actions",
                    cell: (call) => {
                      return (
                        <>
                          <p>
                            <NavLink
                              exact
                              to={`intracall_details?id=${call.id}`}
                              title="Details"
                            >
                              Details
                            </NavLink>
                          </p>
                        </>
                      );
                    },
                  },
                ]}
                id="table-to-xls"
                tableBody={this.state.deleted_calls}
                // rowsPerPage={this.state.intraday_calls?.length}
                labels={{
                  filterPlaceholder: "Search",
                }}
              />
              {/* <table className="table table-borderless">
                <thead>
                  <tr>
                    <th>Created Date &amp; Time</th>
                    <th>Strategy</th>
                    <th>Type</th>
                    <th>Scrip</th>
                    <th>Call Status</th>
                    <th>Call Close Type</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state?.deleted_calls &&
                    this?.state?.deleted_calls?.map((call, index) => (
                      <tr>
                        <td>
                          {moment
                            .utc(call.createdAt)
                            .utcOffset("-00:00")
                            .format(format1, "hh:mm A")}
                        </td>
                        <td>{call?.strategy}</td>
                        <td>{call.call_type}</td>
                        <td>{call.scripname?.toUpperCase()}</td>
                        <td>{call.status}</td>
                        <td>
                          {call.close_type === null ? "---" : call.close_type}
                        </td>
                        <td>
                          <NavLink
                            exact
                            to={`intracall_details?id=${call.id}`}
                            title="Details"
                          >
                            Details
                          </NavLink>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table> */}
            </div>
            <div
              key={"close_calls"}
              className={"tab-pane fade usermanage"}
              id={"close_calls"}
              role="tabpanel"
              aria-labelledby={`close_calls-tab`}
            >
              <Datatable
                tableHeaders={[
                  {
                    title: "Created Date & Time",
                    prop: "createdAt",
                    cell: (row) => {
                      return (
                        <>
                          <p>
                            {moment
                              .utc(row.createdAt)
                              .utcOffset("-00:00")
                              .format(format1, "hh:mm A")}
                          </p>
                        </>
                      );
                    },
                  },
                  { title: "call id", prop: "id", sortable: true },
                  { title: "Strategy", prop: "strategy" },
                  { title: "Type", prop: "call_type" },
                  { title: "Scrip", prop: "scripname" },
                  { title: "Call Status", prop: "status", sortable: true },
                  {
                    title: "Call Close Type",
                    prop: "close_type",
                    cell: (call) => {
                      return (
                        <p>
                          {call.close_type === null ? "---" : call.close_type}
                        </p>
                      );
                    },
                  },
                  {
                    title: "Actions",
                    prop: "actions",
                    cell: (call) => {
                      return (
                        <>
                          <p>
                            <NavLink
                              exact
                              to={`intracall_details?id=${call.id}`}
                              title="Details"
                            >
                              Details
                            </NavLink>
                          </p>
                        </>
                      );
                    },
                  },
                ]}
                id="table-to-xls"
                tableBody={this.state.close_calls}
                // rowsPerPage={this.state.intraday_calls?.length}
                labels={{
                  filterPlaceholder: "Search",
                }}
              />
              {/* <table className="table table-borderless">
                <thead>
                  <tr>
                    <th>Created Date &amp; Time</th>
                    <th>Strategy</th>
                    <th>Type</th>
                    <th>Scrip</th>
                    <th>Call Status</th>
                    <th>Call Close Type</th>
                    <th>Actions</th>{" "}
                  </tr>
                </thead>
                <tbody>
                  {this.state?.close_calls &&
                    this?.state?.close_calls?.map((call, index) => (
                      <tr>
                        <td>
                          {moment
                            .utc(call.createdAt)
                            .utcOffset("-00:00")
                            .format(format1, "hh:mm A")}
                        </td>
                        <td>{call?.strategy}</td>
                        <td>{call.call_type}</td>
                        <td>{call.scripname?.toUpperCase()}</td>
                        <td>{call.status}</td>
                        <td>
                          {call.close_type === null ? "---" : call.close_type}
                        </td>
                        <td>
                          <NavLink
                            exact
                            to={`intracall_details?id=${call.id}`}
                            title="Details"
                          >
                            Details
                          </NavLink>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table> */}
            </div>
          </div>
        </section>
      </section>
    );
  }
}

export default Calls;
