import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";



const ImageModel = (props) => {

  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={`modalblkk`}
    >
      <Modal.Body>
        <section className="row m-0 p-0 mt-4 justify-content-center addblk">
          <section className="col-lg-12">
            <section className="row m-0 p-0 justify-content-center">
             <img src={props?.imgData}/>
            </section>
          </section>
        </section>
      </Modal.Body>
    </Modal>
  );
};

export default ImageModel;
