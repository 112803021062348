import React, { Component } from "react";
import * as API from "../../configuration/apiconfig";
import moment from "moment";
import Select from "react-select";
import Tablecomp from "./Tablecomp";
import Bannermodal from "./Bannermodal";
import Bannercomp from "./Bannercomp";
import Filterresultcomp from "./Filterresultcomp";
import Swal from "sweetalert2";
import { ReactComponent as Loader } from "../../assets/images/loader.svg";

const renameFile = (originalFile, newName) => {
  return new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
};

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const dateformat = "DD/MM/YYYY";

const membertype = [
  { value: "Free", label: "Free", id: "1" },
  { value: "Prime", label: "Prime", id: "2" },
  { value: "Crown", label: "Crown", id: "3" },
  { value: "Intrday", label: "Intrday", id: "4" },
  { value: "2hr", label: "2HR Trader", id: "5" },
];
const tradertype = [
  { value: "", label: "All" },
  { value: "Experienced", label: "Experienced" },
  { value: "Newbie", label: "Newbie" },
];
const selectmonth = [
  { value: "JAN", label: "JAN" },
  { value: "FEB", label: "FEB" },
  { value: "MAR", label: "MAR" },
  { value: "APR", label: "APR" },
  { value: "MAY", label: "MAY" },
  { value: "JUN", label: "JUN" },
  { value: "JUL", label: "JUL" },
  { value: "AUG", label: "AUG" },
  { value: "SEP", label: "SEP" },
  { value: "OCT", label: "OCT" },
  { value: "NOV", label: "NOV" },
  { value: "DEC", label: "DEC" },
];

class Showcasedashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      alertbox: false,
      bannerinfo: [],
      hedgedToggle: {},
      memType: "",
      intradayon: false,
      monType: "",
      tradType: "",
      selectVal: "",
      showModal: false,
      fileinfo: "",
      TradersTyp: [
        {
          id: "1",
          name: "Free",
          YTD: "80%",
          HOC: "70%",
          HOCdisplay: false,
          YTDdisplay: false,
          nooftrad: 1025,
          tradprofit: 950,
          tradclosprof: 914,
          dispnooftrd: false,
          disptradprofit: false,
          disptradclosprof: false,
          disptradbanner: false,
        },
        {
          id: "2",
          name: "Prime",
          YTD: "85%",
          HOC: "65%",
          HOCdisplay: false,
          YTDdisplay: false,
          nooftrad: 1015,
          tradprofit: 960,
          tradclosprof: 924,
          dispnooftrd: false,
          disptradprofit: false,
          disptradclosprof: false,
          disptradbanner: false,
        },
        {
          id: "3",
          name: "Crown",
          YTD: "90%",
          HOC: "55%",
          HOCdisplay: false,
          YTDdisplay: false,
          nooftrad: 1005,
          tradprofit: 970,
          tradclosprof: 934,
          dispnooftrd: false,
          disptradprofit: false,
          disptradclosprof: false,
          disptradbanner: false,
        },
        {
          id: "5",
          name: "2HR Trader",
          YTD: "90%",
          HOC: "55%",
          HOCdisplay: false,
          YTDdisplay: false,
          nooftrad: 1005,
          tradprofit: 970,
          tradclosprof: 934,
          dispnooftrd: false,
          disptradprofit: false,
          disptradclosprof: false,
          disptradbanner: false,
        },
      ],
      resultsAPI: [],
      header: [
        { title: "Date", prop: "date" },
        { title: "Stock", prop: "stock" },
        { title: "Strategy", prop: "strategy" },
        { title: "Capital", prop: "capital" },
        { title: "Profit/Loss (INR) ", prop: "pl" },
      ],
      stockResultAPIData: [],
      bannerImgURL: "",
      uploadingOverlay: false,
      uploadErrorMsg: "",
      showImgBox: false,
    };
  }
  componentDidMount() {
    // let newObj = new Object();
    // newObj.imginfo = "";
    // newObj.publishondate = "";
    // newObj.publishontime = "";
    // newObj.rightnow = "";
    // newObj.publishtilldate = "";
    // newObj.publishtilltime = "";
    // newObj.forever = "";
    // newObj.tradtyp = "";
    // newObj.tradmembership = "";
    // let setBnrinfo = [...this.state.bannerinfo, newObj];
    // this.setState({ bannerinfo: setBnrinfo });
    // setTimeout(() => {
    //   console.log(this.state.bannerinfo);
    // }, 500);

    this.getHomeBanners();
  }

  memberOnChange = (memType) => {
    // console.log("memType: ", memType);
    this.checkHedgedToggle(memType.id);
    this.setState({ memType });
    this.getResultsAPI(memType.id);
  };

  getResultsAPI = (getId) => {
    // console.log(getId, "id");
    if (getId === "4") {
      API.callEndpoint("GET", "Bearer", "/api/intraday/hedgedresults?ytd=1")
        .then((response) => {
          // console.log(response.data, "results");
          this.setState(
            {
              resultsAPI: response.data,
              intradayon: true,
            },
            () => {
              this.tableResultOne();
            }
          );
        })
        .catch((error) => {
          console.log(error.error);
        });
    } else {
      API.callEndpoint(
        "GET",
        "Bearer",
        // `/api/calls/hedgedresults?planid=${getId}&ytd=1&limit=3`
        `/api/calls/hedgedresults?planid=${getId}&ytd=1`
      )
        .then((response) => {
          // console.log(response.data, "results");
          this.setState(
            {
              resultsAPI: response.data,
              intradayon: false,
            },
            () => {
              this.tableResult();
            }
          );
        })
        .catch((error) => {
          console.log(error.error);
        });
    }
  };

  tableResult = () => {
    let getStockResultArray = new Array();
    let getStockResult = this.state.resultsAPI;
    getStockResult.map((result, index) => {
      result.stock_result.map((stkres, index) => {
        let setStockdata = new Object();
        setStockdata.date =
          stkres.scheduled_date == null
            ? moment(stkres.createdAt).format(dateformat)
            : moment(stkres.scheduled_date).format(dateformat);
        setStockdata.stock = stkres.scripname;
        setStockdata.strategy =
          stkres.call_class == "Options" ? stkres.strategy : stkres.call_type;
        setStockdata.capital = "\u20B9 " + stkres.capital_required;
        setStockdata.pl =
          stkres.close_type == "profit"
            ? "\u20B9 " + stkres.close_total
            : "\u20B9 " + "-" + stkres.close_total;
        getStockResultArray.push(setStockdata);
        this.setState({
          stockResultAPIData: getStockResultArray,
        });
      });
    });
  };

  tableResultOne = () => {
    let getStockResultArray = new Array();
    let getStockResult = this.state.resultsAPI;
    getStockResult.map((result, index) => {
      result.stock_result.map((stkres, index) => {
        let setStockdata = new Object();
        setStockdata.date =
          stkres.scheduled_date == null
            ? moment(stkres.createdAt).format(dateformat)
            : moment(stkres.scheduled_date).format(dateformat);
        setStockdata.stock = stkres.scripname;
        setStockdata.strategy = stkres.strategy;
        setStockdata.capital = "\u20B9 " + stkres.capital_required;
        setStockdata.pl =
          stkres.close_type?.toLowerCase() == "profit"
            ? "\u20B9 " + stkres.close_total
            : "\u20B9 " + "-" + stkres.close_total;
        getStockResultArray.push(setStockdata);
        this.setState({
          stockResultAPIData: getStockResultArray,
        });
      });
    });
  };
  traderOnChange = (tradType) => {
    this.setState({ tradType });
  };
  monthOnChange = (monType) => {
    this.setState({ monType });
  };

  openModal = () => {
    let newObj = new Object();
    newObj.imginfo = "";
    newObj.publishondate = moment(new Date()).format("MM/DD/YYYY");
    newObj.publishontime = moment(new Date()).format("hh:mm");
    newObj.rightnow = false;
    newObj.publishtilldate = moment(new Date()).format("MM/DD/YYYY");
    newObj.publishtilltime = moment(new Date()).format("hh:mm");
    newObj.forever = false;
    newObj.tradtyp = "";
    newObj.tradmembership = "";
    let setBnrinfo = [...this.state.bannerinfo, newObj];
    this.setState({ bannerinfo: setBnrinfo });
    this.setState({ showModal: true, showImgBox: false });
  };

  hidemodal = () => {
    //alert(document.getElementById('imagefile').value);
    this.setState({ showModal: false });
    let rmvBnr = [...this.state.bannerinfo];
    rmvBnr.pop();
    this.setState({ bannerinfo: rmvBnr, alertbox: false, fileinfo: "" });
  };
  getData = () => {
    // console.log(this.state.monType.value);
  };
  handleUpload = async (event) => {
    let getbnrindx = this.state.bannerinfo.length - 1;
    let setBnr = [...this.state.bannerinfo];
    setBnr[getbnrindx].imginfo = await getBase64(event.target.files[0]);
    this.setState({
      bannerinfo: setBnr,
      fileinfo: event.target.files[0],
    });
    if (setBnr[getbnrindx].imginfo !== "") {
      this.setState({ alertbox: false });
    }
    //this.bannerUploadFile(event);
  };

  bannerUploadFile = async (e) => {
    this.setState({
      uploadingOverlay: true,
    });
    console.log("uploading...");
    let file = e.target.files[0];

    const fileFormat = file.type.split("/")[1];
    const queryObj = {
      fileFormat,
      type: "bannerimage",
    };

    await API.callEndpoint(
      "GET",
      "Bearer",
      `/api/files/presigned?fileFormat=${queryObj.fileFormat}&type=${queryObj.type}`
    )
      .then((response) => {
        const { key, url } = response.data.data;
        const fileName = `${key}.${fileFormat}`;
        const renamedFile = renameFile(file, fileName);

        const myHeaders = new Headers({ "Content-Type": "image/*" });
        fetch(url, {
          method: "PUT",
          headers: myHeaders,
          body: renamedFile,
        })
          .then(() => {
            API.callEndpoint(
              "GET",
              "Bearer",
              `/api/files/presigned-url?fileName=${fileName}&type=${queryObj.type}`
            )
              .then((response) => {
                this.setState({
                  bannerImgURL: response.data?.data.split("?X-")[0],
                  uploadErrorMsg: "",
                  uploadingOverlay: false,
                  showImgBox: false,
                });
              })
              .catch((error) => {
                console.log(error.error);
                this.setState({
                  tradeErrorMsg: error.error,
                  tradeSuccessMsg: "",
                });
              });
          })
          .catch((error) => {
            console.log(error.error);
            this.setState({
              tradeErrorMsg: error.error,
              tradeSuccessMsg: "",
            });
          });
      })
      .catch((error) => {
        console.log(error.error);
        console.log("Not uploaded image----");
        this.setState({
          uploadErrorMsg: "Image size too large",
          uploadingOverlay: false,
          bannerImgURL: "",
          showImgBox: false,
        });
      });
  };

  setTiming = (info, objname) => {
    let getbnrindx = this.state.bannerinfo.length - 1;
    let setBnr = [...this.state.bannerinfo];
    setBnr[getbnrindx][objname] = info;
    this.setState({ bannerinfo: setBnr });
    if (setBnr[getbnrindx][objname] !== "") {
      this.setState({ alertbox: false });
    }
  };
  getStatus = (e) => {
    let getbnrindx = this.state.bannerinfo.length - 1;
    let setBnr = [...this.state.bannerinfo];
    let checked = e.target.checked;
    let chekval;
    if (checked) {
      chekval = true;
    } else {
      chekval = false;
    }
    setBnr[getbnrindx][e.target.name] = chekval;
    this.setState({ bannerinfo: setBnr });
    if (setBnr[getbnrindx][e.target.name] !== "") {
      this.setState({ alertbox: false });
    }
  };
  getradioinfo = (e) => {
    let getbnrindx = this.state.bannerinfo.length - 1;
    let setBnr = [...this.state.bannerinfo];
    setBnr[getbnrindx][e.target.name] = e.target.value;
    this.setState({ bannerinfo: setBnr });
    console.log(this.state.bannerinfo);
    if (setBnr[getbnrindx][e.target.name] !== "") {
      this.setState({ alertbox: false });
    }
  };
  addBanner = () => {
    let getbnrindx = this.state.bannerinfo.length - 1;
    let setBnr = [...this.state.bannerinfo];
    if (
      setBnr[getbnrindx].imginfo !== "" &&
      setBnr[getbnrindx].publishondate !== "" &&
      setBnr[getbnrindx].publishontime !== "" &&
      // setBnr[getbnrindx].publishtilldate !== "" &&
      // setBnr[getbnrindx].publishtilltime !== "" &&
      setBnr[getbnrindx].tradtyp !== "" &&
      setBnr[getbnrindx].tradtyp !== ""
    ) {
      this.setState({ showModal: false, fileinfo: "", bannerImgURL: "" });
      console.log(setBnr);
      this.showcaseHomeBannerAPI();
    } else {
      this.setState({ alertbox: true });
    }
  };

  showcaseHomeBannerAPI = async () => {
    let getbnrindx = this.state.bannerinfo.length - 1;
    const file = this.state.fileinfo;
    const fileFormat = file.type.split("/")[1];
    const queryObj = {
      fileFormat,
      type: "bannerimage",
    };

    await API.callEndpoint(
      "GET",
      "Bearer",
      `/api/files/presigned?fileFormat=${queryObj.fileFormat}&type=${queryObj.type}`
    )
      .then((response) => {
        const { key, url } = response.data.data;
        const fileName = `${key}.${fileFormat}`;
        const renamedFile = renameFile(file, fileName);

        const myHeaders = new Headers({ "Content-Type": "image/*" });
        fetch(url, {
          method: "PUT",
          headers: myHeaders,
          body: renamedFile,
        })
          .then(async () => {
            await API.callEndpoint(
              "GET",
              "Bearer",
              `/api/files/presigned-url?fileName=${fileName}&type=${queryObj.type}`
            )
              .then((response) => {
                var data = new FormData();
                data.append("file", response?.data?.data.split("?X-")[0]);
                data.append(
                  "startdate",
                  this.state.bannerinfo[getbnrindx].publishondate +
                    " " +
                    this.state.bannerinfo[getbnrindx].publishontime
                );
                data.append(
                  "enddate",
                  this.state.bannerinfo[getbnrindx].publishtilldate +
                    " " +
                    this.state.bannerinfo[getbnrindx].publishtilltime ===
                    " "
                    ? ""
                    : this.state.bannerinfo[getbnrindx].publishtilldate +
                        " " +
                        this.state.bannerinfo[getbnrindx].publishtilltime
                );
                data.append("type", "promo");
                data.append(
                  "planid",
                  parseInt(this.state.bannerinfo[getbnrindx].tradmembership)
                );
                data.append(
                  "call_target",
                  this.state.bannerinfo[getbnrindx].tradtyp == "All"
                    ? ""
                    : this.state.bannerinfo[getbnrindx].tradtyp
                );

                console.log(data, "test");

                API.callEndpoint("POST", "Bearer", "/api/homebanners", data)
                  .then((response) => {
                    this.setState(
                      {
                        showImgBox: true,
                      },
                      () => {
                        this.getHomeBanners();
                      }
                    );
                  })
                  .catch((error) => {
                    console.log(error.error);
                  });
              })
              .catch((error) => {
                console.log(error.error);
                this.setState({
                  tradeErrorMsg: error.error,
                  tradeSuccessMsg: "",
                });
              });
          })
          .catch((error) => {
            console.log(error.error);
            this.setState({
              tradeErrorMsg: error.error,
              tradeSuccessMsg: "",
            });
          });
      })
      .catch((error) => {
        console.log(error.error);
        console.log("Not uploaded image----");
        this.setState({
          uploadErrorMsg: "Image size too large",
          uploadingOverlay: false,
          bannerImgURL: "",
          showImgBox: false,
        });
      });
  };

  getHomeBanners = () => {
    API.callEndpoint("GET", "Bearer", "/api/homebanners", "")
      .then((response) => {
        // console.log(response.data);
        this.setState({ bannerinfo: response.data });
        setTimeout(() => {
          this.setState({ showLoader: false });
        }, 500);
      })
      .catch((error) => {
        console.log(error.error);
      });
  };

  checkHedgedToggle = (id) => {
    API.callEndpoint("GET", "Bearer", `/api/hedged-result-toggle`, "")
      .then((response) => {
        this.setState({ hedgedToggle: response.data[0] });
      })
      .catch((error) => {
        console.log(error.error);
      });
  };

  deleteHomeBanner = (videoId) => {
    API.callEndpoint("DELETE", "Bearer", "/api/homebanners/" + videoId, "")
      .then((response) => {
        this.getHomeBanners();
        Swal.fire("Deleted!", "The banner has been deleted.", "success");
      })
      .catch((error) => {
        console.log(error.error);
      });
  };

  modelConfirmBtnDisabled = () => {
    var disabled = false;
    this.state.bannerinfo.map((item, index) => {
      // console.log(item.publishondate, item.publishontime, item.publishtilldate, item.publishtilltime, item.tradmembership, item.tradtyp);
      if (
        item.fileinfo !== "" &&
        item.publishondate !== "" &&
        item.publishontime !== "" &&
        item.tradmembership !== "" &&
        item.tradtyp !== ""
      ) {
        disabled = true;
      } else {
        disabled = false;
      }
    });
    return disabled;
  };

  displaytradeopt = (event, id) => {
    console.log(event.target.name);
    const data = {};
    if (event.target.name == "HOCdisplay") {
      data.id = [1, 2, 3, 5];
      data.hit_rate = event.target.checked;
      data.ytd_roc = !event.target.checked;
    } else if (event.target.name == "YTDdisplay") {
      data.id = [1, 2, 3, 5];
      data.ytd_roc = event.target.checked;
      data.hit_rate = !event.target.checked;
    } else if (event.target.name == "dispnooftrd") {
      data.id = [1, 2, 3, 5];
      data.trade_numbers = event.target.checked;
    } else if (event.target.name == "disptradprofit") {
      data.id = [1, 2, 3];
      data.trades_profit = event.target.checked;
    } else if (event.target.name == "disptradclosprof") {
      data.id = [1, 2, 3, 5];
      data.trades_closed = event.target.checked;
    } else if (event.target.name == "disptradbanner") {
      data.id = [1, 2, 3, 5];
      data.banner = event.target.checked;
    }

    API.callEndpoint(
      "PATCH",
      "Bearer",
      "/api/hedged-result-toggle/toggle",
      data
    )
      .then(async (response) => {
        this.setState({ showLoader: true });
        this.checkHedgedToggle(this.state.memType.id);
        this.getHomeBanners();
      })
      .catch((error) => {
        console.log(error.error);
      });
    // let newTrad = [...this.state.TradersTyp];
    // let getId = id - 1;
    // let checked = event.target.checked;
    // let chekval;
    // if (checked) {
    //   chekval = true;
    // } else {
    //   chekval = false;
    // }
    // newTrad[getId][event.target.name] = chekval;
    // this.setState({ TradersTyp: newTrad });
    // console.log(this.state.TradersTyp);
  };
  render() {
    let getRetval = this.state.TradersTyp.filter((item) => {
      // console.log("line 660: ", item.id, "   ...   ", this.state.memType?.id);
      return item.id === this.state.memType.id;
    });

    return (
      <>
        <div
          className={`customLoader ${
            this.state.showLoader == true ? "show d-block" : "d-none"
          }`}
        >
          <Loader className="loaderIcon"></Loader>
        </div>
        <h3 className="text-capitalize m-0 mb-lg-2 pb-lg-2">
          Homepage Banners{" "}
          <button className="border-dashed" onClick={this.openModal}>
            +
          </button>
        </h3>
        <section className={`hmpgbnr`}>
          {this.state.bannerinfo.length !== 0 ? (
            <Bannercomp
              deleteHomeBanner={this.deleteHomeBanner}
              bannerinfo={this.state.bannerinfo}
              state={this.state}
            />
          ) : (
            <p>No Banner found</p>
          )}
        </section>
        <section className={`resultblk mt-3`}>
          <section className={`memtypeblk mb-3`}>
            <h3 className="text-capitalize d-inline-block me-3">Results</h3>
            <Select
              value={this.state.memType}
              onChange={this.memberOnChange}
              options={membertype}
              placeholder="Select"
              isSearchable={false}
            />
          </section>
          <Filterresultcomp
            hedgedToggle={this.state.hedgedToggle}
            filerinfo={getRetval}
            displaytradeopt={this.displaytradeopt}
            state={this.state}
          />
        </section>
        {this.state.intradayon ? (
          " "
        ) : (
          <section className={`viewresultblk mt-3 mb-5`}>
            <h3 className="text-capitalize d-inline-block me-3">
              View Results Table
            </h3>
            <section className={`selectresultblk mt-4`}>
              <Select
                value={this.state.tradType}
                onChange={this.traderOnChange}
                options={tradertype}
                placeholder="Select Trader Type"
                classNamePrefix="trader"
                isSearchable={false}
              />
              <Select
                value={this.state.monType}
                onChange={this.monthOnChange}
                options={selectmonth}
                placeholder="Select Month"
                isSearchable={false}
              />
              <button onClick={this.getData}>Go</button>
            </section>
          </section>
        )}
        <section className={`tableblk`}>
          {this.state.resultsAPI.length >= 0 ? (
            <Tablecomp state={this.state} />
          ) : (
            ""
          )}
        </section>
        <Bannermodal
          state={this.state}
          show={this.state.showModal}
          onHide={this.hidemodal}
          addBanner={this.addBanner}
          handleUpload={this.handleUpload}
          setonStartDate={this.setonStartDate}
          setTiming={this.setTiming}
          getStatus={this.getStatus}
          getradioinfo={this.getradioinfo}
          stateinfo={this.state}
          modelConfirmBtnDisabled={this.modelConfirmBtnDisabled}
        />
      </>
    );
  }
}

export default Showcasedashboard;
