import React from "react";
import { NavLink } from "react-router-dom";
import * as API from "../../configuration/apiconfig";
import moment from "moment-timezone";
import Swal from "sweetalert2";
import Datatable from "react-bs-datatable";
import UpdatePlanModal from "../Usermanage/updatePlanModal";
import swal from "sweetalert";
import UpdateStatusModal from "./UpdateStatus";
const format1 = "DD/MM/YYYY hh:mm A";

class Calls extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newcall: null,
      planModal: false,
      updateModal: false,
      email: "",
      plans: [],
      plansData: [],
      deleted_calls: [],
      close_calls: [],
      close_intraday_call: [],
      request_callback: [],
      closeCall: false,
      CloseIntradayCall: false,
      data: null
    };
  }

  newCallFunction = (getTxt) => {
    this.setState({
      newcall: getTxt,
    });
  };

  getData = async () => {
    await API.callEndpoint("GET", "Bearer", `/api/requestcallback`)
      .then(async (response) => {
        console.log(response?.data);
        this.setState({
          request_callback: response?.data,
        });
      })
      .catch((error) => {
        console.log(error.error);
      });
  };

  componentDidMount = async () => {
    await this.getData();
  };

  handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
        API.callEndpoint("DELETE", "Bearer", `/api/calls/${id}`)
          .then(async (response) => {
            if (response.status === 200) {
              this.getData();
            }
          })
          .catch((error) => {
            console.log(error.error);
          });
      }
    });
  };

  deleteUser = async (userDetail) => {
    Swal.fire({
      title: "Delete Resuest ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        API.callEndpoint("DELETE", "Bearer", `/api/requestcallback`, {
          id: userDetail?.id,
        })
          .then(async (response) => {
            if (response.status === 200) {
              Swal.fire("Success!", "Deleted Successfully.", "success");
              this.getData();
            }
          })
          .catch((error) => {
            console.log(error.error);
          });
      }
    });
  };

  updatePlan = async (id, month) => {
    const data = {
      email: this.state.email,
      planid: id,
      month: month,
    };

    return await API.callEndpoint(
      "PATCH",
      "Bearer",
      `/api/admin/update-plan`,
      data
    )
      .then((response) => {
        this.setState({ planModal: false });
        this.getData();
      })
      .catch((error) => {
        console.log(error.error);
        swal({
          text: "An error occured, try again!",
          icon: "info",
        });
      });
  };
  updateStatus = async (status, textValue) => {
    const data = {
      id: this.state.selectedId,
      status: status,
      remark: textValue
    };

    console.log("data", data);

    return await API.callEndpoint(
      "PUT",
      "Bearer",
      `/api/requestcallback/updatecallback`,
      data
    )
      .then((response) => {
        this.setState({ updateModal: false });
        this.getData();
      })
      .catch((error) => {
        console.log(error.error);
        swal({
          text: "An error occured, try again!",
          icon: "info",
        });
      });
  };

  render() {
    return (
      <>
        <section className="admin-calls">
          <h1 className="head text-uppercase m-0">Request Call Back</h1>
          <div className="row mb-4">
            <div className="col-md-8 d-flex align-items-center"></div>
            <div className="col-md-4 text-end calls-dropdown">
              <div
                className="new-call-btn"
                style={{
                  width: 160,
                  marginLeft: 5,
                }}
              ></div>
            </div>
          </div>
          <section className="table-blk">
            <div className="tab-content" id="myTabContent">
              <div
                key={"request_callback"}
                className={"tab-pane active usermanage"}
                id={"request_callback"}
                role="tabpanel"
                aria-labelledby={`request_callback-tab`}
              >
                <Datatable
                  tableHeaders={[
                    {
                      title: "Created Date & Time",
                      prop: "createdAt",
                      cell: (row) => {
                        return (
                          <>
                            <p>
                              {moment
                                .utc(row.createdAt)
                                .utcOffset("-00:00")
                                .format(format1, "hh:mm A")}
                            </p>
                          </>
                        );
                      },
                    },
                    { title: "ID", prop: "id", sortable: true },
                    { title: "Name", prop: "name" },
                    { title: "Email", prop: "email" },
                    { title: "Mobile Number", prop: "phonenumber" },
                    { title: "City", prop: "city" },
                    {
                      title: "Status",
                      prop: "status",
                      cell: (request) => {
                        return (
                          <p>
                            {request.status === 0
                              ? "Open"
                              : request.status === 1
                                ? "Pending"
                                : request.status === 2
                                  ? "Closed"
                                  : ""}
                          </p>
                        );
                      },
                    },
                    { title: "Remark", prop: "remark" },
                    {
                      title: "Actions",
                      prop: "actions",
                      cell: (call) => {
                        return (
                          <>
                            <p>
                              <a
                                style={{ color: "#000" }}
                                onClick={() => {
                                  this.setState({
                                    email: call.email,
                                    planModal: true,
                                  });
                                }}
                              >
                                Update User
                              </a>
                              <a
                                style={{ color: "#000" }}
                                onClick={() => {
                                  this.setState({
                                    selectedId: call.id,
                                    updateModal: true,
                                    data: call
                                  });
                                }}
                              >
                                Update
                              </a>
                              <a
                                style={{ color: "#000" }}
                                onClick={() => this.deleteUser(call)}
                              >
                                Delete
                              </a>
                            </p>
                          </>
                        );
                      },
                    },
                  ]}
                  id="table-to-xls"
                  tableBody={this.state.request_callback}
                  labels={{
                    filterPlaceholder: "Search",
                  }}
                />
              </div>
            </div>
          </section>
        </section>
        <UpdatePlanModal
          updatePlan={this.updatePlan}
          show={this.state.planModal}
          onHide={() => {
            this.setState({
              planModal: false,
            });
          }}
          state={this.state}
        />
        <UpdateStatusModal
          updatePlan={this.updateStatus}
          show={this.state.updateModal}
          data={this.state.data}
          onHide={() => {
            this.setState({
              updateModal: false,
              data: null
            });
          }}
          state={this.state}
        />
      </>
    );
  }
}

export default Calls;
