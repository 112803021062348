import React, { Component } from "react";
import Eventable from "./eventable";
import Eventmodal from "./eventmodal";
import Moment from "moment";
import * as API from "../../configuration/apiconfig";
import moment from "moment";
import swal from "sweetalert";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const addmembertype = [
  // { value: "All", label: "All" },
  { value: 1, label: "Free" },
  { value: 2, label: "Prime" },
  { value: 3, label: "Crown" },
];

const categorytype = [
  { value: "Trading", label: "Trading" },
  { value: "Investment", label: "Investment" },
  { value: "Membership", label: "Membership" },
  { value: "2Hr Trader", label: "2Hr Trader" },
];
const VideoThumb = ({ vdeo }) => {
  return (
    <video
      width="100%"
      height="100%"
      autoPlay={true}
      muted={true}
      loop={true}
      className={`vidresponive`}
    >
      <source
        src={URL.createObjectURL(vdeo)}
        type="video/mp4"
        alt={vdeo.name}
        className={`img-responsive`}
      />
    </video>
  );
};
class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileinfo: "",
      fileName: "",
      vidinfo: "",
      modalstatus: false,
      fakeobj: [],
      eventlist: [],
      newEventList: [],
      evtmodal: {
        memType: "",
        catType: "",
        livmemtyp: "",
        vidsize: 20,
        alertbox: false,
        alertmsg: "",
        iscatmem: "",
      },
    };
  }
  componentDidMount = async () => {
    this.getEvent();
  };

  postEvent = async () => {
    let postForm = new FormData();
    postForm.append("name", this.state.fakeobj[0].title);
    postForm.append("description", this.state.fakeobj[0].desc);
    postForm.append("file", this.state.fakeobj[0].videourl);
    postForm.append("organiser", this.state.fakeobj[0].org);
    postForm.append("planid", this.state.fakeobj[0].membership);
    postForm.append(
      "event_date_time",
      moment(this.state.fakeobj[0].evttime).format("yyyy-MM-DD hh:mm:ss")
    );
    postForm.append(
      "countdown",
      moment(this.state.fakeobj[0].cnttim).format("yyyy-MM-DD hh:mm:ss")
    );
    postForm.append("link", this.state.fakeobj[0].evtlnk);
    await API.callFormDataEndpoint(
      "POST",
      "Bearer",
      `/api/event/create-event`,
      postForm
    )
      .then(async (response) => {
        console.log(response);
        this.getEvent();
      })
      .catch((error) => {
        console.log(error.error);
        swal({
          text: "An error occured, try again!",
          icon: "info",
        });
      });
  };
  getEvent = async () => {
    await API.callEndpoint("GET", "Bearer", `/api/event`)
      .then(async (response) => {
        this.setState({ newEventList: response.data });
      })
      .catch((error) => {
        console.log(error.error);
        swal({
          text: "An error occured, try again!",
          icon: "info",
        });
      });
  };
  removePic = () => {
    let getFileinfo = { ...this.state.fileinfo };
    this.setState({ fileinfo: "" });
  };
  showModal = () => {
    let curdate = new Date();

    let newobj = new Object();
    newobj.title = "";
    newobj.desc = "";
    newobj.org = "";
    newobj.memb = "";
    newobj.evtdt = "";
    newobj.evttime = "";
    newobj.cnttim = "";
    newobj.evtlnk = "";
    newobj.videourl = "";
    newobj.crtdt = Moment(curdate).format("Do MMM YYYY");
    let getobj = [...this.state.fakeobj, newobj];
    this.setState({ modalstatus: true, fakeobj: getobj });
    // console.log(this.state.eventlist);
  };
  hidemodal = () => {
    let getobj = [...this.state.fakeobj];
    getobj.pop();
    this.setState({ modalstatus: false, fakeobj: getobj, fileinfo: "" });
  };
  handlevidUpload = async (event) => {
    let getFileinfo = { ...this.state.fileinfo };
    getFileinfo = await getBase64(event.target.files[0]);
    this.setState({ fileinfo: getFileinfo });
  };
  formatByte = (bytes) => {
    var marker = 1024;
    var decimal = 0;
    var megaBytes = marker * marker;
    var gigaBytes = marker * marker * marker;
    if (bytes < gigaBytes) return (bytes / megaBytes).toFixed(decimal);
  };
  handleevtvidupload = async (event) => {
    this.setState({
      fileName: event.target.files[0].name,
    });
    let getObj = [...this.state.fakeobj];
    getObj[0].videourl = await getBase64(event.target.files[0]);
    this.setState({
      fakeobj: getObj,
      vidinfo: event.target.files[0],
    });
  };
  inputchange = (event) => {
    let getObj = [...this.state.fakeobj];
    getObj[0][event.target.name] = event.target.value;
    this.setState({
      fakeobj: getObj,
    });
    // console.log(getObj[0][event.target.name]);
  };
  memberOnChange = (memType) => {
    let getObj = [...this.state.fakeobj];
    getObj[0].membership = memType.value;
    this.setState({ fakeobj: getObj });
    // console.log(getObj[0].membership);
  };
  categoryOnChange = (catType) => {
    let getvidmodalState = { ...this.state.vidmodal };
    let getObj = [...this.state.fakeobj];
    let getcattype = catType.value;
    getObj[0].category = getcattype;
    if (getcattype === "Membership") {
      getvidmodalState.iscatmem = true;
    } else {
      getvidmodalState.iscatmem = false;
    }
    this.setState({ fakeobj: getObj, vidmodal: getvidmodalState });
    // console.log(getObj[0].category);
  };
  setDatefun = (date, vrname) => {
    // console.log(date);
    let getObj = [...this.state.fakeobj];
    getObj[0][vrname] = date;
    // console.log(getObj);
    this.setState({ fakeobj: getObj });
  };
  evetsave = () => {
    this.postEvent();
    let getfakobj = [...this.state.fakeobj];
    let getactobj = [...this.state.eventlist, getfakobj[0]];
    getfakobj.pop();
    this.setState({
      eventlist: getactobj,
      fakeobj: getfakobj,
      modalstatus: false,
      vidinfo: "",
    });
  };

  handleDeleteEvent = async (id) => {
    await API.callEndpoint("DELETE", "Bearer", `/api/event/${id}`)
      .then(async (response) => {
        if (response.status === 200) {
          this.getEvent();
        }
      })
      .catch((error) => {
        console.log(error.error);
        swal({
          text: "An error occured, try again!",
          icon: "info",
        });
      });
  };
  render() {
    return (
      <>
        <section className={`eventblk`}>
          <h1 className="head text-uppercase m-0 mt-lg-4 mb-lg-4 pt-lg-3 pb-lg-2">
            Events
          </h1>
          {/* <section className="row d-flex flex-row justify-content-center align-items-center">
            <section className="col-lg-8">
              <section className="row d-flex flex-row  justify-content-center align-items-center">
                <section className="col-lg-8">
                  <section className="dndblk">
                    <label className="my-4 text-center">
                      Drag and drop a file here or click browse
                    </label>
                    <input
                      type="file"
                      onChange={this.handlevidUpload}
                      name="myImage"
                      placeholder="Browse"
                      accept="image/x-png,image/gif,image/jpeg"
                      id="vidfilee"
                    />
                    <button className={`btnbrwose`}>Browse</button>
                    {this.state.fileinfo && (
                      <VideoThumb vdeo={this.state.fileinfo} />
                    )}
                  </section>
                </section>
                <section className="col-lg-1"></section>
                <section className="col-lg-3">
                  <aside className="d-block">
                    <button className="btn btn-brown" onClick={this.removePic}>
                      Edit
                    </button>
                  </aside>
                  <aside className="d-block mt-3">
                    <button className="btn btn-brown" onClick={this.removePic}>
                      Delete
                    </button>
                  </aside>
                </section>
              </section>
            </section>
          </section> */}
          <section className="row d-flex flex-row justify-content-end align-items-center">
            <aside className="col-lg-3 mt-3 d-flex flex-row justify-content-end align-items-center">
              <button className="btn btn-brown" onClick={this.showModal}>
                Create Event
              </button>
            </aside>
          </section>
          <Eventable
            state={this.state}
            handleDeleteEvent={this.handleDeleteEvent}
          />
        </section>
        <Eventmodal
          show={this.state.modalstatus}
          onHide={this.hidemodal}
          handlevidupload={this.handleevtvidupload}
          inputchange={this.inputchange}
          memberOnChange={this.memberOnChange}
          categoryOnChange={this.categoryOnChange}
          setDatefun={this.setDatefun}
          addmembertype={addmembertype}
          categorytype={categorytype}
          evetsave={this.evetsave}
          state={this.state}
        />
      </>
    );
  }
}

export default index;
