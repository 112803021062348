import React, { Component, useState } from 'react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { FaRegEdit } from 'react-icons/fa';
import * as API from '../../configuration/apiconfig';
import Form from 'react-bootstrap/Form';


const renameFile = (originalFile, newName) => {
  return new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
};
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const legsValue = [
  { value: 'BUY', label: 'BUY' },
  { value: 'SELL', label: 'SELL' },
];

const legsOption = [
  { value: 'CE', label: 'CE' },
  { value: 'PE', label: 'PE' },
  { value: 'FUTURES', label: 'FUTURES' },
];

const select2 = [
  { value: 'Very Expensive', label: 'Very Expensive' },
  { value: 'Expensive', label: 'Expensive' },
  { value: 'Fair', label: 'Fair' },
  { value: 'Cheap', label: 'Cheap' },
  { value: 'Very Cheap', label: 'Very Cheap' },
  { value: 'IV independent Strategy', label: 'IV independent Strategy' },
];

const stopLossTypeSelect = [
  { value: 'Value', label: 'Value' },
  { value: 'Price', label: 'Price' },
];

const DateCustomInput = ({ onClick, value }) => {
  //console.log(value, moment(value).format("Do MMM'YY"));
  return (
    <React.Fragment>
      <div className="form-group form-group1  ">
        <input
          value={value === '' ? '' : moment(value).format("Do MMM'YY")}
          className="legsItemWidth1 legs-series"
          onClick={onClick}
          placeholder="Select Date"
        ></input>
      </div>
    </React.Fragment>
  );
};

function DatePickerFunComp(props) {
  const [startDate, setStartDate] = useState(props.getStartDate);
  const ExampleCustomInput = React.forwardRef(({ value, onClick }, ref) => {
    return (
      <section className="custom-datepicker">
        <button className="example-custom-input" onClick={onClick} ref={ref}>
          Schedule
        </button>
        {props.optionSchedule === '' ? null : <strong>{value}</strong>}
      </section>
    );
  });

  const getStartDate = () => {
    let startDate = new Date();
    if (new Date().getHours() === 15 && new Date().getMinutes() > 30) {
      startDate.setDate(startDate.getDate() + 1);
    } else if (new Date().getHours() > 15) {
      startDate.setDate(startDate.getDate() + 1);
    }
    return startDate;
  };

  return (
    <DatePicker
      selected={startDate}
      minDate={getStartDate()}
      onChange={(date) => {
        setStartDate(date);
        props.optionToggleClass('optionSchedule', new Date(date));
      }}
      timeInputLabel="Time:"
      dateFormat="MM/dd/yyyy  h:mm aa"
      showTimeInput
      disabledKeyboardNavigation
      placeholderText="This has disabled keyboard navigation"
      customInput={<ExampleCustomInput />}
    />
  );
}
const getStartDate = () => {
  let startDate = new Date();
  if (new Date().getHours() === 15 && new Date().getMinutes() > 30) {
    startDate.setDate(startDate.getDate() + 1);
  } else if (new Date().getHours() > 15) {
    startDate.setDate(startDate.getDate() + 1);
  }
  return startDate;
};

class IntradayRightComp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuIsOpen: true,
    };
  }

  onImageChange = async (e) => {
    if (e?.target?.files && e?.target?.files[0]) {
      let img = e?.target?.files[0];
      let type = e?.target?.files[0]?.type;
      var srcss = await getBase64(e.target.files[0]);
      // console.log(srcss, "srcss");
      if (type == 'image/jpeg' || type == 'image/png' || type == 'image/jpg') {
        this.setState({
          imageValues: {
            loaded: true,
            source: e?.target?.files[0],
            url: srcss,
          },
        });
      } else {
        // setChangeImageError("Please Select Jpeg/Png/Jpg files only");
      }
    }
  };

  uploadPayOffChart = async (e) => {
    this.setState({
      uploadStatus: 1,
    });
    // try {
    //   let file = this.state.imageValues.source;

    //   const fileFormat = file.type.split('/')[1];
    //   const queryObj = {
    //     fileFormat,
    //     type: 'roadblock',
    //   };

    //   await API.callEndpoint(
    //     'GET',
    //     'Bearer',
    //     `/api/files/presigned?fileFormat=${queryObj.fileFormat}&type=${queryObj.type}`
    //   )
    //     .then((response) => {
    //       const { key, url } = response.data.data;
    //       const fileName = `${key}.${fileFormat}`;
    //       const renamedFile = renameFile(file, fileName);

    //       const myHeaders = new Headers({ 'Content-Type': 'image/*' });
    //       fetch(url, {
    //         method: 'PUT',
    //         headers: myHeaders,
    //         body: renamedFile,
    //       })
    //         .then(() => {
    //           API.callEndpoint(
    //             'GET',
    //             'Bearer',
    //             `/api/files/presigned-url?fileName=${fileName}&type=${queryObj.type}`
    //           )
    //             .then((response) => {
    //               console.log(response.data?.data.split('?X-')[0]);
    //               var raw = {
    //                 url: response.data?.data.split('?X-')[0],
    //               };
    //               API.callEndpoint(
    //                 'POST',
    //                 'Bearer',
    //                 `/api/road-block/update-image`,
    //                 raw
    //               )
    //                 .then((response) => {
    //                   this.setState({
    //                     uploadStatus: 2,
    //                   });
    //                   // swal({
    //                   //   text: "Updated Success",
    //                   //   icon: "success",
    //                   // });
    //                 })
    //                 .catch((error) => {
    //                   console.log(error.error);
    //                   // swal({
    //                   //   text: "An error occured, try again!",
    //                   //   icon: "info",
    //                   // });
    //                 });
    //               this.setState({
    //                 successMsg: 'Uploaded!',
    //                 errorMsg: '',
    //                 payOffChart: response.data?.data.split('?X-')[0],
    //               });
    //               this.props?.onPayChartEdit(
    //                 response.data?.data.split('?X-')[0]
    //               );
    //             })
    //             .catch((error) => {
    //               console.log(error.error);
    //               this.setState({
    //                 errorMsg: error.error,
    //                 successMsg: '',
    //               });
    //             });
    //         })
    //         .catch((error) => {
    //           console.log(error.error);
    //           this.setState({
    //             tradeErrorMsg: error.error,
    //             tradeSuccessMsg: '',
    //           });
    //         });
    //     })
    //     .catch((error) => {
    //       console.log(error.error);
    //       this.setState({ tradeErrorMsg: error.error, tradeSuccessMsg: '' });
    //     });
    // } catch (e) {
    //   console.log(e);
    //   this.setState({ tradeErrorMsg: e.error, tradeSuccessMsg: '' });
    // }
  };

  uploadFile = async (e) => {
    // try {
    //   let file = e.target.files[0];
    //   const fileFormat = file.type.split('/')[1];
    //   const queryObj = {
    //     fileFormat,
    //     type: 'tradelogics',
    //   };
    //   await API.callEndpoint(
    //     'GET',
    //     'Bearer',
    //     `/api/files/presigned?fileFormat=${queryObj.fileFormat}&type=${queryObj.type}`
    //   )
    //     .then((response) => {
    //       const { key, url } = response.data.data;
    //       const fileName = `${key}.${fileFormat}`;
    //       const renamedFile = renameFile(file, fileName);
    //       const myHeaders = new Headers({ 'Content-Type': 'image/*' });
    //       fetch(url, {
    //         method: 'PUT',
    //         headers: myHeaders,
    //         body: renamedFile,
    //       })
    //         .then(() => {
    //           API.callEndpoint(
    //             'GET',
    //             'Bearer',
    //             `/api/files/presigned-url?fileName=${fileName}&type=${queryObj.type}`
    //           )
    //             .then((response) => {
    //               this.setState({
    //                 tradeSuccessMsg: 'Uploaded!',
    //                 tradeErrorMsg: '',
    //                 tradeImageURL: response.data?.data.split('?X-')[0],
    //               });
    //             })
    //             .catch((error) => {
    //               console.log(error.error);
    //               this.setState({
    //                 tradeErrorMsg: error.error,
    //                 tradeSuccessMsg: '',
    //               });
    //             });
    //         })
    //         .catch((error) => {
    //           console.log(error.error);
    //           this.setState({
    //             tradeErrorMsg: error.error,
    //             tradeSuccessMsg: '',
    //           });
    //         });
    //     })
    //     .catch((error) => {
    //       console.log(error.error);
    //       this.setState({ tradeErrorMsg: error.error, tradeSuccessMsg: '' });
    //     });
    // } catch (e) {
    //   console.log(e);
    //   this.setState({ tradeErrorMsg: e.error, tradeSuccessMsg: '' });
    // }
  };

  render() {
    return (
      <section className="option-rgt-blk">
        <section className="add-legs-blk">
          <p>Add Legs</p>
          <aside
            className="plus-btn"
            onClick={(e) => this.props.optionAddBtn(e)}
          >
            <span>&nbsp;</span>
          </aside>
        </section>

        <section className="leg-blk">
          {this.props.state.legsArray.map((legs, index) => {
            // index = this.props.state.legsArray.length - (index + 1);
            return (
              <section
                className={`form-blk ${legs.exitToggle ? `gray-leg-bg ` : ``}`}
                key={index}
              >
                <h2 className={` ${legs.exitToggle ? `head-gray-bg` : ``}`}>
                  Leg {index + 1}
                </h2>
                <div
                  className={`form-group ps-0 colmb ${
                    legs.exitToggle ? `call-leg-grey` : ``
                  }`}
                >
                  <div
                    className={`call-field-header ${
                      legs.exitToggle ? `head-gray-bg` : ``
                    }`}
                  >
                    Action
                  </div>
                  <Select
                    isDisabled={legs.exitToggle}
                    className="legsItemWidth"
                    onChange={this.props.legsSelectOnchange(index, 'action')}
                    options={legsValue}
                    placeholder="Select"
                    value={legsValue.filter(
                      (item) => item.value === legs.action
                    )}
                    //menuIsOpen={this.state.menuIsOpen}
                  />
                </div>

                <div
                  className="form-group colmb"
                  style={{
                    width: '26%',
                  }}
                >
                  <div
                    className={`call-field-header ${
                      legs.exitToggle ? `head-gray-bg` : ``
                    } ${legs.legOption === 'FUTURES' && `head-gray-bg`}`}
                  >
                    Strike Price
                  </div>
                  <input
                    disabled={legs.exitToggle || legs.legOption === 'FUTURES'}
                    type="text"
                    className={`form-control legsItemWidth ${
                      legs.exitToggle
                        ? `legs-option-call-grey`
                        : `legs-option-call-black`
                    }`}
                    name="strike_price"
                    onChange={(e) => this.props.legFieldsOnchange(e, index)}
                    placeholder="Strike Price"
                    autoComplete="off"
                    value={legs.strike_price}
                  />
                </div>
                <div
                  className={`form-group  colmb ${
                    legs.exitToggle ? `call-leg-grey` : ``
                  }`}
                >
                  <div
                    className={`call-field-header ${
                      legs.exitToggle ? `head-gray-bg` : ``
                    }`}
                  >
                    Option
                  </div>
                  <Select
                    isDisabled={legs.exitToggle}
                    className="legsItemWidth"
                    onChange={this.props.legsSelectOptionOnchange(
                      index,
                      'legOption'
                    )}
                    options={legsOption}
                    placeholder="Select"
                    value={legsOption.filter(
                      (item) => item.value === legs.legOption
                    )}
                  />
                </div>
                <div
                  className={`form-group form-group1  colmb ${
                    legs.exitToggle
                      ? `legs-option-call-grey`
                      : `legs-option-call-black`
                  }`}
                >
                  <div
                    className={`call-field-header ${
                      legs.exitToggle ? `head-gray-bg ` : ``
                    }`}
                  >
                    Series
                  </div>
                  {/* <Select
                    className="legsItemWidth1 "
                    onChange={this.props.legsSelectOptionOnchange(
                      index,
                      "series"
                    )}
                   
                    options={this.props.listOfLastThursdays}
                    placeholder="Select"
                    value={this.props.listOfLastThursdays.filter(
                      (item) => item.value === legs.series
                    )}
                  /> */}
                  <DatePicker
                    readOnly={legs.exitToggle}
                    className="legsItemWidth1"
                    selected={legs.series}
                    showMonthDropdown
                    showYearDropdown
                    onChange={this.props.legsSelectOptionOnchange1(
                      index,
                      'series'
                    )}
                    customInput={<DateCustomInput toggle={legs.exitToggle} />}
                  />
                </div>
                {/* <div className="form-group colmb">
                  <div
                    className={`call-field-header ${
                      legs.exitToggle ? `head-gray-bg` : ``
                    }`}
                  >
                    Min Price
                  </div>
                  <input
                    disabled={legs.exitToggle}
                    type="text"
                    className={`form-control legsItemWidth ${
                      legs.exitToggle
                        ? `legs-option-call-grey`
                        : `legs-option-call-black`
                    }`}
                    name="entry_price_start"
                    onChange={(e) => this.props.legFieldsOnchange(e, index)}
                    placeholder="Min Price"
                    autoComplete="off"
                    value={legs.entry_price_start}
                  />
                </div> */}
                {/* <div className="form-group colmb">
                  <div
                    className={`call-field-header ${
                      legs.exitToggle ? `head-gray-bg` : ``
                    }`}
                  >
                    Max Price
                  </div>
                  <input
                    disabled={legs.exitToggle}
                    type="text"
                    className={`form-control legsItemWidth ${
                      legs.exitToggle
                        ? `legs-option-call-grey`
                        : `legs-option-call-black`
                    }`}
                    name="entry_price_end"
                    onChange={(e) => this.props.legFieldsOnchange(e, index)}
                    placeholder="Max Price"
                    autoComplete="off"
                    value={legs.entry_price_end}
                  />
                </div> */}

                {/* {this.props?.state?.optionsSelect == "Long call" ||
                this.props?.state?.optionsSelect == "Long Put" ? ( */}
                <div className="form-group colmb">
                  <div
                    className={`call-field-header ${
                      legs.exitToggle ? `head-gray-bg` : ``
                    }`}
                  >
                    Entry Price
                  </div>
                  <input
                    disabled={legs.exitToggle}
                    type="text"
                    className={`form-control legsItemWidth ${
                      legs.exitToggle
                        ? `legs-option-call-grey`
                        : `legs-option-call-black`
                    }`}
                    name="entry_price"
                    onChange={(e) => {
                      console.log('changed');

                      this.props.legFieldsOnchange(e, index);
                    }}
                    placeholder="Entry Price"
                    autoComplete="off"
                    value={legs.entry_price}
                  />
                </div>
                {/* ) : null} */}
                <div className="form-group  colmb">
                  <div
                    className={`call-field-header ${
                      legs.exitToggle ? `head-gray-bg` : ``
                    }`}
                  >
                    Quantity
                  </div>
                  <input
                    disabled={legs.exitToggle}
                    type="text"
                    className={`form-control legsItemWidth ${
                      legs.exitToggle
                        ? `legs-option-call-grey`
                        : `legs-option-call-black`
                    }`}
                    name="quantity"
                    onChange={(e) => this.props.legFieldsOnchange(e, index)}
                    placeholder="Quantity"
                    autoComplete="off"
                    value={legs.quantity}
                  />
                </div>
                <div className="form-group w-6 colmb">
                  <div
                    className="close-btn"
                    onClick={() => {
                      if (!legs.exitToggle) {
                        this.props.legDeleteClick(index);
                      }
                    }}
                  >
                    <span>&nbsp;</span>
                  </div>
                </div>
              </section>
            );
          })}
          {this.props?.state?.modifyHeader ? (
            <div className={`form-group exit-width colmb `}>
              <h6>Will show this legs on the trade updates</h6>
              <label className="switch">
                <input
                  type="checkbox"
                  name="show_legs"
                  onChange={(e) => {
                    this.props.changeshowlegs(e);
                  }}
                  checked={this.props?.state?.show_legs == 0 ? true : false}
                />
                <span className="slider"></span>
              </label>
            </div>
          ) : null}
        </section>

        <section className="call-participat-blk">
          <section className="list-blk">
            <h2>Participation Data</h2>
            <section className="participt-blk">
              <div className="form-group">
                <div className="call-field-header">Capital Required</div>
                <input
                  type="text"
                  className="form-control"
                  name="parCapitalRequied"
                  onChange={this.props.parDataOnChange}
                  placeholder="Capital Requied"
                  value={this.props.state.parCapitalRequied}
                />
              </div>
              {this.props?.state?.optionsSelect == 'Bear Call' ||
              this.props?.state?.optionsSelect == 'Bull Put' ? (
                <div className="form-group">
                  <div className="call-field-header">Max Profit</div>
                  <input
                    type="text"
                    className="form-control"
                    name="parMaxProfit"
                    onChange={this.props.parDataOnChange}
                    placeholder="Max Profit"
                    value={this.props.state.parMaxProfit}
                  />
                </div>
              ) : null}

              {this.props?.state?.optionsSelect == 'Bear Call' ||
              this.props?.state?.optionsSelect == 'Bull Put' ? (
                <div className="form-group">
                  <div className="call-field-header">Max Loss</div>
                  <input
                    type="text"
                    className="form-control"
                    name="parMaxLoss"
                    onChange={this.props.parDataOnChange}
                    placeholder="Max Loss"
                    value={this.props.state.parMaxLoss}
                  />
                </div>
              ) : null}

              <div className="form-group">
                <div className="call-field-header">Stop Loss</div>
                <input
                  type="text"
                  className="form-control"
                  name="parStopLoss"
                  onChange={this.props.parDataOnChange}
                  placeholder="Stop Loss"
                  value={this.props.state.parStopLoss}
                />
              </div>
              {this.props?.state?.optionsSelect == 'Long call' ||
              this.props?.state?.optionsSelect == 'Long Put' ? (
                <div className="form-group">
                  <div className="call-field-header">Target</div>
                  <input
                    type="text"
                    className="form-control"
                    name="target_price"
                    onChange={this.props.parDataOnChange}
                    placeholder="Target"
                    value={this.props.state.target_price}
                  />
                </div>
              ) : null}

              {/* <div className="form-group">
                <div className="call-field-header">Expected Profit</div>
                <input
                  type="text"
                  className="form-control"
                  name="parExpectedProfit"
                  onChange={this.props.parDataOnChange}
                  placeholder="Expected Profit"
                  value={this.props.state.parExpectedProfit}
                />
              </div> */}
              {/* <div className="form-group">
                <div className="call-field-header">Stop Loss Type</div>
                <Select
                  onChange={this.props.parSelectOnChange}
                  options={stopLossTypeSelect}
                  placeholder="Stop Loss Type"
                  value={stopLossTypeSelect.filter(
                    (item) => item.value === this.props.state.parSelectValue
                  )}
                  //menuIsOpen={this.state.menuIsOpen}
                />
              </div> */}

              <div className="form-group">
                <div className="call-field-header">Lot Size</div>
                <input
                  type="text"
                  className="form-control"
                  name="parLotSize"
                  onChange={this.props.parDataOnChange}
                  placeholder="Lot Size"
                  value={this.props.state.parLotSize}
                />
              </div>
            </section>

            {/* <section className="list-blk d-block ">
              <h2>Trade Logic </h2>
              <ul
                className="list-group list-group-horizontal"
                style={{ display: 'flex', flexWrap: 'wrap', gap: '1rem' }}
              >
                <li
                  className={`list-group-item border-0 px-2 py-0 ${
                    this.props.state.tradelogic === 1 ? 'active' : ''
                  }`}
                >
                  <span
                    onClick={() => this.props.optionsTradeLogic(1)}
                    className="d-block text-center"
                  >
                    Option 1
                  </span>
                </li>
                <li
                  className={`list-group-item border-0 px-2 py-0 ${
                    this.props.state.tradelogic === 2 ? 'active' : ''
                  }`}
                >
                  <span
                    onClick={() => this.props.optionsTradeLogic(2)}
                    className="d-block text-center"
                  >
                    Option 2
                  </span>
                </li>
                <li
                  className={`list-group-item border-0 px-2 py-0 ${
                    this.props.state.tradelogic === 3 ? 'active' : ''
                  }`}
                >
                  <span
                    onClick={() => this.props.optionsTradeLogic(3)}
                    className="d-block text-center"
                  >
                    Option 3
                  </span>
                </li>
                <li
                  className={`list-group-item border-0 px-2 py-0 ${
                    this.props.state.tradelogic === 4 ? 'active' : ''
                  }`}
                >
                  <span
                    onClick={() => this.props.optionsTradeLogic(4)}
                    className="d-block text-center"
                  >
                    Option 4
                  </span>
                </li>
              </ul>

              <h2 style={{ marginTop:20}}>Trade Logic URL </h2>
              <img src= {this.props.state.tradeLogicUrl}  style={{
                width:"100%"
              }}/>
              <h2 style={{ marginTop:20}}>Trade Logic Notes</h2>
              <p>
              {this.props.state.tradeLogicNotes} 
              </p>
            </section> */}
          </section>
             <section className="list-blk">
            <section className="participt-blk">
              {/* <h2>Notify User</h2> */}
              <Form.Check
                onChange={(event) => {
                  this.props.updateNotify(event.target.checked);
                  // console.log(event.target.checked, 'check value')
                }}
                label="Notify User"
                name="group1"
                type={"checkbox"}
                checked={this.props.state.notify}
                id={`reverse-checkbox-1`}
                className="notifycheck"
              />

            </section>

          </section>
      </section>
        </section>

     
    );
  }
}

export default IntradayRightComp;
