import React, { Component } from "react";
import "font-awesome/css/font-awesome.min.css";
import Swal from "sweetalert2";
import moment from "moment";

export class Bannercomp extends Component {
  constructor(props) {
    super(props);
  }
  confirmDelete = (videoId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Click on 'Yes' to delete the banner",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        this.props.deleteHomeBanner(videoId);
      }
    });
  };
  render() {
    return (
      <>
        {this.props.bannerinfo
          .sort(function (a, b) {
            if (a.updatedAt < b.updatedAt) {
              return 1;
            }
            if (a.updatedAt > b.updatedAt) {
              return -1;
            }
            return 0;
          })
          .map((bnrifo) => {
            if (bnrifo !== undefined && bnrifo.end_date !== undefined) {
              let date = bnrifo.end_date;
              let a = date.split("T");
              let b = a[0].split("-").reverse().join("-");
              let c = a[1].split(":");
              let d = "am";
              if (c[0] > 12) {
                d = "pm";
                c[0] -= 12;
              }
              let e = b + " | " + c[0] + ":" + c[1] + " " + d;
              return (
                <div>
                  <figure>
                    <img
                      data-id={bnrifo.id}
                      src={bnrifo.imageurl}
                      alt={bnrifo.name}
                      className={`img-responsive`}
                    />
                  </figure>
                  <div className="targetPlan">
                    <p className="targetPlanParagraph">
                      Target :{" "}
                      <b>
                        {bnrifo.call_target == "" ? "All" : bnrifo.call_target}
                      </b>
                    </p>
                    <p className="targetPlanParagraph">
                      Plan :{" "}
                      <b>
                        {bnrifo.planid == 1
                          ? "Free"
                          : bnrifo.planid == 2
                          ? "Prime"
                          : "Crown"}
                      </b>
                    </p>
                  </div>
                  <p className="displayTill">
                    <span>
                      Display till:{" "}
                      <b>{bnrifo.end_date === null ? "Forever" : e}</b>
                    </span>
                    <i
                      class="fa fa-trash-o"
                      onClick={() => this.confirmDelete(bnrifo.id)}
                    ></i>
                  </p>
                </div>
              );
            }
          })}
      </>
    );
  }
}

export default Bannercomp;
