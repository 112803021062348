import React, { Component } from 'react';
import Showcasedashboard from './showcasedashboard';

class index extends Component {
    render() {
        return (
            <section className={`showcaseblk`}>
                <h1 className="head text-uppercase m-0 mt-lg-4 mb-lg-4 pt-lg-3 pb-lg-2">Showcase</h1>
                <Showcasedashboard />
            </section>
        )
    }
}

export default index
