import React, { Component } from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    NavLink,
  } from "react-router-dom";
import * as API from "../../configuration/apiconfig";
// import moment from "moment";
import moment from "moment-timezone";

import Datatable from "react-bs-datatable";
import Swal from "sweetalert2";
import swal from "sweetalert";






export class adminList extends Component {
    constructor(props) {
      super(props)
      this.state = {
        adminList: [],
      rowsPerPage: 5,
        header : [
            { title: "Id", prop: "id" },
            { title: "Name", prop: "name" },
            { title: "Email", prop: "email" },
            { title: "Mobile No.", prop: "phonenumber" },
            { title: "Type", prop: "type" },
            { title: "Created on", prop: "createdAt" ,
            cell: (row) => {
              return (
                <>
                  <p>
                    {moment(row.createdAt)
                     .utcOffset("-00:00")
                     .format("DD MMM'YY | h:mm A")}
                  </p>
                </>
              );
            }},
            { title: "Updated on", prop: "updatedAt",
            cell: (row) => {
              return (
                <>
                  <p>
                    {moment(row.updatedAt)
                     .utcOffset("-00:00")
                     .format("DD MMM'YY | h:mm A")}
                  </p>
                </>
              );
            } },
            {
              prop: "actions",
              title: "Action",
              cell: (row) => {
                return (
                    <>
                    <a onClick={() => props.history.push(`/admin/update?id=${row.id}`)}>
                      Update
                    </a>
                    <a onClick={() => this.handleDelete(row.id)}>
                      Delete
                    </a>
                    </>
                );
              }
            },
          ]
      }
    }

    handleDelete= async (id)=>{
        Swal.fire({
          title: "Are you sure?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire("Deleted!", "Your file has been deleted.", "success");
             API.callEndpoint(
              "DELETE",
              "Bearer",
              `/api/admin/${id}`
            )
              .then(async (response) => {
                if(response.status === 200){
                  this.fetchcall()
                }
              })
              .catch((error) => {
                console.log(error.error);
                swal({
                  text: "An error occured, try again!",
                  icon: "info",
                });
              });
          }
        });
       
      }
    componentDidMount = async () => {
        this.fetchcall();
      };
    fetchcall = async () => {
    return await API.callEndpoint(
        "GET",
        "Bearer",
        `/api/admin/all-admins`
    )
        .then((response) => {
        let fetchData = response.data.data;
        this.setState({ 
            adminList: fetchData,
        });
        })
        .catch((error) => {
        console.log(error.error);
        swal({
          text: "An error occured, try again!",
          icon: "info",
        });
        });
    };


  render() {
    return (
        <section className="admin-bundleTable-page">
        <h1 className="head text-uppercase m-0">Admin List</h1>
        <aside className="add-bundle">
          <h2>
            <NavLink exact activeClassName="active" to={"/admin/create"}>
              Create New Admin
            </NavLink>
          </h2>
        </aside>
        <section className={`usermanage`}>
            <section className={`contblk`}>
                <section className={`row m-0 p-0 mt-1`}>
                    <aside className={`col-12 m-0 p-0`}>
                    <span className="display-rows-placement">
                        Display rows :{" "}
                        <select
                        className="display-select"
                        onChange={(e) => {
                            if( 
                            this.state.adminList.length === 0){
                                this.setState({
                                rowsPerPage: 1,
                                });
                            }
                            else if (
                            e.target.value === "All"
                            ) {
                            this.setState({
                                rowsPerPage: this.state.adminList.length,
                            });
                            } else {
                            this.setState({ rowsPerPage: e.target.value });
                            }
                        }}
                        >
                        {[5, 10, 15, 20, "All"].map((item) => {
                            return <option value={item}>{item}</option>;
                        })}
                        </select>
                    </span>
                    <Datatable
                        tableHeaders={this.state.header}
                        id="table-to-xls"
                        tableBody={this.state.adminList}
                        rowsPerPage={this.state.rowsPerPage}
                        labels={{
                        filterPlaceholder: "Search",
                        }}
                    />
                    </aside>
                </section>
            </section>
        </section>
      </section>
    )
  }
}

export default adminList