import React from "react";
import * as API from "../../configuration/apiconfig";
import { NavLink, Link } from "react-router-dom";
import moment from "moment";
import { createCall, updateCall } from "./ApiCalls";
import { withRouter } from "react-router-dom";

const dateformat = "DD/MM/YYYY, hh:mm A";

class CallDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      totalHistory: "",
      callDetailsFullAPI: {},
      callDetailsAPI: [],
      modifiedText: "",
    };
  }
  fetchCall = async () => {
    let path = this.props.props.location.search;
    let id = parseInt(path.slice(path.indexOf("id=") + 3));
    // var id = this.props.props.location.pathname?.split("=")?.[1];
    // console.log("fetchCallID",id);
    return await API.callEndpoint(
      "GET",
      "Bearer",
      `/api/calls?id=${id}&childtables=history`
    )
      .then((response) => {
        return response.data[0];
      })
      .catch((error) => {
        console.log(error.error);
      });
  };

  componentDidMount = async () => {
    await this.getCallDetailsAPI();
  };

  getCallDetailsAPI = async () => {
    let callDetailsFullAPI = this.props.props.location.callDetails;
    if (callDetailsFullAPI === undefined) {
      callDetailsFullAPI = await this.fetchCall();
    }
    var getId = callDetailsFullAPI.id;

    await API.callEndpoint("GET", "Bearer", `/api/calls/${getId}/history`)
      .then((response) => {
        this.setState({
          callDetailsAPI: response.data,
        });
        if (response.data.length > 0) {
          this.setState({
            callDetailsFullAPI,
            totalHistory: response.data.length - 1,
          });
        }
      })
      .catch((error) => {
        console.log(error.error);
      });
  };

  render() {
    return (
      <section className="admin-call-details">
        <section className="call-inner">
          <section
            className={`equity-blk ${this.state.callDetailsFullAPI?.call_class == "Equity"
              ? "d-bock"
              : "d-none"
              }`}
          >
            <aside className="subhead">
              <h2>{this.state.callDetailsFullAPI?.scripname}</h2>
              <ul>
                <li>{this.state.callDetailsFullAPI?.call_class}</li>
                <li>{this.state.callDetailsFullAPI?.call_type}</li>
                <li>All</li>
                <li>Modified x {this.state.totalHistory}</li>
              </ul>
            </aside>

            {this.state.callDetailsAPI.map((item, index) => {
              if (this.state.callDetailsAPI.length === index + 1) {
                var equityModify = "Call made on";
              } else {
                var equityModify = "Call modified on";
              }
              if (item.type == "Equity") {
                return (
                  <section className="calldetails-inner" key={index}>
                    <section className="box-blk">
                      <aside className="box">
                        <h3>
                          {equityModify}{" "}
                          {moment(item.createdAt)
                            .utcOffset("-00:00")
                            .format("DD MMM'YY | h:mm A")}
                        </h3>
                        <aside className="inner-box">
                          {/* <p>Max Profit - Rs. {item.info.maximum_profit}</p>
                          <p>Quantity - {item.info.quanity}</p> */}
                          <ul>
                            <li>
                              Entry price
                              <span>Rs. {item.info.entry_price_start}</span>
                            </li>
                            <li>
                              Exit price <span>Rs. {item.info.exit_price}</span>
                            </li>
                            <li>
                              Stop Loss <span>Rs. {item.info.stop_loss}</span>
                            </li>
                            <li
                              className={`${item.notes == null
                                ? "d-none"
                                : "call-details-notes "
                                }`}
                            >
                              <strong>Notes:</strong>
                              <p>{item.notes}</p>
                            </li>
                          </ul>
                        </aside>
                      </aside>
                    </section>
                  </section>
                );
              }
            })}
          </section>

          <section
            className={`options-blk ${this.state.callDetailsFullAPI?.call_class == "Options"
              ? "d-block"
              : "d-none"
              }`}
          >
            <aside className="subhead">
              <h2>{this.state.callDetailsFullAPI?.scripname}</h2>
              <ul>
                <li>{this.state.callDetailsFullAPI?.call_class}</li>
                <li>{this.state.callDetailsFullAPI?.call_type}</li>
                <li>All</li>
                <li>Modified x {this.state.totalHistory}</li>
              </ul>
            </aside>
            {this.state.callDetailsAPI.map((item, index) => {
              if (this.state.callDetailsAPI.length === index + 1) {
                var optionsModify = "Call made on";
              } else {
                var optionsModify = "Call modified on";
              }
              if (item.type == "Options") {
                return (
                  <section className="calldetails-inner" key={index}>
                    <section className="box-blk options-blk">
                      <aside className="box">
                        {item.createdAt == null ? (
                          ""
                        ) : (
                          <h3>
                            {optionsModify}{" "}
                            {moment(item.createdAt)
                              .utcOffset("-00:00")
                              .format("DD MMM'YY | h:mm A")}
                          </h3>
                        )}
                        <table>
                          <tr>
                            <th></th>
                            <th className="table-th-exit">Exit Price</th>
                          </tr>
                          <tr>
                            <td>
                              <aside className="inner-box">
                                <table>
                                  {item.info.legs.map((subItem, index) => {
                                    // console.log("subItem",subItem)
                                    return (
                                      <tr key={index}>
                                        <td className="td-action">
                                          {subItem.action}
                                        </td>

                                        <td className="td-series">
                                          {" "}
                                          {subItem.series}
                                        </td>
                                        <td> {subItem.strike_price}</td>

                                        <td className="td-center">
                                          {subItem.action == "BUY" ||
                                            subItem.action == "Buy" ||
                                            subItem.action == "buy"
                                            ? `+${subItem.quantity}`
                                            : subItem.action == "SELL" ||
                                              subItem.action == "Sell" ||
                                              subItem.action == "sell"
                                              ? `-${subItem.quantity}`
                                              : subItem.action == ""
                                                ? subItem.quantity
                                                : ""}
                                        </td>
                                        <td className="td-center1">
                                          &#8377; {subItem.entry_price_start}
                                        </td>

                                      </tr>
                                    );
                                  })}
                                </table>
                                <aside
                                  className={`call-details-notes  ${item.notes == null ? "d-none" : ""
                                    }`}
                                >
                                  <p>
                                    <strong>Notes:</strong>
                                    {item.notes}
                                  </p>
                                </aside>
                              </aside>
                            </td>
                            <td>
                              <table className="table-td">
                                {item.info.legs.map((subItem, index) => {
                                  return (
                                    <tr key={index}>
                                      <td className="td-action1">
                                        {subItem.exit_price === undefined ||
                                          subItem.exit_price === "" ? (
                                          <span>&nbsp;</span>
                                        ) : (
                                          subItem.exit_price
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </table>
                            </td>
                          </tr>
                        </table>
                      </aside>
                    </section>
                  </section>
                );
              }
            })}
          </section>

          <aside
            className={`modify-edit-btn ${this.props.state.closeCall ? "d-none" : "d-block"
              }`}
          >
            {/* <NavLink exact to={"/newcall/modify_equity"}>
              &nbsp;
            </NavLink> */}
            {this.state.callDetailsFullAPI?.call_status !== undefined &&
              this.state.callDetailsFullAPI?.call_status !== "closed" ? (
              <NavLink
                exact
                // to={{
                //   pathname: "/newcall/modify_equity",
                //   callDetails: call,
                // }}
                to={`/newcall/${this.state.callDetailsFullAPI?.call_class === "Equity"
                  ? "modify_equity"
                  : "modify_options"
                  }?id=${this.state.callDetailsFullAPI?.id}`}
              >
                <i class="fa fa-pencil-square-o fs-2"></i>
              </NavLink>
            ) : null}
          </aside>
        </section>
      </section>
    );
  }
}

export default CallDetails;
