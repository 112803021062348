import React from "react";

const Filterresultcomp = (props) => {
  return (
    <>
      {props.state.resultsAPI.map((item, index) => {
        return (
          <section className={`memcontblk pe-5`} key={index}>
            <section className={`lftblk`}>
              <aside className={`blk pe-5`}>
                {item?.hedgedresults?.hitrate != undefined ? (
                  <aside className={`innercont`}>
                    <h4>
                      Hit Rate{" "}
                      <span>{item?.hedgedresults?.hitrate?.toFixed(2)}</span>
                    </h4>
                  </aside>
                ) : (
                  ""
                )}
                <aside className={`formblk mt-3 text-center`}>
                  <label htmlFor="dispone">Display</label>
                  <input
                    type="checkbox"
                    value="Display"
                    name="HOCdisplay"
                    checked={props.hedgedToggle.hit_rate}
                    id="dispone"
                    onChange={(e) => props.displaytradeopt(e, item.id)}
                  />
                </aside>
              </aside>
              <aside className={`blk pe-5`}>
                <aside className={`innercont`}>
                  <h4>
                    YTD ROC <span>{item.hedgedresults.YTDRoc.toFixed(2)}</span>
                  </h4>
                </aside>
                <aside className={`formblk mt-3 text-center`}>
                  <label htmlFor="disptwo">Display</label>
                  <input
                    type="checkbox"
                    value="Display"
                    name="YTDdisplay"
                    checked={props.hedgedToggle.ytd_roc}
                    id="disptwo"
                    onChange={(e) => props.displaytradeopt(e, item.id)}
                  />
                </aside>
              </aside>
            </section>
            <section className={`rytblk mb-4`}>
              <section className={`innercont`}>
                {/* <aside>
                  <p className="my-3">
                    <span className={`ps-2`}>No. of trades</span>{" "}
                    <span className="text-center">
                      {item.hedgedresults.No_of_trades_give}
                    </span>{" "}
                    <i>
                      <label htmlFor="dispthree">Display</label>
                      <input
                        type="checkbox"
                        value="Display"
                        name="dispnooftrd"
                        checked={props.hedgedToggle.trade_numbers}
                        id="dispthree"
                        onChange={(e) => props.displaytradeopt(e, item.id)}
                      />
                    </i>
                  </p>
                </aside>
                <aside>
                  <p className="my-3">
                    <span className={`ps-2`}>Trades for profit</span>{" "}
                    <span className="text-center">
                      {item.hedgedresults.trades_closed_profit}
                    </span>{" "}
                    <i>
                      <label htmlFor="dispfour">Display</label>
                      <input
                        type="checkbox"
                        value="Display"
                        name="disptradprofit"
                        checked={props.hedgedToggle.trades_profit}
                        id="dispfour"
                        onChange={(e) => props.displaytradeopt(e, item.id)}
                      />
                    </i>
                  </p>
                </aside>
                <aside>
                  <p className="my-3">
                    <span className={`ps-2`}>Trades closed for profit</span>{" "}
                    <span className="text-center">
                      {item.hedgedresults.trades_closed_loss}
                    </span>{" "}
                    <i>
                      <label htmlFor="dispfive">Display</label>
                      <input
                        type="checkbox"
                        value="Display"
                        name="disptradclosprof"
                        checked={props.hedgedToggle.trades_closed}
                        id="dispfive"
                        onChange={(e) => props.displaytradeopt(e, item.id)}
                      />
                    </i>
                  </p>
                </aside> */}

                <aside>
                  <p className="my-3">
                    <span className={`ps-2`}>Trades Banner</span>{" "}
                    <span className="text-center"></span>{" "}
                    <i>
                      <label htmlFor="dispsix">Display</label>
                      <input
                        type="checkbox"
                        value="Display"
                        name="disptradbanner"
                        checked={props.hedgedToggle.banner}
                        id="dispsix"
                        onChange={(e) => props.displaytradeopt(e, item.id)}
                      />
                    </i>
                  </p>
                </aside>
              </section>
            </section>
          </section>
        );
      })}
    </>
  );
};

export default Filterresultcomp;
