import React from "react";
import Vimeo from "@u-wave/react-vimeo";
import moment from "moment";
import { DateTimeFormatISO } from "../dateTimeFormat/DateTimeFormat";
import "font-awesome/css/font-awesome.min.css";
import Card from "@mui/material/Card";

const dateTimeFormat1 = "DD MMM'YY";
const Memcontblk = (props) => {
  let getMemVal = props.state.navList.filter((getnam) => {
    return getnam.tabname === "Membership";
  });
  let getlivVal = props.state.navList.filter((getnam) => {
    return getnam.tabname === "Live Video";
  });

  let tradeValue = props.state.navList.filter((getnam) => {
    return getnam.tabname === "Trading";
  });
  let tutorialValue = props.state.navList.filter((getnam) => {
    return getnam.tabname === "Hedged tutorials";
  });

  let investmentValue = props.state.navList.filter((getnam) => {
    return getnam.tabname === "Investment";
  });

  let membershipValue = props.state.navList.filter((getnam) => {
    return getnam.tabname === "Membership";
  });
  let two_hr = props.state.navList.filter((getnam) => {
    return getnam.tabname === "2Hr Trader";
  });
  let liveFromHedgedValue = props.state.navList.filter((getnam) => {
    return getnam.tabname === "Live from hedged";
  });

  let aosTradervideos = props.state.navList.filter((getnam) => {
    return getnam.tabname === "Ace Of Spades";
  });

  // let setcont;
  // if (props.tabname == "Membership") {
  //   setcont = props.state.videolist[props.tabname].map((info) => {
  //     return (
  //       <Meminnercont
  //         key={info.id}
  //         edititem={props.edititem}
  //         continfo={info}
  //         state={props.state}
  //       />
  //     );
  //   });
  // } else {
  //   if (props.memtabname === "Free") {
  //     // setcont = props.state.videolist[props.tabname].map((info) => {
  //       // return (
  //       //   <Meminnercont
  //       //     key={info.id}
  //       //     edititem={props.edititem}
  //       //     continfo={info}
  //       //     state={props.state}
  //       //   />
  //       // );
  //     // });
  //   }
  //   else {
  //     let settcont = props.state.videolist[props.tabname].map((info) => {
  //       return info;
  //     });

  //     let getcont = settcont.filter((data) => {
  //       return data.subnav === props.memtabname;
  //     });
  //     setcont = getcont.map((info) => {
  //       return (
  //         <Meminnercont
  //           key={info.id}
  //           edititem={props.edititem}
  //           continfo={info}
  //           state={props.state}
  //         />
  //       );
  //     });
  //   }
  // }

  if (tradeValue[0].navStatus) {
    return (
      <section className="trading-tab-blk">
        <section className="trader-inner">
          <section className="memcontblk">
            <section className="row m-0 p-0">
              {props.state.videoListAPIData
                .filter((item) => {
                  return item.islive == false && item.category == "Trading";
                })
                .sort(function (a, b) {
                  if (a.sortorder < b.sortorder) {
                    return -1;
                  }
                  if (a.sortorder > b.sortorder) {
                    return 1;
                  }
                  return 0;
                })
                .map((videoData, Tradeindex) => {
                  if (
                    videoData.islive == false &&
                    videoData.category == "Trading"
                  ) {
                    // console.log(
                    //   videoData.id,
                    //   videoData.topic,
                    //   videoData.sortorder
                    // );
                    return (
                      <section
                        className="video-dashboard-width p-0 pe-3 mb-3"
                        key={Tradeindex}
                      >
                        <Card className="video-card">
                          <aside className="vidfile position-relative mb-2">
                            <Vimeo video={videoData.url} />
                          </aside>
                          <aside className="vidfileinfo position-relative mb-2">
                            <h2 className="pe-5">
                              {videoData.topic}
                              <span className="d-block video-description">
                                {videoData.description}
                              </span>
                            </h2>
                            <p>
                              <strong>CreatedAt:</strong>{" "}
                              {videoData.createdAt !== null
                                ? moment(videoData.createdAt).format(
                                  dateTimeFormat1
                                ) +
                                " | " +
                                DateTimeFormatISO(
                                  videoData.createdAt,
                                  "TimeDefault"
                                )
                                : null}
                            </p>
                            <div className="">
                              {videoData.isFeature == true ? (
                                <span
                                  className="featured-btn active-btn"
                                  onClick={(e) =>
                                    props.featuredBtnActive(videoData.id)
                                  }
                                >
                                  &nbsp;
                                </span>
                              ) : (
                                <span
                                  className="featured-btn"
                                  onClick={(e) =>
                                    props.featuredBtn(videoData.id)
                                  }
                                >
                                  &nbsp;
                                </span>
                              )}
                              <span
                                className="position-absolute top-0 d-inline-block"
                                style={{
                                  height: "15px",
                                  right: "35px",
                                  cursor: "pointer",
                                }}
                                onClick={(e) =>
                                  props.videoEditBtn(videoData.id)
                                }
                              >
                                <i class="fa fa-pencil-square-o fs-5"></i>
                              </span>
                              <span
                                className="position-absolute top-0"
                                style={{ cursor: "pointer", right: "10px" }}
                                onClick={(e) =>
                                  props.videoDeleteBtn(videoData.id)
                                }
                              >
                                <i class="fa fa-trash-o fs-5"></i>
                              </span>
                            </div>
                          </aside>
                        </Card>
                      </section>
                    );
                  }
                })}
            </section>
          </section>
        </section>
      </section>
    );
  }

  if (investmentValue[0].navStatus) {
    return (
      <section className="invesment-tab-blk">
        <section className="invesment-inner">
          <section className="memcontblk">
            <section className="row m-0 p-0">
              {props.state.videoListAPIData
                .filter((item) => {
                  return item.islive == false && item.category == "Investment";
                })
                .sort(function (a, b) {
                  if (a.sortorder < b.sortorder) {
                    return -1;
                  }
                  if (a.sortorder > b.sortorder) {
                    return 1;
                  }
                  return 0;
                })
                .map((videoData, investindex) => {
                  if (
                    videoData.islive == false &&
                    videoData.category == "Investment"
                  ) {
                    console.log(
                      videoData.id,
                      videoData.topic,
                      videoData.sortorder
                    );
                    return (
                      <section
                        className="video-dashboard-width p-0 pe-3 mb-3"
                        key={investindex}
                      >
                        <Card className="video-card">
                          <aside className="vidfile position-relative mb-2">
                            <Vimeo video={videoData.url} />
                          </aside>
                          <aside className="vidfileinfo position-relative">
                            <h2 className="pe-5">
                              {videoData.topic}
                              <span className="d-block video-description">
                                {videoData.description}
                              </span>
                            </h2>
                            <p>
                              <strong>CreatedAt:</strong>{" "}
                              {videoData.createdAt === null
                                ? ""
                                : moment(videoData.createdAt).format(
                                  dateTimeFormat1
                                ) +
                                " | " +
                                DateTimeFormatISO(
                                  videoData.createdAt,
                                  "TimeDefault"
                                )}
                            </p>
                            {videoData.isFeature == true ? (
                              <span
                                className="featured-btn active-btn"
                                onClick={(e) =>
                                  props.featuredBtnActive(videoData.id)
                                }
                              >
                                &nbsp;
                              </span>
                            ) : (
                              <span
                                className="featured-btn"
                                onClick={(e) => props.featuredBtn(videoData.id)}
                              >
                                &nbsp;
                              </span>
                            )}
                            <span
                              className="position-absolute top-0 d-inline-block"
                              style={{
                                height: "15px",
                                right: "35px",
                                cursor: "pointer",
                              }}
                              onClick={(e) => props.videoEditBtn(videoData.id)}
                            >
                              <i class="fa fa-pencil-square-o fs-5"></i>
                            </span>
                            <span
                              className="position-absolute top-0"
                              style={{ cursor: "pointer", right: "10px" }}
                              onClick={(e) =>
                                props.videoDeleteBtn(videoData.id)
                              }
                            >
                              <i class="fa fa-trash-o fs-5"></i>
                            </span>
                          </aside>
                        </Card>
                      </section>
                    );
                  }
                })}
            </section>
          </section>
        </section>
      </section>
    );
  }

  if (membershipValue[0].navStatus) {
    return (
      <section className="membership-tab-blk">
        <section className="membership-inner">
          <section className="memcontblk">
            <section className="row m-0 p-0">
              {props.state.videoListAPIData
                .filter((item) => {
                  return item.islive == false && item.category == "Membership";
                })
                .sort(function (a, b) {
                  if (a.sortorder < b.sortorder) {
                    return -1;
                  }
                  if (a.sortorder > b.sortorder) {
                    return 1;
                  }
                  return 0;
                })
                .map((videoData, memberindex) => {
                  if (
                    videoData.islive == false &&
                    videoData.category == "Membership"
                  ) {
                    console.log(
                      videoData.id,
                      videoData.topic,
                      videoData.sortorder
                    );
                    return (
                      <section
                        className="video-dashboard-width p-0 pe-3 mb-3"
                        key={memberindex}
                      >
                        <Card className="video-card">
                          <aside className="vidfile position-relative mb-2">
                            <Vimeo video={videoData.url} />
                          </aside>
                          <aside className="vidfileinfo position-relative">
                            <h2 className="pe-5">
                              {videoData.topic}
                              <span className="d-block video-description">
                                {videoData.description}
                              </span>
                            </h2>
                            <p>
                              <strong>CreatedAt:</strong>{" "}
                              {videoData.createdAt === null
                                ? ""
                                : moment(videoData.createdAt).format(
                                  dateTimeFormat1
                                ) +
                                " | " +
                                DateTimeFormatISO(
                                  videoData.createdAt,
                                  "TimeDefault"
                                )}
                            </p>
                            {videoData.isFeature == true ? (
                              <span
                                className="featured-btn active-btn"
                                onClick={(e) =>
                                  props.featuredBtnActive(videoData.id)
                                }
                              >
                                &nbsp;
                              </span>
                            ) : (
                              <span
                                className="featured-btn"
                                onClick={(e) => props.featuredBtn(videoData.id)}
                              >
                                &nbsp;
                              </span>
                            )}
                            <span
                              className="position-absolute top-0 d-inline-block"
                              style={{
                                height: "15px",
                                right: "35px",
                                cursor: "pointer",
                              }}
                              onClick={(e) => props.videoEditBtn(videoData.id)}
                            >
                              <i class="fa fa-pencil-square-o fs-5"></i>
                            </span>
                            <span
                              className="position-absolute top-0"
                              style={{ cursor: "pointer", right: "10px" }}
                              onClick={(e) =>
                                props.videoDeleteBtn(videoData.id)
                              }
                            >
                              <i class="fa fa-trash-o fs-5"></i>
                            </span>
                          </aside>
                        </Card>
                      </section>
                    );
                  }
                })}
            </section>
          </section>
        </section>
      </section>
    );
  }

  if (liveFromHedgedValue[0].navStatus) {
    return (
      <section className="memcontblk">
        <section className="livevideo-table-blk p-0 overflow-hidden">
          <section className="livefrom-tab-blk">
            <ul className="nav nav-tabs " id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link py-2 px-4 me-2 rounded-2 active"
                  id="free-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#free"
                  type="button"
                  role="tab"
                  aria-controls="free"
                  aria-selected="true"
                >
                  Free
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link py-2 px-4 me-2 rounded-2"
                  id="membership-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#membership"
                  type="button"
                  role="tab"
                  aria-controls="membership"
                  aria-selected="false"
                >
                  Membership
                </button>
              </li>
            </ul>

            <div className="tab-content mt-4" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="free"
                role="tabpanel"
                aria-labelledby="free-tab"
              >
                <section className="row m-0">
                  {props.state.videoListAPIData
                    .filter((item) => {
                      return (
                        item.islive == false &&
                        item.category == "Live from hedged" &&
                        item.subcategory == "Free"
                      );
                    })
                    .sort(function (a, b) {
                      if (a.sortorder < b.sortorder) {
                        return -1;
                      }
                      if (a.sortorder > b.sortorder) {
                        return 1;
                      }
                      return 0;
                    })
                    .map((videoData, memberindex) => {
                      if (
                        videoData.islive == false &&
                        videoData.category == "Live from hedged" &&
                        videoData.subcategory == "Free"
                      ) {
                        console.log(
                          videoData.id,
                          videoData.topic,
                          videoData.sortorder
                        );
                        return (
                          <section
                            className="video-dashboard-width p-0 pe-3 mb-3"
                            key={memberindex}
                          >
                            <Card className="video-card">
                              <aside className="vidfile position-relative mb-2">
                                <Vimeo video={videoData.url} />
                              </aside>
                              <aside className="vidfileinfo position-relative">
                                <h2 className="pe-5">
                                  {videoData.topic}
                                  <span className="d-block video-description">
                                    {videoData.description}
                                  </span>
                                </h2>
                                <p>
                                  <strong>CreatedAt:</strong>{" "}
                                  {videoData.createdAt === null
                                    ? ""
                                    : moment(videoData.createdAt).format(
                                      dateTimeFormat1
                                    ) +
                                    " | " +
                                    DateTimeFormatISO(
                                      videoData.createdAt,
                                      "TimeDefault"
                                    )}
                                </p>
                                {videoData.isFeature == true ? (
                                  <span
                                    className="featured-btn active-btn"
                                    onClick={(e) =>
                                      props.featuredBtnActive(videoData.id)
                                    }
                                  >
                                    &nbsp;
                                  </span>
                                ) : (
                                  <span
                                    className="featured-btn"
                                    onClick={(e) =>
                                      props.featuredBtn(videoData.id)
                                    }
                                  >
                                    &nbsp;
                                  </span>
                                )}
                                <span
                                  className="position-absolute top-0 d-inline-block"
                                  style={{
                                    height: "15px",
                                    right: "35px",
                                    cursor: "pointer",
                                  }}
                                  onClick={(e) =>
                                    props.videoEditBtn(videoData.id)
                                  }
                                >
                                  <i class="fa fa-pencil-square-o fs-5"></i>
                                </span>
                                <span
                                  className="position-absolute top-0"
                                  style={{ cursor: "pointer", right: "10px" }}
                                  onClick={(e) =>
                                    props.videoDeleteBtn(videoData.id)
                                  }
                                >
                                  <i class="fa fa-trash-o fs-5"></i>
                                </span>
                              </aside>
                            </Card>
                          </section>
                        );
                      }
                    })}
                </section>
              </div>
              <div
                className="tab-pane fade"
                id="membership"
                role="tabpanel"
                aria-labelledby="membership-tab"
              >
                <section className="row m-0">
                  {props.state.videoListAPIData
                    .filter((item) => {
                      return (
                        item.islive == false &&
                        item.category == "Live from hedged" &&
                        item.subcategory == "Membership"
                      );
                    })
                    .sort(function (a, b) {
                      if (a.sortorder < b.sortorder) {
                        return -1;
                      }
                      if (a.sortorder > b.sortorder) {
                        return 1;
                      }
                      return 0;
                    })
                    .map((videoData, memberindex) => {
                      if (
                        videoData.islive == false &&
                        videoData.category == "Live from hedged" &&
                        videoData.subcategory == "Membership"
                      ) {
                        console.log(
                          videoData.id,
                          videoData.topic,
                          videoData.sortorder
                        );
                        return (
                          <section
                            className="video-dashboard-width p-0 pe-3 mb-3"
                            key={memberindex}
                          >
                            <Card className="video-card">
                              <aside className="vidfile position-relative mb-2">
                                <Vimeo video={videoData.url} />
                              </aside>
                              <aside className="vidfileinfo position-relative">
                                <h2 className="pe-5">
                                  {videoData.topic}
                                  <span className="d-block video-description">
                                    {videoData.description}
                                  </span>
                                </h2>
                                <p>
                                  <strong>CreatedAt:</strong>{" "}
                                  {videoData.createdAt === null
                                    ? ""
                                    : moment(videoData.createdAt).format(
                                      dateTimeFormat1
                                    ) +
                                    " | " +
                                    DateTimeFormatISO(
                                      videoData.createdAt,
                                      "TimeDefault"
                                    )}
                                </p>
                                {videoData.isFeature == true ? (
                                  <span
                                    className="featured-btn active-btn"
                                    onClick={(e) =>
                                      props.featuredBtnActive(videoData.id)
                                    }
                                  >
                                    &nbsp;
                                  </span>
                                ) : (
                                  <span
                                    className="featured-btn"
                                    onClick={(e) =>
                                      props.featuredBtn(videoData.id)
                                    }
                                  >
                                    &nbsp;
                                  </span>
                                )}
                                <span
                                  className="position-absolute top-0 d-inline-block"
                                  style={{
                                    height: "15px",
                                    right: "35px",
                                    cursor: "pointer",
                                  }}
                                  onClick={(e) =>
                                    props.videoEditBtn(videoData.id)
                                  }
                                >
                                  <i class="fa fa-pencil-square-o fs-5"></i>
                                </span>
                                <span
                                  className="position-absolute top-0"
                                  style={{ cursor: "pointer", right: "10px" }}
                                  onClick={(e) =>
                                    props.videoDeleteBtn(videoData.id)
                                  }
                                >
                                  <i class="fa fa-trash-o fs-5"></i>
                                </span>
                              </aside>
                            </Card>
                          </section>
                        );
                      }
                    })}
                </section>
              </div>
            </div>
          </section>
        </section>
      </section>
    );
  }

  if (two_hr[0].navStatus) {
    return (
      <section className="two_hr-tab-blk">
        <section className="two_hr-inner">
          <section className="memcontblk">
            <section className="row m-0 p-0">
              {props.state.videoListAPIData
                .filter((item) => {
                  return (
                    item.islive == false && item.category == "2Hr Trader"
                  );
                })
                .sort(function (a, b) {
                  if (a.sortorder < b.sortorder) {
                    return -1;
                  }
                  if (a.sortorder > b.sortorder) {
                    return 1;
                  }
                  return 0;
                })
                .map((videoData, Tradeindex) => {
                  if (
                    videoData.islive == false &&
                    videoData.category == "2Hr Trader"
                  ) {
                    // console.log(
                    //   videoData.id,
                    //   videoData.topic,
                    //   videoData.sortorder
                    // );
                    return (
                      <section
                        className="video-dashboard-width p-0 pe-3 mb-3"
                        key={Tradeindex}
                      >
                        <Card className="video-card">
                          <aside className="vidfile position-relative mb-2">
                            <Vimeo video={videoData.url} />
                          </aside>
                          <aside className="vidfileinfo position-relative mb-2">
                            <h2 className="pe-5">
                              {videoData.topic}
                              <span className="d-block video-description">
                                {videoData.description}
                              </span>
                            </h2>
                            <p>
                              <strong>CreatedAt:</strong>{" "}
                              {videoData.createdAt !== null
                                ? moment(videoData.createdAt).format(
                                  dateTimeFormat1
                                ) +
                                " | " +
                                DateTimeFormatISO(
                                  videoData.createdAt,
                                  "TimeDefault"
                                )
                                : null}
                            </p>
                            <div className="">
                              {videoData.isFeature == true ? (
                                <span
                                  className="featured-btn active-btn"
                                  onClick={(e) =>
                                    props.featuredBtnActive(videoData.id)
                                  }
                                >
                                  &nbsp;
                                </span>
                              ) : (
                                <span
                                  className="featured-btn"
                                  onClick={(e) =>
                                    props.featuredBtn(videoData.id)
                                  }
                                >
                                  &nbsp;
                                </span>
                              )}
                              <span
                                className="position-absolute top-0 d-inline-block"
                                style={{
                                  height: "15px",
                                  right: "35px",
                                  cursor: "pointer",
                                }}
                                onClick={(e) =>
                                  props.videoEditBtn(videoData.id)
                                }
                              >
                                <i class="fa fa-pencil-square-o fs-5"></i>
                              </span>
                              <span
                                className="position-absolute top-0"
                                style={{ cursor: "pointer", right: "10px" }}
                                onClick={(e) =>
                                  props.videoDeleteBtn(videoData.id)
                                }
                              >
                                <i class="fa fa-trash-o fs-5"></i>
                              </span>
                            </div>
                          </aside>
                        </Card>
                      </section>
                    );
                  }
                })}
            </section>
          </section>
        </section>
      </section>
    );
  }

  if (tutorialValue[0].navStatus) {
    return (
      <section className="trading-tab-blk">
        <section className="trader-inner">
          <section className="memcontblk">
            <section className="row m-0 p-0">
              {props.state.videoListAPIData
                .filter((item) => {
                  return (
                    item.islive == false && item.category == "Hedged tutorials"
                  );
                })
                .sort(function (a, b) {
                  if (a.sortorder < b.sortorder) {
                    return -1;
                  }
                  if (a.sortorder > b.sortorder) {
                    return 1;
                  }
                  return 0;
                })
                .map((videoData, Tradeindex) => {
                  if (
                    videoData.islive == false &&
                    videoData.category == "Hedged tutorials"
                  ) {
                    // console.log(
                    //   videoData.id,
                    //   videoData.topic,
                    //   videoData.sortorder
                    // );
                    return (
                      <section
                        className="video-dashboard-width p-0 pe-3 mb-3"
                        key={Tradeindex}
                      >
                        <Card className="video-card">
                          <aside className="vidfile position-relative mb-2">
                            <Vimeo video={videoData.url} />
                          </aside>
                          <aside className="vidfileinfo position-relative mb-2">
                            <h2 className="pe-5">
                              {videoData.topic}
                              <span className="d-block video-description">
                                {videoData.description}
                              </span>
                            </h2>
                            <p>
                              <strong>CreatedAt:</strong>{" "}
                              {videoData.createdAt !== null
                                ? moment(videoData.createdAt).format(
                                  dateTimeFormat1
                                ) +
                                " | " +
                                DateTimeFormatISO(
                                  videoData.createdAt,
                                  "TimeDefault"
                                )
                                : null}
                            </p>
                            <div className="">
                              {videoData.isFeature == true ? (
                                <span
                                  className="featured-btn active-btn"
                                  onClick={(e) =>
                                    props.featuredBtnActive(videoData.id)
                                  }
                                >
                                  &nbsp;
                                </span>
                              ) : (
                                <span
                                  className="featured-btn"
                                  onClick={(e) =>
                                    props.featuredBtn(videoData.id)
                                  }
                                >
                                  &nbsp;
                                </span>
                              )}
                              <span
                                className="position-absolute top-0 d-inline-block"
                                style={{
                                  height: "15px",
                                  right: "35px",
                                  cursor: "pointer",
                                }}
                                onClick={(e) =>
                                  props.videoEditBtn(videoData.id)
                                }
                              >
                                <i class="fa fa-pencil-square-o fs-5"></i>
                              </span>
                              <span
                                className="position-absolute top-0"
                                style={{ cursor: "pointer", right: "10px" }}
                                onClick={(e) =>
                                  props.videoDeleteBtn(videoData.id)
                                }
                              >
                                <i class="fa fa-trash-o fs-5"></i>
                              </span>
                            </div>
                          </aside>
                        </Card>
                      </section>
                    );
                  }
                })}
            </section>
          </section>
        </section>
      </section>
    );
  }

  if (aosTradervideos[0].navStatus) {
    return (
      <section className="trading-tab-blk">
        <section className="trader-inner">
          <section className="memcontblk">
            <section className="row m-0 p-0">
              {props.state.videoListAPIData
                .filter((item) => {
                  return (
                    item.islive == false && item.category == "Ace Of Spades"
                  );
                })
                .sort(function (a, b) {
                  if (a.sortorder < b.sortorder) {
                    return -1;
                  }
                  if (a.sortorder > b.sortorder) {
                    return 1;
                  }
                  return 0;
                })
                .map((videoData, Tradeindex) => {
                  if (
                    videoData.islive == false &&
                    videoData.category == "Ace Of Spades"
                  ) {
                    // console.log(
                    //   videoData.id,
                    //   videoData.topic,
                    //   videoData.sortorder
                    // );
                    return (
                      <section
                        className="video-dashboard-width p-0 pe-3 mb-3"
                        key={Tradeindex}
                      >
                        <Card className="video-card">
                          <aside className="vidfile position-relative mb-2">
                            <Vimeo video={videoData.url} />
                          </aside>
                          <aside className="vidfileinfo position-relative mb-2">
                            <h2 className="pe-5">
                              {videoData.topic}
                              <span className="d-block video-description">
                                {videoData.description}
                              </span>
                            </h2>
                            <p>
                              <strong>CreatedAt:</strong>{" "}
                              {videoData.createdAt !== null
                                ? moment(videoData.createdAt).format(
                                  dateTimeFormat1
                                ) +
                                " | " +
                                DateTimeFormatISO(
                                  videoData.createdAt,
                                  "TimeDefault"
                                )
                                : null}
                            </p>
                            <div className="">
                              {videoData.isFeature == true ? (
                                <span
                                  className="featured-btn active-btn"
                                  onClick={(e) =>
                                    props.featuredBtnActive(videoData.id)
                                  }
                                >
                                  &nbsp;
                                </span>
                              ) : (
                                <span
                                  className="featured-btn"
                                  onClick={(e) =>
                                    props.featuredBtn(videoData.id)
                                  }
                                >
                                  &nbsp;
                                </span>
                              )}
                              <span
                                className="position-absolute top-0 d-inline-block"
                                style={{
                                  height: "15px",
                                  right: "35px",
                                  cursor: "pointer",
                                }}
                                onClick={(e) =>
                                  props.videoEditBtn(videoData.id)
                                }
                              >
                                <i class="fa fa-pencil-square-o fs-5"></i>
                              </span>
                              <span
                                className="position-absolute top-0"
                                style={{ cursor: "pointer", right: "10px" }}
                                onClick={(e) =>
                                  props.videoDeleteBtn(videoData.id)
                                }
                              >
                                <i class="fa fa-trash-o fs-5"></i>
                              </span>
                            </div>
                          </aside>
                        </Card>
                      </section>
                    );
                  }
                })}
            </section>
          </section>
        </section>
      </section>
    );
  }

  return (
    // <section className="memcontblk">
    //   {/* {(getMemVal[0].navStatus || getlivVal[0].navStatus) && (
    //     <Membernavcomp
    //       changememTab={props.changememTab}
    //       navs={props.state.navMembernav}
    //     />
    //   )} */}
    //   {(getlivVal[0].navStatus) && (
    //     <Membernavcomp
    //       changememTab={props.changememTab}
    //       navs={props.state.navMembernav}
    //     />
    //   )}
    //   <section className="row m-0 p-0">{setcont}</section>
    // </section>

    <section className="memcontblk">
      {getlivVal[0].navStatus && (
        <section className="livevideo-table-blk p-0">
          <section className="livefrom-tab-blk">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link py-2 px-4 me-2 rounded-2 active"
                  id="live-free-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#live-free"
                  type="button"
                  role="tab"
                  aria-controls="live-free"
                  aria-selected="true"
                >
                  Free
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link py-2 px-4 me-2 rounded-2"
                  id="live-membership-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#live-membership"
                  type="button"
                  role="tab"
                  aria-controls="live-membership"
                  aria-selected="false"
                >
                  Membership
                </button>
              </li>
            </ul>

            <div className="tab-content mt-4" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="live-free"
                role="tabpanel"
                aria-labelledby="live-free-tab"
              >
                <section className="table-blk">
                  <table className="livevideo-table">
                    <thead>
                      <tr>
                        <th>Event Date &amp; Time</th>
                        <th>Topic</th>
                        <th>Description</th>
                        <th>Organizer</th>
                        <th>Category</th>
                        <th>Banner Start date &amp; time</th>
                        <th>Banner End date &amp; time</th>
                        <th>Video link</th>
                        <th>Thumbnail upload</th>
                        <th>Created at</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.state.videoListAPIData
                        .filter((item) => {
                          return (
                            item.islive == true && item.subcategory == "Free"
                          );
                        })
                        .sort(function (a, b) {
                          if (a.updatedAt < b.updatedAt) {
                            return -1;
                          }
                          if (a.updatedAt > b.updatedAt) {
                            return 1;
                          }
                          return 0;
                        })
                        .map((videoLiveData, Liveindex) => {
                          if (
                            videoLiveData.islive == true &&
                            videoLiveData.subcategory == "Free"
                          ) {
                            return (
                              <tr key={Liveindex}>
                                <td>
                                  {videoLiveData.eventDate == null ||
                                    videoLiveData.eventDate == "0000-00-00"
                                    ? "No date"
                                    : moment(videoLiveData.eventDate).format(
                                      "DD/MM/YYYY"
                                    )}
                                  <span className="d-block">
                                    {videoLiveData.eventTime == null
                                      ? "and time"
                                      : moment(
                                        videoLiveData.eventTime,
                                        "HH:mm:ss"
                                      ).format("hh:mm A")}
                                  </span>
                                </td>
                                <td>{videoLiveData.topic}</td>
                                <td>{videoLiveData.description}</td>
                                <td>
                                  {videoLiveData.organiser == ""
                                    ? "Null"
                                    : videoLiveData.organiser}
                                </td>
                                <td>
                                  {videoLiveData.subcategory == null
                                    ? "Null"
                                    : videoLiveData.subcategory}
                                </td>
                                <td>
                                  {videoLiveData.bannerStartDate == null
                                    ? "Null"
                                    : moment(
                                      videoLiveData.bannerStartDate
                                    ).format("DD/MM/YYYY")}

                                  <span>
                                    {videoLiveData.bannerStartDate == null
                                      ? ""
                                      : // DateTimeFormatISO(
                                      //     videoLiveData.bannerStartDate,
                                      //     "TimeDefault"
                                      //   )
                                      new Date(
                                        videoLiveData.bannerStartDate
                                      ).toLocaleTimeString("en-US")}
                                  </span>
                                </td>
                                <td>
                                  {videoLiveData.bannerEndDate == null
                                    ? "Null"
                                    : moment(
                                      videoLiveData.bannerEndDate
                                    ).format("DD/MM/YYYY")}
                                  <span>
                                    {videoLiveData.bannerEndDate == null
                                      ? ""
                                      : new Date(
                                        videoLiveData.bannerEndDate
                                      ).toLocaleTimeString("en-US")}
                                  </span>
                                </td>
                                <td>
                                  <a
                                    href={videoLiveData.imageUrl}
                                    target="_blank"
                                  >
                                    {videoLiveData.imageUrl == null
                                      ? "Null"
                                      : videoLiveData.imageUrl}
                                  </a>
                                </td>
                                <td>
                                  {videoLiveData.imageUrl == null
                                    ? "No"
                                    : "Yes"}
                                </td>
                                <td>
                                  {videoLiveData.createdAt == null
                                    ? "Null"
                                    : moment(videoLiveData.createdAt).format(
                                      "DD/MM/YYYY"
                                    )}
                                </td>
                                <td className="edit-fields">
                                  <span
                                    className="d-block"
                                    onClick={(e) =>
                                      props.liveVideoEdit(videoLiveData.id)
                                    }
                                  >
                                    Edit
                                  </span>
                                  <span
                                    className="d-block"
                                    onClick={(e) =>
                                      props.liveVideoDelete(videoLiveData.id)
                                    }
                                  >
                                    Delete
                                  </span>
                                </td>
                              </tr>
                            );
                          }
                        })}
                    </tbody>
                  </table>
                </section>
              </div>
              <div
                className="tab-pane fade"
                id="live-membership"
                role="tabpanel"
                aria-labelledby="live-membership-tab"
              >
                <section className="table-blk">
                  <table className="livevideo-table">
                    <thead>
                      <tr>
                        <th>Event Date &amp; Time</th>
                        <th>Topic</th>
                        <th>Description</th>
                        <th>Organizer</th>
                        <th>Category</th>
                        <th>Banner Start date &amp; time</th>
                        <th>Banner End date &amp; time</th>
                        <th>Video link</th>
                        <th>Thumbnail upload</th>
                        <th>Created at</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.state.videoListAPIData
                        .filter((item) => {
                          return (
                            item.islive == true &&
                            item.subcategory == "Membership"
                          );
                        })
                        .sort(function (a, b) {
                          if (a.updatedAt < b.updatedAt) {
                            return -1;
                          }
                          if (a.updatedAt > b.updatedAt) {
                            return 1;
                          }
                          return 0;
                        })
                        .map((videoLiveData, Liveindex) => {
                          if (
                            videoLiveData.islive == true &&
                            videoLiveData.subcategory == "Membership"
                          ) {
                            return (
                              <tr key={Liveindex}>
                                <td>
                                  {videoLiveData.eventDate == null ||
                                    videoLiveData.eventDate == "0000-00-00"
                                    ? "No date"
                                    : moment(videoLiveData.eventDate).format(
                                      "DD/MM/YYYY"
                                    )}
                                  <span className="d-block ">
                                    {videoLiveData.eventTime == null
                                      ? "and time"
                                      : moment(
                                        videoLiveData.eventTime,
                                        "HH:mm:ss"
                                      ).format("hh:mm A")}
                                  </span>
                                </td>
                                <td>{videoLiveData.topic}</td>
                                <td>{videoLiveData.description}</td>
                                <td>
                                  {videoLiveData.organiser == ""
                                    ? "Null"
                                    : videoLiveData.organiser}
                                </td>
                                <td>
                                  {videoLiveData.subcategory == null
                                    ? "Null"
                                    : videoLiveData.subcategory}
                                </td>
                                <td>
                                  {videoLiveData.bannerStartDate == null
                                    ? "Null"
                                    : moment(
                                      videoLiveData.bannerStartDate
                                    ).format("DD/MM/YYYY")}
                                  <span>
                                    {videoLiveData.bannerStartDate == null
                                      ? ""
                                      : // : DateTimeFormatISO(
                                      //     videoLiveData.bannerStartDate,
                                      //     "TimeDefault"
                                      //   )
                                      new Date(
                                        videoLiveData.bannerStartDate
                                      ).toLocaleTimeString("en-US")}
                                  </span>
                                </td>
                                <td>
                                  {videoLiveData.bannerEndDate == null
                                    ? "Null"
                                    : moment(
                                      videoLiveData.bannerEndDate
                                    ).format("DD/MM/YYYY")}
                                  <span>
                                    {videoLiveData.bannerEndDate == null
                                      ? ""
                                      : // : DateTimeFormatISO(
                                      //     videoLiveData.bannerEndDate,
                                      //     "TimeDefault"
                                      //   )
                                      new Date(
                                        videoLiveData.bannerEndDate
                                      ).toLocaleTimeString("en-US")}
                                  </span>
                                </td>
                                <td>
                                  <a
                                    href={videoLiveData.imageUrl}
                                    target="_blank"
                                  >
                                    {videoLiveData.imageUrl == null
                                      ? "Null"
                                      : videoLiveData.imageUrl}
                                  </a>
                                </td>
                                <td>
                                  {videoLiveData.imageUrl == null
                                    ? "No"
                                    : "Yes"}
                                </td>
                                <td>
                                  {videoLiveData.createdAt == null
                                    ? "Null"
                                    : moment(videoLiveData.createdAt).format(
                                      "DD/MM/YYYY"
                                    )}
                                </td>
                                <td className="edit-fields">
                                  <span
                                    className="d-block"
                                    onClick={(e) =>
                                      props.liveVideoEdit(videoLiveData.id)
                                    }
                                  >
                                    Edit
                                  </span>
                                  <span
                                    className="d-block"
                                    onClick={(e) =>
                                      props.liveVideoDelete(videoLiveData.id)
                                    }
                                  >
                                    Delete
                                  </span>
                                </td>
                              </tr>
                            );
                          }
                        })}
                    </tbody>
                  </table>
                </section>
              </div>
            </div>
          </section>
        </section>
      )}
    </section>
  );
};

export default Memcontblk;
