import React,{useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import Addvideocomp from './Addvideocomp';
import Livevidcomp from './Livevideocomp';

const Videomodal = (props) => {
    return (
        <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered className={`modalblkk`} >
            <Modal.Body>
                <section className={`${props.state.liveEditSaveBtnShow ? 'd-none':'d-block'}`}>
                    <aside className="col-lg-8 firstblk pt-1 pb-2 d-flex flex-row justify-content-between mx-auto position-video">
                        <aside>
                            <input type="radio" name="newVideo" id="add" onChange={(e)=>props.getRadioinfo(e)}/>
                            <label htmlFor="add">New Video <i>&nbsp;</i></label>
                        </aside>
                        <aside>
                            <input type="radio" name="newVideo" id="live" onChange={(e)=>props.getRadioinfo(e)}/>
                            <label htmlFor="live">Live Video <i>&nbsp;</i></label>
                        </aside>
                    </aside>
                </section>
                {/* <section className="row flex-row justify-content-center align-itmes-center">
                    <aside className="col-lg-7 firstblk pt-1 pb-2 d-flex flex-row justify-content-between">
                        <aside>
                            <input type="radio" name="newVideo" id="add" onChange={(e)=>props.getRadioinfo(e)}/>
                            <label htmlFor="add">New Video <i>&nbsp;</i></label>
                        </aside>
                        <aside>
                            <input type="radio" name="newVideo" id="live" onChange={(e)=>props.getRadioinfo(e)}/>
                            <label htmlFor="live">Live Video <i>&nbsp;</i></label>
                        </aside>
                    </aside>
                </section> */}
                {props.state.vidmodal.alertbox &&
                    (<div className="alert alert-danger text-center" role="alert">
                        {props.state.vidmodal.alertmsg}
                    </div>)
                } 
                {props.state.vidmodal.addvid && <Addvideocomp
                    addvidsave={props.addvidsave}
                    featuredOnOff={props.featuredOnOff}
                    newVideoSaveDisabled={props.newVideoSaveDisabled}
                    handlevidupload={props.handlevidupload}
                    addmembertype={props.addmembertype}
                    categorytype={props.categorytype}
                    liveFromHedgedSelected={props.liveFromHedgedSelected}
                    publishOnOff={props.publishOnOff}
                    inputchange={props.inputchange}
                    memberOnChange={props.memberOnChange}
                    categoryOnChange={props.categoryOnChange}
                    liveHedgedcategoryOnChange={props.liveHedgedcategoryOnChange}
                    setDatefun={props.setDatefun}
                    stateinfo={props.state}
                    videoType={props?.videoType}
                    videoTypeOnChange={props.videoTypeOnChange}

                    />}

                {props.state.vidmodal.livvid && <Livevidcomp 
                    lividsave={props.lividsave} 
                    liveEditSaveBtn={props.liveEditSaveBtn}
                    liveVideoDelete={props.liveVideoDelete}
                    handlevidupload={props.handlevidupload}
                    liveVideoSaveDisabled={props.liveVideoSaveDisabled}
                    handleimgupload={props.handleimgupload}
                    addmembertype={props.addmembertype}
                    inputchange={props.inputchange}
                    checkchange={props.checkchange}
                    memberOnChange={props.memberOnChange}
                    setDatefun={props.setDatefun}
                    stateinfo={props.state}
                    fakeobj={props.state.fakeobj}
                    videoType={props?.videoType}
                    videoTypeOnChange={props.videoTypeOnChange}    
                    />}
            </Modal.Body>
        </Modal>
        
    )
}

export default Videomodal
