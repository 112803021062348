import React, { Component } from 'react';
import moment from 'moment';
import Datatable from 'react-bs-datatable';

export default class PreviewIntradayData extends Component {
  render() {
    return (
      <section className="admin-bulishSaveProceedLeft">
        <section className="pv-inner">
          <section className="row">
            <div className="col-lg-6">
              <ul className="list-group list-group-horizontal w-33">
                <li className="list-group-item col">
                  <span>Call Status </span>
                  <span className="price">{this.props.state.callStatus}</span>
                </li>
                <li className="list-group-item col">
                  <span>Call Type </span>
                  <span className="price">
                    {this.props.state.optionsBulish ? 'Bullish' : 'Bearish'}
                  </span>
                </li>

                <li className="list-group-item col">
                  <span>Strategy </span>
                  <span className="price">
                    {this.props.state.optionsSelect}
                  </span>
                </li>
                <li className="list-group-item col">
                  <span>scrip </span>
                  <span className="price">{this.props.state.scripname}</span>
                </li>
              </ul>
            </div>
          </section>
          <section className="row">
            <section className="col-lg-6">
              {this.props.state.legsArray.length === 0 ? (
                ''
              ) : (
                <section className="total-calls-blk">
                  {this.props.state.legsArray.map((legs, index) => {
                    return (
                      <section className="calls" key={index}>
                        <h3>Leg {index + 1}</h3>
                        <p>
                          <span>Action</span>
                          <span>Series</span>
                          <span>Strike Price</span>
                          <span>Entry Price</span>
                          <span>Quantity</span>
                        </p>
                        <p>
                          <span>{legs.action}</span>
                          <span>
                            {' '}
                            {moment(legs.series).format("Do MMM'YY")}
                          </span>
                          <span>
                            {legs.strike_price} {legs.legOption}
                          </span>
                          <span>{legs.entry_price}</span>
                          <span>{legs.quantity}</span>
                        </p>
                      </section>
                    );
                  })}
                </section>
              )}
            </section>
          </section>

          <section className="row">
            <div className="col-lg-6">
              <ul className="list-group list-group-horizontal w-33">
                <li className="list-group-item col">
                  <span>Capital Required </span>
                  <span className="price">
                    {this.props.state.parCapitalRequied}
                  </span>
                </li>

                {/* <li className="list-group-item col">
                  <span>Max Profit </span>
                  <span className="price">{this.props.state.parMaxProfit}</span>
                </li>

                <li className="list-group-item col">
                  <span>Max Loss </span>
                  <span className="price">{this.props.state.parMaxLoss}</span>
                </li> */}

                <li className="list-group-item col">
                  <span>Stop Loss </span>
                  <span className="price">{this.props.state.parStopLoss}</span>
                </li>
                {/* <li className="list-group-item col">
                  <span>Expected Profit </span>
                  <span className="price">
                    {this.props.state.parExpectedProfit}
                  </span>
                </li> */}
                {/* <li className="list-group-item col">
                  <span>Stop Loss Type </span>
                  <span className="price">
                    {this.props.state.parSelectValue}
                  </span>
                </li> */}

                <li className="list-group-item col">
                  <span>Lot Size </span>
                  <span className="price">{this.props.state.parLotSize}</span>
                </li>
              </ul>
            </div>
          </section>
          <section className="list-blk d-block">
            <div className="row">
              <div className="col-md-3">
                <h2>Payoff Chart : </h2>
                {/* <p className=""> {this.props.state.getpayoffchartUrl}</p> */}
                <img
                  src={this.props.state.getpayoffchartUrl}
                  style={{ width: '100%' }}
                />
              </div>
              {/* <div className="col-md-6">
                 <h2>Trade logic Image </h2>
                <img
                  src={this.props.state.tradeLogicUrl}
                  alt="img"
                  style={{ width: '100%' }}
                />
                <div>
                  <h2>Trade logic Notes : </h2>
                  <span>{this.props.state.tradeLogicNotes}</span>
                </div> 
              </div>*/}
            </div>
          </section>
        </section>
      </section>
    );
  }
}
