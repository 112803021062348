import React from 'react';
import Dashboard from '../dashboard/dashboard';

class Body extends React.Component {
  render() {
    return (
      <section className="admin-body">
          <div className="row">
              <div className="col-md-10">
                  <Dashboard />
              </div>
          </div>
      </section>
    )
  }
}

export default Body;