import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const options = [
  { value: null, label: "Select" },
  { value: 0, label: "Open" },
  { value: 1, label: "Pending" },
  { value: 2, label: "Closed" },
];

const UpdateStatusModal = (props) => {
  const [status, setStatus] = useState(null);
  const [textValue, setTextValue] = useState("");
  console.log('textValue', textValue);

  const handleTextareaChange = (event) => {
    setTextValue(event.target.value);
  };

  useEffect(() => {
    if (props.data?.status !== null) {
      setStatus(props.data?.status)
    }
    setTextValue(props.data?.remark)
  }, [props.data])

  console.log("status", status);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={`modalblkk`}
    >
      <Modal.Body>
        <section className="row m-0 p-0 mt-4 justify-content-center addblk">
          <section className="col-lg-12">
            <section className="row m-0 p-0 justify-content-center">
              <section className="col-lg-8 formblk">
                <aside className="blk mt-3 w-100">
                  <label>Status</label>
                  <Select
                    options={options}
                    onChange={(e) => setStatus(e.value)}
                    classNamePrefix="addsele"
                    value={options.find((option) => option.value === status)}
                  // value={status}
                  // textValue={status}
                  />
                </aside>
                <aside className="blk mt-3 w-100 remark">
                  <label>Remark</label>
                  {/* <input/> */}
                  <textarea
                    defaultValue={textValue}
                    onChange={handleTextareaChange}
                    rows="6"
                    cols="41"
                  >
                    {/* aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaasas */}
                  </textarea>
                </aside>
              </section>
            </section>
            <section className="row justify-content-center p-0 m-0 my-5">
              <section className="col-3">
                <button
                  className="btnsave"
                  onClick={async () => {
                    await props.updatePlan(status, textValue)
                    setTextValue('');
                  }}
                >
                  Save
                </button>
              </section>
            </section>
          </section>
        </section>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateStatusModal;
