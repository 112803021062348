import * as API from "../../configuration/apiconfig";
import swal from "sweetalert";
import moment from "moment";
import constant from "../../configuration/config";

const format1 = "YYYY-MM-DD HH:mm:ss";

const fieldName = {
  title: "Live Fund Title",
  // label: "Live Fund Label",
  planid: "Subscription",
  expected_returns: "Expected Return (%)",
  equity_capital_required: "Equity Capital Required",
  hedged_capital_required: "Hedged Capital Required",
  equity_notes: "Equity Notes",
  hedged_notes: "Hedged Notes",
  current_returns: "Current Returns",
};

// function to create a new Bundle (Equity / Options)
export async function createBundle(data, setDoneBtnDisable) {
  console.log("----------------- API Create Bundle -------------------");
  let error = false;
  // console.log("data",data);
  await data.data.optionsAddScripArray.map(async (item) => {
    await item.legs.map((leg, index) => {
      if (
        leg.exitPriceToggle &&
        (leg.exitPrice === undefined || leg.exitPrice === "")
      ) {
        swal({
          title: `Enter Exit Price for Leg ${index + 1}`,
          text: "Leg cannot be closed without Exit Price",
          icon: "info",
        });
        error = true;
      }
    });
  });

  //console.log(data.data);

  if (error) {
    setDoneBtnDisable(false);
    return false;
  } else {
    try {
      let data1 = await formartData(data);
      // check if all fields are present
      if (checkIfAllFieldsArePresent(data1)) {
        setDoneBtnDisable(false);
        return false;
      } else {
        return await API.callEndpoint("POST", "Bearer", "/api/bundles", data1)
          .then((response) => {
            try {
              setDoneBtnDisable(false);
              return true;
            } catch (e) {
              swal({
                title: "An error occured, try again!",
                text: e.error,
                icon: "info",
              });
              setDoneBtnDisable(false);
              return false;
            }
          })
          .catch((error) => {
            swal({
              title: "An error occured, try again!",
              text: error.error,
              icon: "info",
            });
            setDoneBtnDisable(false);
            return false;
          });
      }
    } catch (e) {
      swal({
        title: "An error occured, try again!",
        text: e.error,
        icon: "info",
      });
      setDoneBtnDisable(false);
    }
  }
}

// function to update a new Bundle (Equity / Options)
export async function updateBundle(data, id, setDoneBtnDisable) {
  console.log("----------------- API Update Bundle -------------------");
  let error = false;
  // console.log("data",data);
  await data.data.optionsAddScripArray.map(async (item) => {
    await item.legs.map((leg, index) => {
      if (
        leg.exitPriceToggle &&
        (leg.exitPrice === undefined || leg.exitPrice === "")
      ) {
        swal({
          title: `Enter Exit Price for Leg ${index + 1}`,
          text: "Leg cannot be closed without Exit Price",
          icon: "info",
        });
        error = true;
      }

      // if (
      //   leg.instrument_id == ""
      // ) {
      //   swal({
      //     title: `Instrument Not Found for Leg ${index + 1}`,
      //     text: "Leg cannot be Add without instrument Id",
      //     icon: "info",
      //   });
      //   error = true;
      // }
    });
  });

  if (error) {
    setDoneBtnDisable(false);
    return false;
  } else {
    try {
      let data1 = await updateformartData(data);

      // console.log("data1",data1);
      data1["id"] = id;
      // data1["bundles_calls"] = data1.bundles_calls.map((item) => {
      //   item["bundle_id"] = id;
      //   return item;
      // });

      // check if all fields are present
      if (checkIfAllFieldsArePresent(data1)) {
        setDoneBtnDisable(false);
        // console.log("updateformartData(data) if", data1);

        return false;
      } else {
        // console.log("updateformartData", data1  );

        return await API.callEndpoint("PUT", "Bearer", "/api/bundles", data1)
          .then(async (response) => {
            try {
              console.log(response);
              setDoneBtnDisable(false);
              await API.swaggerEndpoint(
                "POST",
                "System",
                "/system/mark_bundle_modifications",
                {},
                `bundleId=${data1.id}`,
                constant.swaggerSystemToken
              );
              return true;
            } catch (e) {
              console.log("1", e);

              swal({
                title: "An error occured, try again!",
                text: e.error,
                icon: "info",
              });
              setDoneBtnDisable(false);
              return false;
            }
          })
          .catch((error) => {
            swal({
              title: "An error occured, try again!",
              text: error.error,
              icon: "info",
            });
            setDoneBtnDisable(false);
            return false;
          });
      }
    } catch (e) {
      swal({
        title: "An error occured, try again!",
        text: e.error,
        icon: "info",
      });
      setDoneBtnDisable(false);
    }
  }
}

let searchInstrumentID = async (name) => {
  try {
    const response = await fetch(
      `https://staging-dot-orders-hedged-in.appspot.com/instruments/search_instruments?tradingsymbol=${name?.toUpperCase()}`,
      {
        method: "GET",
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    const instrumentData = data || "";

    return instrumentData[0];
  } catch (error) {
    return "";
  }
};

let getInstrumentID = async (name, series, strike) => {
  try {
    const response = await fetch(
      `https://staging-dot-orders-hedged-in.appspot.com/instruments/get_instrument?name=${name}&series=${series}&strike_price=${strike}`,
      {
        method: "GET",
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    const instrumentId = data?.instrument_id || "";

    return instrumentId;
  } catch (error) {
    return "";
  }
};

//this function is used to format the data in the desired format for backend to injust the data
let formartData = async (obj) => {
  let data = obj.data;
  //console.log(data rohan);
  let outputData = {};

  //planid
  let planid = 1;
  if (data.subscription === "Prime") {
    planid = 2;
  } else if (data.subscription === "Crown") {
    planid = 3;
  }
  let equityAddScripArray = [];

  //equity
  equityAddScripArray = await Promise.all(
    data.equityAddScripArray.map(async (item) => {
      // console.log("item",item);
      const instrumentData = await searchInstrumentID(
        item.scripNameValue.label
      );

      let out = {
        type: "Equity",
        instrument_type: instrumentData?.instrument_type,
        instrument_id: instrumentData?.instrument_id,
        action: "BUY",
        quantity: parseInt(item.quantityValue) || null,
        strike: instrumentData?.tradingsymbol,
        entry_version: 1,
        call_type: item.call_type.value,
        entry_price_start: parseInt(item.minPriceValue) || null,
        entry_price_end: parseInt(item.maxPriceValue) || null,
        quantity_percent: parseInt(item.quantityPercentValue) || null,
        status: item.call_status.value,
        stop_loss_type: "Value",
        name: item.scripNameValue.label,
        current_price: instrumentData?.last_price,
        returns:
          item.call_status.value === "closed"
            ? parseInt(item.closeTotal) || null
            : null,
      };
      if (item.id !== undefined && item.id !== "") {
        out["id"] = item.id;
      }

      return out;
    })
  );
  let optionsAddScripArray = [];

  // Define a helper function for processing options data
  const processOptionsData = async (item) => {
    return Promise.all(
      item.legs.map(async (leg) => {
        const instrument_id = await getInstrumentID(
          item?.scripNameValue?.label,
          moment(leg.expiry).format("Do MMM'YY"),
          `${leg.strikePrice}${leg.option.value}`
        );

        return {
          type: "Option",
          instrument_type: leg.option.value,
          instrument_id: instrument_id,
          action: leg.action.value?.toUpperCase(),
          quantity: parseInt(leg.qtyValue),
          entry_price_start: leg.Price,
          entry_price_end: leg.Price,
          series: moment(leg.expiry).format("Do MMM'YY"),
          strike: leg.strikePrice,
          entry_version: "1",
          strike_price: `${leg.strikePrice}${leg.option.value}`,
          price: leg.Price,
          exit_price: leg.exitPrice === undefined ? "" : leg.exitPrice,
          status:
            leg.exitPrice === undefined || leg.exitPrice === ""
              ? "new"
              : "closed",
        };
      })
    );
  };

  optionsAddScripArray = await Promise.all(
    data.optionsAddScripArray.map(async (item) => {
      let legs_info = await processOptionsData(item);
      let instrumentData = await searchInstrumentID(
        item.scripNameValue.label?.toLowerCase() == "nifty"
          ? "Nifty 50"
          : item.scripNameValue.label
      );

      let out = {
        hedged_capital_required: parseInt(item.capitalReq) || null,
        hedged_strategy: item.strategy.value,
        hedged_maxloss: parseInt(item.maxLoss) || null,
        hedged_stoploss: parseInt(item.stopLoss) || null,
        hedged_stoplosstype: item.stopLossTypeOption.value,
        hedged_exitprice: parseInt(item.exitPrice) || "",
        hedged_type: item.call_type.value,
        hedged_status: item.call_status.value,
        name: item.scripNameValue.label,
        current_price: instrumentData?.last_price,
        instrument_id: instrumentData?.instrument_id,
        hedged_bundle_version: "1",
        bundles_calls_legs: [...legs_info],
        close_type:
          item.call_status.value === "closed"
            ? item.closeType.value || null
            : null,

        // name: item.scripNameValue.label,
        // scrip_symbol: item.scripNameValue.value,
        // call_class: "Option",
        // call_type: item.call_type.value,
        // capital_required: parseInt(item.capitalReq) || null,
        // call_status: item.call_status.value,
        // close_type:
        //   item.call_status.value === "closed"
        //     ? item.closeType.value || null
        //     : null,
        // returns:
        //   item.call_status.value === "closed"
        //     ? parseInt(item.closeTotal) || null
        //     : null,
        // info: {
        //   strategy: item.strategy.value,
        //   max_loss: parseInt(item.maxLoss) || null,
        //   stopp_loss_value: parseInt(item.stopLoss) || null,
        //   stop_loss_type: item.stopLossTypeOption.value,
        //   exit_price: parseInt(item.exitPrice),
        //   legs_info: [...legs_info],
        // },
      };
      if (item.id !== undefined && item.id !== "") {
        out["id"] = item.id;
      }
      return out;
    })
  );

  outputData = {
    title: data.bundleTitle,
    planid: parseInt(planid) || null,
    expected_returns: parseInt(data.expectedReturn) || null,
    equity_capital_required: parseInt(data.equityCapital) || null,
    hedged_capital_required: parseInt(data.optionsCapital) || null,
    equity_notes: data.eqtynote,
    hedged_notes: data.optionalnote,
    current_returns: data.currentReturn || null,
    use_old_formula: data.use_old_formula || 0,
    enter_status: data.enter_status || 0,
    status: 1,
    bundles_calls_legs: [...equityAddScripArray],
    fund_hedgeds: [...optionsAddScripArray],
    bundle_type: data.type,
    is_notify : data?.notify
  };

  return outputData;
};

let updateformartData = async (obj) => {
  let data = obj.data;
  //console.log(data rohan);
  let outputData = {};

  //planid
  let planid = 1;
  if (data.subscription === "Prime") {
    planid = 2;
  } else if (data.subscription === "Crown") {
    planid = 3;
  }
  let equityAddScripArray = [];

  //equity
  equityAddScripArray = await Promise.all(
    data.equityAddScripArray.map(async (item) => {
      // console.log("item",item);
      const instrumentData = item?.instrument_id
        ? item?.instrument_id
        : await searchInstrumentID(item.scripNameValue.label);
      // console.log("item", item);
      let out = {
        name: item.scripNameValue.label,
        bundleId: data?.bundleId,
        fund_hedged_id: item?.fund_hedged_id,
        uuid: item?.uuid,
        type: "Equity",
        instrument_type: item?.instrument_type
          ? item?.instrument_type
          : instrumentData?.instrument_type,
        instrument_id: item?.instrument_id
          ? item?.instrument_id
          : instrumentData?.instrument_id,
        action: item?.action,
        quantity: parseInt(item.quantityValue) || null,
        entry_price_start: parseInt(item.minPriceValue) || null,
        entry_price_end: parseInt(item.maxPriceValue) || null,
        series: null,
        strike: item?.strike ? item?.strike : instrumentData?.tradingsymbol,
        exit_price: parseInt(item.exit_price),
        entry_version: item?.entry_version,
        exit_version: item?.exit_version,
        closed_type:
          item.call_status.value === "closed"
            ? item.closeType.value || null
            : null,
        closed_on: item?.closed_on ? item?.closed_on : item.call_status.value === "closed" ? moment().format("YYYY-MM-DD HH:mm:ss") : null,
        returns:
          item.call_status.value === "closed"
            ? parseInt(item.closeTotal) || null
            : null,
        status: item.call_status.value,
        strike_price: item?.strike_price,
        price: item?.price,
        quantity_percent: parseInt(item.quantityPercentValue) || null,
        stop_loss_type: item?.stop_loss_type,
        call_type: item.call_type.value,
        createdAt: item?.createdAt
      };

      // name: item.scripNameValue.label,
      // scrip_symbol: item.scripNameValue.value,
      // call_class: "Equity",
      // call_type: item.call_type.value,
      // capital_required: 0,
      // call_status: item.call_status.value,
      // close_type:
      //   item.call_status.value === "closed"
      //     ? item.closeType.value || null
      //     : null,

      // returns:
      //   item.call_status.value === "closed"
      //     ? parseInt(item.closeTotal) || null
      //     : null,
      // info: {
      //   minprice: parseInt(item.minPriceValue) || null,
      //   maxprice: parseInt(item.maxPriceValue) || null,
      //   quantity: parseInt(item.quantityValue) || null,
      //   quantity_percent: parseInt(item.quantityPercentValue) || null,
      //   stop_loss_type: "Value",
      //   // close_total:item.closeTotal,
      //   exit_price: parseInt(item.exit_price),
      //   close_date: new Date(),
      // },
      // };

      if (item.id !== undefined && item.id !== "") {
        out["id"] = item.id;
      }

      // if (item.leg !== undefined && item.leg !== "") {
      //   // If leg exists, add legs_info to the info object
      //   out.info["leg_info"] = item.leg;
      // }

      return out;
    })
  );

  //options

  let optionsAddScripArray = [];

  // Define a helper function for processing options data
  const processOptionsData = async (item) => {
    return Promise.all(
      item.legs.map(async (leg) => {
        const instrument_id = await getInstrumentID(
          item?.scripNameValue?.label,
          moment(leg.expiry).format("Do MMM'YY"),
          `${leg.strikePrice}${leg.option.value}`
        );

        let leg_out = {
          // {
          bundleId: data?.bundleId,
          uuid: leg?.uuid,
          type: leg?.type ? leg?.type : "Option",
          action: leg.action.value?.toUpperCase(),
          quantity: parseInt(leg.qtyValue),
          entry_price_start: leg?.Price,
          entry_price_end: leg?.Price,
          series: moment(leg.expiry).format("Do MMM'YY"),
          strike: leg.strikePrice,
          exit_price: leg.exitPrice === undefined ? "" : leg.exitPrice,
          status:
            leg.exitPrice === undefined || leg.exitPrice === ""
              ? "new"
              : "closed",
          strike_price: `${leg.strikePrice}${leg.option.value}`,
          price: parseInt(leg.Price),
          quantity_percent: null,
          stop_loss_type: null,
          instrument_id: instrument_id,
          closed_on: leg?.closed_on !== null ? leg?.closed_on : (leg.exitPrice !== undefined && leg.exitPrice !== "") ? moment().format("YYYY-MM-DD HH:mm:ss") : null,
          createdAt: leg?.createdAt
        };

        //   type: leg?.type ? leg?.type : "Option",
        //   quantity: parseInt(leg.qtyValue),
        //   strike_price: `${leg.strikePrice}${leg.option.value}`,
        //   series: moment(leg.expiry).format("Do MMM'YY"),
        //   price: parseInt(leg.Price),
        //   exit_price: leg.exitPrice === undefined ? "" : leg.exitPrice,
        //   bundle_calls_id: item?.id,
        //   bundleId: data?.bundleId,
        //   fund_hedged_id: item?.fund_hedged_id,
        //   entry_price_start: leg?.Price,
        //   entry_price_end: leg?.Price,
        //   price:leg?.Price,
        //   status:
        //     leg.exitPrice === undefined || leg.exitPrice === ""
        //       ? "Open"
        //       : "closed",
        //   action: leg.action.value,
        //   instrument_id: instrument_id, // Add instrument_id if it is not undefined
        // };

        if (leg.id !== undefined && leg.id !== "") {
          leg_out["id"] = leg.id;
        }
        if (item?.fund_hedged_id !== undefined && item?.fund_hedged_id !== "") {
          leg_out["fund_hedged_id"] = item?.fund_hedged_id
        }
        // if (leg.instrument_id !== undefined && item.instrument_id !== "") {
        //   leg_out["instrument_id"] = leg.instrument_id;
        // }
        if (leg.instrument_type !== undefined && item.instrument_type !== "") {
          leg_out["instrument_type"] = leg.instrument_type;
        }
        if (leg?.entry_version !== undefined && leg?.entry_version !== "") {
          leg_out["entry_version"] = leg?.entry_version;
        }
        if (leg?.exit_version !== undefined && leg?.exit_version !== "") {
          leg_out["exit_version"] = leg?.exit_version;
        }

        return leg_out;
      })
    );
  };

  optionsAddScripArray = await Promise.all(
    data.optionsAddScripArray.map(async (item) => {
      let legs_info = await processOptionsData(item);

      let out = {
        name: item.scripNameValue.label,
        fund_id: data?.bundleId,
        fund_call_id: item?.fund_call_id,
        hedged_capital_required: parseInt(item.capitalReq) || null,
        hedged_strategy: item.strategy.value,
        hedged_maxloss: parseInt(item.maxLoss) || null,
        hedged_stoploss: parseInt(item.stopLoss) || null,
        hedged_stoplosstype: item.stopLossTypeOption.value,
        hedged_exitprice: parseInt(item.exitPrice),
        hedged_type: item.call_type.value,
        hedged_status: item.call_status.value,
        capital_required: item?.capitalReq,
        hedged_bundle_version: "1",
        bundles_calls_legs: [...legs_info],
        close_type:
          item.call_status.value === "closed"
            ? item.closeType.value || null
            : null,
        returns:
          item.call_status.value === "closed"
            ? parseInt(item.closeTotal) || null
            : null,
        current_price: null,
        market_trend: null,
        closed_on: item?.closed_on ? item?.closed_on : item.call_status.value === "closed" ? moment().format("YYYY-MM-DD HH:mm:ss") : null,
        createdAt: item?.createdAt
      };

      //   bundle_id: data?.bundleId,
      //   name: item.scripNameValue.label,
      //   scrip_symbol: item.scripNameValue.value,
      //   call_class: "Option",
      //   call_type: item.call_type.value,
      //   capital_required: parseInt(item.capitalReq) || null,
      //   call_status: item.call_status.value,
      //   close_type:
      //     item.call_status.value === "closed"
      //       ? item.closeType.value || null
      //       : null,
      //   returns:
      //     item.call_status.value === "closed"
      //       ? parseInt(item.closeTotal) || null
      //       : null,
      //   current_price: null,
      //   market_trend: null,
      //   closed_on: item?.closed_on,
      //   info: {
      //     strategy: item.strategy.value,
      //     max_loss: parseInt(item.maxLoss) || null,
      //     stopp_loss_value: parseInt(item.stopLoss) || null,
      //     stop_loss_type: item.stopLossTypeOption.value,
      //     exit_price: parseInt(item.exitPrice),
      //     legs_info: [...legs_info],
      //   },
      // };
      if (item.id !== undefined && item.id !== "") {
        out["id"] = item.id;
      }
      return out;
    })
  );

  // let optionsAddScripArray = [];

  // optionsAddScripArray = data.optionsAddScripArray.map((item) => {
  //   let legs_info = item.legs.map((leg) => {
  //     let leg_out = {
  //       type: leg?.type ? leg?.type : "Option",
  //       quantity: parseInt(leg.qtyValue),
  //       strike_price: `${leg.strikePrice}${leg.option.value}`,
  //       series: moment(leg.expiry).format("Do MMM'YY"),
  //       price: parseInt(leg.Price),
  //       exit_price: leg.exitPrice === undefined ? "" : leg.exitPrice,
  //       bundle_calls_id: item?.id,
  //       bundle_id: data?.bundleId,
  //       fund_hedged_id: item?.fund_hedged_id,
  //       entry_price_start: leg?.Price,
  //       entry_price_end: leg?.Price,
  //       status:
  //         leg.exit_price === undefined || leg.exit_price === ""
  //           ? "Open"
  //           : "closed",
  //       action: leg.action.value,
  //     };

  //     if (leg.id !== undefined && leg.id !== "") {
  //       leg_out["id"] = leg.id;
  //     }
  //     if (leg.instrument_id !== undefined && item.instrument_id !== "") {
  //       leg_out["instrument_id"] = leg.instrument_id;
  //     }
  //     if (leg.instrument_type !== undefined && item.instrument_type !== "") {
  //       leg_out["instrument_type"] = leg.instrument_type;
  //     }
  //     if (leg?.entry_version !== undefined && leg?.entry_version !== "") {
  //       leg_out["entry_version"] = leg?.entry_version;
  //     }
  //     if (leg?.exit_version !== undefined && leg?.exit_version !== "") {
  //       leg_out["exit_version"] = leg?.exit_version;
  //     }
  //     return leg_out;
  //   });
  //   // console.log(item);
  //   let out = {
  //     bundle_id: data?.bundleId,
  //     name: item.scripNameValue.label,
  //     scrip_symbol: item.scripNameValue.value,
  //     call_class: "Option",
  //     call_type: item.call_type.value,
  //     capital_required: parseInt(item.capitalReq) || null,
  //     call_status: item.call_status.value,
  //     close_type:
  //       item.call_status.value === "closed"
  //         ? item.closeType.value || null
  //         : null,
  //     returns:
  //       item.call_status.value === "closed"
  //         ? parseInt(item.closeTotal) || null
  //         : null,
  //     current_price: null,
  //     market_trend: null,
  //     closed_on: item?.closed_on,
  //     info: {
  //       strategy: item.strategy.value,
  //       max_loss: parseInt(item.maxLoss) || null,
  //       stopp_loss_value: parseInt(item.stopLoss) || null,
  //       stop_loss_type: item.stopLossTypeOption.value,
  //       exit_price: parseInt(item.exitPrice),
  //       legs_info: [...legs_info],
  //     },
  //   };
  //   if (item.id !== undefined && item.id !== "") {
  //     out["id"] = item.id;
  //   }
  //   return out;
  // });

  outputData = {
    id: data?.bundleId,
    bundle_type: data.type,
    title: data.bundleTitle,
    isNew: 1,
    planid: parseInt(planid) || null,
    expected_returns: parseInt(data.expectedReturn) || null,
    hedged_capital_required: parseInt(data.optionsCapital) || null,
    equity_capital_required: parseInt(data.equityCapital) || null,
    hedged_notes: data.optionalnote,
    equity_notes: data.eqtynote,
    current_returns: data.currentReturn || null,
    status: 1,
    enter_status: data.enter_status || 0,
    use_old_formula: data.use_old_formula || 0,
    enter_nifty: null,
    current_nifty: null,
    bundle_version: data.bundle_version,
    fund_hedgeds: [...optionsAddScripArray],
    bundles_calls_legs: [...equityAddScripArray],
    meter: data?.meter,
    nifty_growth: data?.nifty_growth ? data?.nifty_growth : null,
    is_notify : data?.notify
  };

  // outputData = {
  //   id: data?.bundleId,
  //   title: data.bundleTitle,
  //   planid: parseInt(planid) || null,
  //   expected_returns: parseInt(data.expectedReturn) || null,
  //   equity_capital_required: parseInt(data.equityCapital) || null,
  //   hedged_capital_required: parseInt(data.optionsCapital) || null,
  //   equity_notes: data.eqtynote,
  //   hedged_notes: data.optionalnote,
  //   current_returns: data.currentReturn || null,
  //   use_old_formula: data.use_old_formula || 0,
  //   enter_status: data.enter_status || 0,
  //   status: 1,
  //   bundles_calls: [...optionsAddScripArray, ...equityAddScripArray],
  //   bundle_type: data.type,
  //   bundle_version: data.bundle_version,
  // };
  // outputData = {
  //   title: data.bundleTitle,
  //   // label:null,
  //   planid: parseInt(planid) || null,
  //   expected_returns: parseInt(data.expectedReturn) || null,
  //   equity_capital_required: parseInt(data.equityCapital) || null,
  //   hedged_capital_required: parseInt(data.optionsCapital) || null,
  //   equity_notes: data.eqtynote,
  //   hedged_notes: data.optionalnote,
  //   current_returns: data.currentReturn || null,
  //   use_old_formula: data.use_old_formula || 0,
  //   enter_status: data.enter_status || 0,
  //   status: 1,
  //   bundles_calls: [...optionsAddScripArray, ...equityAddScripArray],
  //   bundle_type: data.type,
  // };
  //console.log(outputData);
  return outputData;
};

let checkIfAllFieldsArePresent = (data) => {
  let equity = false;
  let option = false;
  let returnValue = false;

  // data.bundles_calls.map((item) => {
  //   if (item.call_class === "Equity") {
  //     equity = true;
  //   } else if (item.call_class === "Option") {
  //     option = true;
  //     // item?.info?.leg_info?.map((elem, ind)=>{
  //     //   if (
  //     //     elem.instrument_id == ""
  //     //     ) {
  //     //       swal({
  //     //         title: `Instrument Not Found for Leg ${ind + 1}`,
  //     //         text: "Leg cannot be Add without instrument Id",
  //     //         icon: "info",
  //     //       });
  //     //       returnValue = true;
  //     //     }
  //     // })
  //   }
  // });

  for (const property in data) {
    console.log(`property ${property} : ${data[property]}`);
    if (isEmptyField(data[property], !equity, !option, property)) {
      swal({
        text: `${fieldName[property]} field cannot be empty`,
        icon: "error",
      });
      returnValue = true;
    }
  }

  // data.bundles_calls.map((call) => {
  //   // console.log(call);
  //   if (call.call_class === "Equity") {
  //     if (
  //       isEmpty(call.name, "Scrip Name") ||
  //       isEmpty(call.info.minprice, "Min Price") ||
  //       isEmpty(call.info.maxprice, "Max Price") ||
  //       isEmpty(call.info.quantity, "Quantity") ||
  //       isEmpty(call.info.quantity_percent, "Quantity %") ||
  //       isEmpty(call.call_type, "Call Type") ||
  //       isEmpty(call.call_status, "Call Status")
  //     ) {
  //       returnValue = true;
  //     }
  //   } else if (call.call_class === "Option") {
  //     if (
  //       isEmpty(call.name, "Scrip Name") ||
  //       isEmpty(call.capital_required, "Capital Required") ||
  //       isEmpty(call.info.strategy, "Strategy") ||
  //       isEmpty(call.info.max_loss, "Max Loss") ||
  //       isEmpty(call.info.stopp_loss_value, "Stop Loss Value") ||
  //       isEmpty(call.info.stop_loss_type, "Stop Loss Type") ||
  //       isEmpty(call.call_type, "Call Type") ||
  //       isEmpty(call.call_status, "Call Status")
  //     ) {
  //       returnValue = true;
  //     }
  //     call.info.legs_info.map((leg, index) => {
  //       if (
  //         isEmpty(leg.type, "Action") ||
  //         checkDate(leg.series, "Series") ||
  //         checkNAN(leg.price, "Price") ||
  //         checkNAN(leg.quantity, "Quantity") ||
  //         isEmpty(
  //           leg.strike_price.substring(0, leg.strike_price.length - 2),
  //           "Strike Price"
  //         ) ||
  //         isEmptyOption(leg.strike_price.slice(-2), "Strike Price Option") ||
  //         instrumentEmpty(leg.instrument_id, "Instrument", index)

  //       ) {
  //         returnValue = true;
  //       }
  //     });
  //   }
  // });

  data.bundles_calls_legs.map((call) => {
    // console.log(call);
    // if (call.call_class === "Equity") {
    if (
      isEmpty(call.name, "Scrip Name") ||
      isEmpty(call.entry_price_start, "Min Price") ||
      isEmpty(call.entry_price_end, "Max Price") ||
      isEmpty(call.quantity, "Quantity") ||
      isEmpty(call.quantity_percent, "Quantity %") ||
      isEmpty(call.call_type, "Call Type") ||
      isEmpty(call.status, "Call Status")
    ) {
      returnValue = true;
    }

    data?.fund_hedgeds.map((call) => {
      console.log("call.status", call.hedged_status);

      if (
        isEmpty(call.name, "Scrip Name") ||
        isEmpty(call.hedged_capital_required, "Capital Required") ||
        isEmpty(call.hedged_strategy, "Strategy") ||
        isEmpty(call.hedged_maxloss, "Max Loss") ||
        isEmpty(call.hedged_stoploss, "Stop Loss Value") ||
        isEmpty(call.hedged_stoplosstype, "Stop Loss Type") ||
        isEmpty(call.hedged_type, "Call Type") ||
        isEmpty(call.hedged_status, "Call Status")
      ) {
        returnValue = true;
      }
      call.bundles_calls_legs.map((leg, index) => {
        if (
          isEmpty(leg.type, "Action") ||
          checkDate(leg.series, "Series") ||
          checkNAN(leg.price, "Price") ||
          checkNAN(leg.quantity, "Quantity") ||
          isEmpty(
            leg.strike_price.substring(0, leg.strike_price.length - 2),
            "Strike Price"
          ) ||
          isEmptyOption(leg.strike_price.slice(-2), "Strike Price Option") ||
          instrumentEmpty(leg.instrument_id, "Instrument", index, leg)
        ) {
          returnValue = true;
        }
      });
    });
    // }
    //  else if (call.call_class === "Option") {;
  });

  return returnValue;
};

let isEmptyField = (field, equity, option, property) => {
  if (property === "equity_notes") {
    return false;
  }
  if (property === "hedged_notes") {
    return false;
  }
  if (property === "nifty_growth") {
    return false;
  }
  if (property === "current_nifty") {
    return false;
  }
  if (property === "enter_nifty") {
    return false;
  }

  if (equity) {
    if (property === "equity_capital_required") {
      return false;
    }
  }
  if (option) {
    if (property === "hedged_capital_required") {
      return false;
    }
  }
  if (field === undefined || field === null || field === "") {
    return true;
  }
  return false;
};

let instrumentEmpty = (field, property, ind, leg) => {
  if (field === undefined || field === null || field === "") {
    // if(property == "Instrument"){

    if (leg?.status == "closed") {
      return false;
    } else {
      swal({
        title: `${property} Not Found for Leg ${ind + 1}`,
        text: "Leg cannot be Add without instrument Id",
        icon: "info",
      });
      return true;
    }

    //   }else{

    //   swal({
    //     text: `${property} field cannot be empty`,
    //     icon: "error",
    //   });
    //   return true;
    // }
  }

  return false;
};

let isEmpty = (field, property) => {
  if (field === undefined || field === null || field === "") {
    console.log("property", property);
    // if(property == "Instrument"){

    //   swal({
    //             title: `Instrument Not Found for Leg ${ind + 1}`,
    //             text: "Leg cannot be Add without instrument Id",
    //             icon: "info",
    //           });
    //           return true;
    // }else{

    swal({
      text: `${property} field cannot be empty`,
      icon: "error",
    });
    return true;
    // }
  }

  return false;
};

let checkNAN = (field, property) => {
  if (isNaN(field)) {
    swal({
      text: `${property} field cannot be empty`,
      icon: "error",
    });
    return true;
  }
  return false;
};
let checkDate = (field, property) => {
  if (field === "Invalid date") {
    swal({
      text: `${property} field cannot be empty`,
      icon: "error",
    });
    return true;
  }
  return false;
};

let isEmptyOption = (field, property) => {
  if (field === "CE" || field === "PE") {
    return false;
  }
  swal({
    text: `${property} field cannot be empty`,
    icon: "error",
  });
  return true;
};
