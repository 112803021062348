import React, { Component } from "react";
import * as API from "../../configuration/apiconfig";
import Swal from "sweetalert2";
import moment from "moment";
class CloseIntradayCall extends Component {
  constructor() {
    super();
    this.state = {
      profitTotal: "",
      losstotal: "",
      profitNotes: "",
      lossNotes: "",
      breakEvenNotes: "",
      getsigleCall: [],
      clossType: 0,
      exitPrices: [],
      exitErrormsg: [],
      closeLossChecked: false,
      amount: null,
      errorMsg: "",
      amounterrormsg: "",
    };
  }

  componentDidMount = async () => {
    await this.fetchCall();
  };

  fetchCall = async () => {
    let path = this.props.location.search;
    let id = parseInt(path.slice(path.indexOf("id=") + 3));
    return await API.callEndpoint("GET", "Bearer", `/api/intraday?id=${id}`)
      .then((response) => {
        this.setState({
          getsigleCall: response?.data?.data,
        });
        return response.data[0];
      })
      .catch((error) => {
        console.log(error.error);
      });
  };

  callClosePopup = async (id) => {
    let body = {
      id: id,
      exitPrice: this.state.exitPrices,
      amount: this.state.amount,
      close_type:
        this.state?.clossType == 1
          ? "PROFIT"
          : this.state?.clossType == 2
          ? "LOSS"
          : this.state?.clossType == 3
          ? "BREAK EVEN"
          : "",
    };
    if (this.state.clossType == 0) {
      this.setState({ errorMsg: "Choose Any One Option" });
    }

    if (!this.state.amount) {
      this.setState({ amounterrormsg: "please Enter Amount" });
    }
    // if (!this.state.exitPrice) {
    //   this.setState({ exitErrormsg: "please Enter EXit Price" });
    // }

    if (this.state.clossType != 0 && this.state.amount) {
      this.setState({ errorMsg: "" });
      this.setState({ amounterrormsg: "" });
      this.setState({ exitErrormsg: "" });
      await Swal.fire({
        title: "Are you sure you want close?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Close",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire("Closed", "Your file has been closed.", "success");
          API.callEndpoint("put", "Bearer", "/api/intraday/status", body)
            .then(async (response) => {
              // if (response.status === 200) {
              this.props.history.push("/intradaycall");
              // }
            })
            .catch((error) => {
              console.log(error.error);
            });
        }
      });
    }
  };

  render() {
    return (
      <>
        <section className="admin-bulishSaveProceedLeft">
          <section className="pv-inner">
            <section className="row">
              <div className="col-lg-12">
                <ul className="list-group list-group-horizontal w-33">
                  <li className="list-group-item col">
                    <span>Call Status </span>
                    <span className="price">
                      {this.state.getsigleCall[0] &&
                        this.state.getsigleCall[0].status}
                    </span>
                  </li>
                  <li className="list-group-item col">
                    <span>Call Type </span>
                    <span className="price">
                      {this.state.getsigleCall[0] &&
                        this.state.getsigleCall[0].call_type}
                    </span>
                  </li>

                  <li className="list-group-item col">
                    <span>Strategy </span>
                    <span className="price">
                      {this.state.getsigleCall[0] &&
                        this.state.getsigleCall[0].strategy}
                    </span>
                  </li>
                  <li className="list-group-item col">
                    <span>scrip </span>
                    <span className="price">
                      {this.state.getsigleCall[0] &&
                        this.state.getsigleCall[0].scripname}
                    </span>
                  </li>
                </ul>
              </div>
            </section>

            <section className="row">
              <section className="col-lg-6">
                {this.state.getsigleCall[0] &&
                this.state.getsigleCall[0].info.legs.length === 0 ? (
                  ""
                ) : (
                  <section className="total-calls-blk">
                    {this.state.getsigleCall[0] &&
                      this.state.getsigleCall[0].info.legs.map(
                        (legs, index) => {
                          return (
                            <section className="calls" key={index}>
                              <h3>Leg {index + 1}</h3>
                              <p>
                                <span>Action</span>
                                <span>Series</span>
                                <span>Strike Price</span>
                                <span>Entry Price</span>
                                <span>Quantity</span>
                              </p>
                              <p>
                                <span>{legs.action}</span>
                                <span>
                                  {" "}
                                  {legs?.series}
                                  {/* {moment(legs.series).format("Do MMM'YY")} */}
                                </span>
                                <span>
                                  {legs.strike_price} {legs.legOption}
                                </span>
                                <span>{legs.entry_price}</span>
                                <span>{legs.quantity}</span>
                              </p>
                            </section>
                          );
                        }
                      )}
                  </section>
                )}
              </section>
            </section>
          </section>
        </section>

        {this.state.getsigleCall[0]?.info?.legs?.map((leg, index) => (
          <div className="row">
            <div className="col-md-3">
              <div className="form-group colmb" style={{ marginTop: "20px" }}>
                <div className="call-field-header">
                  Exit Price for {leg.action} {leg?.series} {leg?.strike_price}{" "}
                  {leg.legOption}
                </div>
                <input
                  type="text"
                  className="form-control legsItemWidth  white-bg black-text"
                  name="exitPrice"
                  onChange={(e) => {
                    let ext = this.state.exitPrices;
                    ext[index] = {
                      price: e.target.value
                        .split("")
                        .filter((item) => item.match(/[0-9\\.]/i))
                        .join(""),
                    };

                    this.setState({
                      exitErrormsg: "",
                      exitPrices: ext,
                    });
                  }}
                  placeholder="Exit Price"
                  autoComplete="off"
                  value={this.state.exitPrices[index]?.price}
                />

                {this.state.exitErrormsg ? (
                  <section>
                    <div
                      style={{
                        marginTop: "7px",
                        marginBottom: "15px",
                        color: "red",
                      }}
                    >
                      {this.state.exitErrormsg}{" "}
                    </div>
                  </section>
                ) : null}
              </div>
            </div>
            {this.state?.getsigleCall[0]?.strategy?.toLowerCase() ==
              "long call" ||
            this.state?.getsigleCall[0]?.strategy?.toLowerCase() ==
              "long put" ? (
              <div className="col-md-6">
                <p
                  style={{
                    paddingTop: 18,
                  }}
                >
                  Esitmated Close Amount
                </p>
                <p>
                  {this.state?.getsigleCall[0]?.info?.lot_size *
                    leg?.quantity *
                    (this.state?.exitPrices[index]?.price - leg?.entry_price)}
                </p>
              </div>
            ) : null}
          </div>
        ))}

        <section className="admin-close-call" style={{ marginTop: "30px" }}>
          <section className="box-blk">
            <section className="box">
              <h3>Close this call</h3>
              <section className="list-blk">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    onChange={() =>
                      this.setState({
                        // closeProfitChecked: true,
                        errorMsg: "",
                        // closeLossChecked: false,
                        clossType: 1,
                      })
                    }
                    checked={this?.state?.clossType == 1}
                  />
                  <label className="form-check-label" htmlFor="inlineRadio1">
                    Profit
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio2"
                    onChange={() =>
                      this.setState({
                        // closeProfitChecked: false,
                        errorMsg: "",
                        // closeLossChecked: true,
                        clossType: 2,
                      })
                    }
                    checked={this?.state?.clossType == 2}
                  />
                  <label className="form-check-label" htmlFor="inlineRadio2">
                    Loss
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio3"
                    onChange={() =>
                      this.setState({
                        // closeProfitChecked: false,
                        errorMsg: "",
                        // closeLossChecked: true,
                        clossType: 3,
                      })
                    }
                    checked={this?.state?.clossType == 3}
                  />
                  <label className="form-check-label" htmlFor="inlineRadio3">
                    Break Even
                  </label>
                </div>
              </section>
              {this.state.errorMsg ? (
                <section>
                  <div
                    style={{
                      marginTop: "-10px",
                      marginBottom: "15px",
                      color: "red",
                    }}
                  >
                    {this.state.errorMsg}{" "}
                  </div>
                </section>
              ) : null}

              <div className="form-group">
                <input
                  value={this.state.amount}
                  className="form-control"
                  type="text"
                  placeholder={`Total Amount`}
                  onChange={(e) => {
                    this.setState({
                      amounterrormsg: "",
                      amount: e.target.value
                        .split("")
                        .filter((item) => item.match(/[0-9\\.]/i))
                        .join(""),
                    });
                  }}
                />
              </div>

              {this.state.amounterrormsg ? (
                <section>
                  <div
                    style={{
                      marginTop: "7px",
                      marginBottom: "15px",
                      color: "red",
                    }}
                  >
                    {this.state.amounterrormsg}{" "}
                  </div>
                </section>
              ) : null}
              <aside className="call-final-btn">
                <a
                  title="Close"
                  onClick={() => {
                    this.callClosePopup(this.state.getsigleCall[0].id);
                  }}
                >
                  Close
                </a>
              </aside>
            </section>
          </section>
        </section>
      </>
    );
  }
}

export default CloseIntradayCall;
