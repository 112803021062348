import React from "react";
import moment from "moment";

class OrdersTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableRef: React.createRef(),
    };
  }

  componentDidMount() {
    if (this.props.ref) {
      this.props.ref(this.state.tableRef);
    }
  }

  render() {
    return (
        <table class="table-datatable__root table order-table" ref={this.state.tableRef}>
        <thead class="thead">
          <tr class="thead-tr">
            <td class="thead-th">
              Broker<span class="pull-right"></span>
            </td>
            <td class="thead-th">
              User<span class="pull-right"></span>
            </td>
            <td class="thead-th">
              Call Id<span class="pull-right"></span>
            </td>
            <td class="thead-th">
              Instrument<span class="pull-right"></span>
            </td>
            <td class="thead-th">
              Status<span class="pull-right"></span>
            </td>
            <td class="thead-th">
              Remarks<span class="pull-right"></span>
            </td>
            <td class="thead-th">
              Entry At<span class="pull-right"></span>
            </td>
            <td class="thead-th">
              Entry price<span class="pull-right"></span>
            </td>
            <td class="thead-th">
              Entry Qty<span class="pull-right"></span>
            </td>
            <td class="thead-th">
              Exit At<span class="pull-right"></span>
            </td>
            <td class="thead-th">
              Exit Price<span class="pull-right"></span>
            </td>
            <td class="thead-th">
              Exit Qty<span class="pull-right"></span>
            </td>
            <td class="thead-th">
              MTM<span class="pull-right"></span>
            </td>
            <td class="thead-th">
              PNL<span class="pull-right"></span>
            </td>
          </tr>
        </thead>
        {this.props.tableBody?.length > 0 ? 
        <tbody class="tbody">
          {this.props.tableBody?.map((row, index)=>{
              return(
  
          <tr class="tbody-tr" key={index}>
            <td class="tbody-td">{row?.broker}</td>
            <td class="tbody-td">{row?.email}</td>
            <td class="tbody-td">{row?.callId}</td>
            <td class="tbody-td">{row?.instrumentSymbol ? row?.instrumentSymbol : "-"}</td>
            <td class="tbody-td">
            <p>{row?.leg_status ? row?.leg_status : "-"}</p>
            </td>
            <td class="tbody-td">{row?.failReason}</td>
            <td class="tbody-td">
            <p>
                    {row?.entryAt
                      ? moment(row?.entryAt)?.format("DD/MM/YYYY HH:MM A")
                      : "-"}
                      
                  </p>
            </td>
            <td class="tbody-td">{row?.avgEntryPrice ? row?.avgEntryPrice : "-"}</td>
            <td class="tbody-td">{row?.entryQtyFilled ? row?.entryQtyFilled : "-"}</td>
            <td class="tbody-td">
  
                  <p>
                    {row?.exitAt
                      ? moment(row?.exitAt)?.format("DD/MM/YYYY HH:MM A")
                      : "-"}
                  </p>
            </td>
            <td class="tbody-td">
            <p> {row?.avgExitPrice ? row?.avgExitPrice : "-"}</p>
            </td>
            <td class="tbody-td">
            <p>{row?.exitQtyFilled ? row?.exitQtyFilled : "-"}</p>
            </td>
            <td class="tbody-td">
            <p>{row?.mtm !== "" ? row?.mtm?.toFixed(2) : "-"}</p>
            </td>
            <td class="tbody-td">
            <p>{row?.pnl !== "" ? row?.pnl?.toFixed(2) : "-"}</p>
            </td>
          </tr>
              )
          })}
        </tbody> :<div className="cm-no-data"> <p className="no-data-text">No orders found</p> </div>}
      </table>
    );
  }
}

export default OrdersTable;

