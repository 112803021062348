const constant = {
  // baseUrl: "https://api.production.hedged.in",
  baseUrl: process.env.REACT_APP_API_URL || "https://api.staging.hedged.in",
  // baseUrl: "http://localhost:5001",
  // "proxy": "http://localhost:5001/"
  // baseUrl: "http://192.168.1.44:5001",
  username_cinema_app: process.env.REACT_APP_LOGIN || "appuser@injin.com",
  password_cinema_app: process.env.REACT_APP_PASSWORD || "N_w?Z92w%-BuLvKX",
  swaggerUrl: process.env.REACT_APP_SWAGGER_API_URL || "https://staging-dot-orders-hedged-in.appspot.com",
  // swaggerProdUrl: "https://orders.hedged.in/",
  swaggerSystemToken: process.env.REACT_SWAGGER_TOKEN || "1d015957f3874ede91ed7ba1325494db1bafc9d45fa04152a382d772d5ce4166"
};
export default constant;

export const vimeoBearerToken = "40fb8d6ce2255a0b615a31ea441138d8";
