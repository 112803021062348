import React, { Component } from "react";
import { Button } from "react-bootstrap";
import Select from "react-select";
import * as API from "../../configuration/apiconfig";

export class userCreate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      name: "",
      password: "",
      type: "",
      experience: "",
      phone: "",
      months:"",
      showSuccess: false,
      showError: false,
      typesOfUser: [
        { value: "Free", label: "Free" },
        { value: "Prime", label: "Prime" },
        { value: "Crown", label: "Crown" },
        { value: "2hr", label: "2hr" },
        { value: "AOS", label: "AOS" },
        { value: "AOS2HR", label: "AOS + 2HR" },
        { value: "AOS2HRCROWN", label: "AOS + 2HR + CROWN" },

      ],
      userExp: [
        { value: "Newbie", label: "Newbie" },
        { value: "Experienced", label: "Experienced" },
      ],
      optionsMonth : [
        { value: null, label: "Select" },
        { value: 1, label: "1" },
        { value: 2, label: "2" },
        { value: 3, label: "3" },
        { value: 4, label: "4" },
        { value: 5, label: "5" },
        { value: 6, label: "6" },
        { value: 7, label: "7" },
        { value: 8, label: "8" },
        { value: 9, label: "9" },
        { value: 10, label: "10" },
        { value: 11, label: "11" },
        { value: 12, label: "12" },
      ]
    };
  }

  getinput = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  LoginGet = () => {
    let data = {
      name: this.state.name,
      email: this.state.email,
      password: this.state.password,
      phonenumber: this.state.phone,
      authenticationPlatform: "app",
      type: this.state.type,
      experience: this.state.experience,
      registered_platform: 2,
      month:this.state.months
    };
    API.callEndpoint("POST", "Bearer", "/api/admin/create-user", data)
      .then((response) => {
        try {
          this.setState({
            showSuccess: true,
            showError: false,
          });
          setTimeout(() => {
            this.props.history.push(`/paid-users`);
          }, 1000);
        } catch (e) {
          this.setState({
            showError: true,
          });
          //  document.getElementById("wrongmess").style.display = "block";
        }
      })
      .catch((ex) => {
        // this.setState({ showLoader: false });
        this.setState({
          showError: true,
        });
        // document.getElementById("wrongmess").style.display = "block";
      });
  };

  componentDidMount() {
    console.log("user", API.getCookie("role"));
    if (API.getCookie("role") == "CMSSALESADMINUSER") {
      this.setState({
        typesOfUser: [
          { value: "Free", label: "Free" },
          // { value: "Prime", label: "Prime" },
          // { value: "Crown", label: "Crown" },
        ],
      });
    } else {
      this.setState({
        typesOfUser: [
          { value: "Free", label: "Free" },
          { value: "Prime", label: "Prime" },
          { value: "Crown", label: "Crown" },
          { value: "2hr", label: "2hr" },
        { value: "AOS", label: "AOS" },
        { value: "AOS2HR", label: "AOS + 2HR" },
        { value: "AOS2HRCROWN", label: "AOS + 2HR + CROWN" },


        ],
      });
    }
  }
  render() {
    return (
      <section className="admin-bundleTable-page">
        <h1 className="head text-uppercase m-0">Create User</h1>
        <section className="select-options-blk">
          <section className="row">
            <section className="col-lg-6 mb-20">
              <section className="forms-blk">
                <div className="head-1">Email</div>
                <div className="form-group">
                  <input
                    id="email"
                    type="text"
                    className="form-control"
                    name="email"
                    placeholder="Enter email"
                    autoComplete="off"
                    onChange={(e) => this.getinput(e)}
                    // onChange={(e) =>
                    //   this.props.updateState("bundleTitle", e.target.value)
                    // }
                    value={this.state.email}
                  />
                  {/* <span className="b-editbtn">&nbsp;</span> */}
                </div>
              </section>
            </section>
            <section className="col-lg-6 mb-20">
              <section className="forms-blk">
                <div className="head-1">Name</div>
                <div className="form-group">
                  <input
                    id="name"
                    type="text"
                    className="form-control"
                    name="name"
                    placeholder="Enter name"
                    autoComplete="off"
                    onChange={(e) => this.getinput(e)}
                    // onChange={(e) =>
                    //   this.props.updateState("bundleTitle", e.target.value)
                    // }
                    value={this.state.name}
                  />
                  {/* <span className="b-editbtn">&nbsp;</span> */}
                </div>
              </section>
            </section>
            <section className="col-lg-6 mb-20">
              <section className="forms-blk">
                <div className="head-1">Phone Number</div>
                <div className="form-group">
                  <input
                    id="phone"
                    type="number"
                    className="form-control"
                    name="phone"
                    // maxlength = "10"
                    // pattern="/^-?\d+\.?\d*$/"
                    onKeyPress={(e) => {
                      if (e.target.value.length >= 10) e.preventDefault();
                    }}
                    placeholder="Enter Phone number"
                    autoComplete="off"
                    onChange={(e) => this.getinput(e)}
                    // onChange={(e) =>
                    //   this.props.updateState("bundleTitle", e.target.value)
                    // }
                    value={this.state.phone}
                  />
                  {/* <span className="b-editbtn">&nbsp;</span> */}
                </div>
              </section>
            </section>
            <section className="col-lg-6 mb-20">
              <section className="forms-blk">
                <div className="head-1">Password</div>
                <div className="form-group">
                  <input
                    id="password"
                    type="password"
                    className="form-control"
                    name="password"
                    placeholder="Enter password"
                    autoComplete="off"
                    onChange={(e) => this.getinput(e)}
                    // onChange={(e) =>
                    //   this.props.updateState("bundleTitle", e.target.value)
                    // }
                    value={this.state.password}
                  />
                </div>
              </section>
            </section>
            <section className="col-lg-6 mb-20">
              <section className="forms-blk">
                <div className="head-1">User Type</div>
                <div className="form-group">
                  <Select
                    onChange={(e) =>
                      this.setState({
                        type: e.value,
                      })
                    }
                    options={this.state.typesOfUser}
                    placeholder="Select"
                  // value={this.props.membershipSelectOptions.filter(
                  //   (item) => item.value === this.props.state.subscription
                  // )}
                  />
                </div>
              </section>
            </section>
            <section className="col-lg-6 mb-20">
              <section className="forms-blk">
                <div className="head-1">User Experience</div>
                <div className="form-group">
                  <Select
                    onChange={(e) =>
                      this.setState({
                        experience: e.value,
                      })
                    }
                    options={this.state.userExp}
                    placeholder="Select"
                  // value={this.props.membershipSelectOptions.filter(
                  //   (item) => item.value === this.props.state.subscription
                  // )}
                  />
                </div>
              </section>
            </section>
            <section className="col-lg-6 mb-20">
              <section className="forms-blk">
                <div className="head-1">User Subscription Months</div>
                <div className="form-group">
                  <Select
                    onChange={(e) =>
                      this.setState({
                        months: e.value,
                      })
                    }
                    options={this.state.optionsMonth}
                    placeholder="Select"
                  // value={this.props.membershipSelectOptions.filter(
                  //   (item) => item.value === this.props.state.subscription
                  // )}
                  />
                </div>
              </section>
            </section>
          </section>
        </section>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "50px",
          }}
        >
          <Button
            disabled={
              !(
                this.state.email &&
                this.state.name &&
                this.state.password &&
                this.state.phone &&
                this.state.type
              )
            }
            onClick={this.LoginGet}
          >
            Submit
          </Button>
        </div>

        <div
          style={{ marginTop: "10px" }}
          className={`alert alert-success ${this.state.showSuccess ? "" : "d-none"
            }`}
          id="wrongmess"
        >
          Account created successfully.
        </div>
        <div
          style={{ marginTop: "10px" }}
          className={`alert alert-danger ${this.state.showError ? "" : "d-none"
            }`}
          id="wrongmess"
        >
          Something went wrong.
        </div>
      </section>
    );
  }
}

export default userCreate;
